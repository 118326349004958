// EnhaanceMyStory.jsx

import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios';
import Lottie from "lottie-react";
import 'react-toastify/dist/ReactToastify.css';
import coloredCircleAnimation from '../../images/94137-1hour-loading-2.json';
import errorCat from '../../images/9195-error.json';

import { ILLUSTRATION_AI_PROMPTS } from '../../constants/illustrationsConsts'
import { ILLUSTRATION_BUTTON_IMAGE_URLS } from '../../constants/illustrationsConsts'
import { ILLUSTRATION_STYLES } from '../../constants/illustrationsConsts'

import BasicStoryEnhanceUI from './CreateComponents/BasicStoryEnhanceUI'
import AdvancedStoryEnhanceUI from './CreateComponents/AdvancedStoryEnhanceUI'
import buildStory from './CreateApi/buildStoryApi'


import { createStoryMap, languageSelectionMap } from '../../constants/siteTextMap'

import themes from '../../constants/themesConfig';



function EnhaanceMyStory(props) {

  const { currentUser, avatar, firestoreUserData, showNavbar, setShowNavbar,
  } = props

  // const selectedThemeKey = 'valentinesTheme';
  // const theme = themes[selectedThemeKey] || themes.defaultTheme;

  const [selectedThemeKey, setSelectedThemeKey] = useState('defaultTheme'); // State to keep track of selected theme
  const theme = themes[selectedThemeKey];

  const chatModelVersion = "gpt-4-1106-preview"
  const imageGeneration = "Dalle-3"
  const isMobile = window.innerWidth <= 600;

  // ------------------------------------ //

  const cancelTokenSourceRef1 = useRef(null);
  const intervalIdRef1 = useRef(null);

  const [entireResponse, setEntireResponse] = useState(null)
  const [newStory, setNewStory] = useState(null);
  const [userPrompt, setUserPrompt] = useState(null);
  const [advancedUI, setAdvancedUI] = useState(false)

  const [generatedStoryId, setGeneratedStoryId] = useState(false)
  const [language, setStoryLanguage] = useState(firestoreUserData?.currentLanguage ? firestoreUserData.currentLanguage : "English")


  const [illustrationType, setIllustrationType] = useState([0, ILLUSTRATION_STYLES[0], ILLUSTRATION_BUTTON_IMAGE_URLS[0], ILLUSTRATION_AI_PROMPTS[0]])



  const [illustrationObject, setIllustrationObject] = useState({})
  const [loading, setLoading] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  let navigate = useNavigate()

  useEffect(() => {

    if (shouldNavigate) {
      navigate('/newstorydisplay', {
        state: {
          entireResponse,
          generatedStoryId,
          newStory,
          illustrationObject,
          illustrationType: illustrationType[3],
          userPrompt,
          chatModelVersion,
          imageGeneration
        }
      });
    }
  }, [shouldNavigate, navigate, entireResponse, generatedStoryId, newStory, illustrationObject, illustrationType, userPrompt, chatModelVersion, imageGeneration]);


  useEffect(() => {
    if (!currentUser) { navigate("/") }
  }, [currentUser, navigate]);



  useEffect(() => {
    setGeneratedStoryId(uuidv4())
    setShowNavbar(true)
    setIsCancelling(false)
  }, [])


  const clearAllPageState = () => {
    setErrorMessage(null)
    setEntireResponse(null)
    setNewStory(null)
    setShowNavbar(true)
    setIsCancelling(false)
  }

  const toggleTheme = () => {
    // Example toggle function (you might want to adapt this to your needs)
    const nextThemeKey = selectedThemeKey === 'defaultTheme' ? 'valentinesTheme' : 'defaultTheme'; // Toggle logic
    setSelectedThemeKey(nextThemeKey);
  };


  const submitStoryPrompt = (formData) => {
    setShowNavbar(false);
    setLoading(true);

    const userPromptInput = formData.userRequest;
    const additionalData = {
      userId: currentUser?.uid,
      theme: theme?.descriptionForApi,
      language
    };

    setUserPrompt(userPromptInput)

    cancelTokenSourceRef1.current = axios.CancelToken.source();

    buildStory({
      userPrompt: userPromptInput,
      additionalData,
      chatModelVersion,
      cancelToken: cancelTokenSourceRef1.current.token,
      intervalIdRef1
    })
      .then(response => {
        setLoading(false);

        const {
          originalData,
          returnvalue,
          storyData,
        } = response


        setEntireResponse({
          originalData,
          returnvalue,
          storyData,
        });

        setNewStory(storyData);
        setShouldNavigate(true);

      })


      // .catch(error => {
      //   setLoading(false); 
      //   setErrorMessage(error.message);
      // });

      .catch(error => {
        setLoading(false); // Stop loading animation
        if (axios.isCancel(error)) {
          console.log("User cancelled the story request");
          // Additional handling if needed for cancellation
        } else {
          setErrorMessage(error.message);
        }
      });


  };




  const paymentRequired = () => navigate('/payment')


  if (errorMessage) {
    return <Container>
      <br />
      <br />
      <h1 className="center">{createStoryMap[language]?.serversBusyErrorMessage || createStoryMap['English'].serversBusyErrorMessage}</h1>
      <Lottie animationData={errorCat} loop={true} style={{ height: 600, position: "relative" }} />
      <div className="center">
        <Button onClick={clearAllPageState} className="center" size="huge" color="black">{createStoryMap[language]?.continue || createStoryMap['English'].continue}</Button>
      </div>
    </Container>
  }

  if (loading) {
    return (
      <div style={{ position: "relative", height: 600 }}>
        <Lottie animationData={coloredCircleAnimation} loop={true} style={{ width: '100%', height: '100%' }} />

        <div className="center" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
          <h2>{userPrompt}</h2>
        </div>

        <div className="center" style={{ position: "absolute", bottom: 20, left: "50%", transform: "translateX(-50%)" }}>
          <Button
            color="black"
            onClick={() => {
              setIsCancelling(true);

              // Cancel the API request
              if (cancelTokenSourceRef1.current) {
                cancelTokenSourceRef1.current.cancel("API request cancelled by the user.");
              }

              setEntireResponse(null);
              setNewStory(null);
              setUserPrompt(null); // Reset the user prompt
              setAdvancedUI(false);
              setGeneratedStoryId(uuidv4()); // Regenerate the story ID
              setLoading(false);
              setIllustrationObject({});
              setIsCancelling(false); // Reset the cancelling state
            }}
          >❌</Button>
        </div>

        {isCancelling && (
          <h1 style={{ position: "absolute", top: "60%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>Cancelling this story</h1>
        )}
      </div>
    );
  }

  return (
    <Container>

      {advancedUI
        ?
        <AdvancedStoryEnhanceUI
          language={language}
          paymentRequired={paymentRequired}
          isMobile={isMobile}
          illustrationType={illustrationType}
          setIllustrationType={setIllustrationType}
          illustrationObject={illustrationObject}
          setIllustrationObject={setIllustrationObject}
          firestoreUserData={firestoreUserData}
          theme={theme}
          selectedThemeKey={selectedThemeKey}
          toggleTheme={toggleTheme}
          themes={themes}

        />
        : <BasicStoryEnhanceUI
          language={language}
          paymentRequired={paymentRequired}
          isMobile={isMobile}
          illustrationType={illustrationType}
          setIllustrationType={setIllustrationType}
          illustrationObject={illustrationObject}
          setIllustrationObject={setIllustrationObject}
          submitStoryPrompt={submitStoryPrompt}
          firestoreUserData={firestoreUserData}
          theme={theme}
          selectedThemeKey={selectedThemeKey}
          toggleTheme={toggleTheme}
          themes={themes}

        />
      }

    </Container>
  )
}

export default EnhaanceMyStory

