// ReadingAdvenures.jsx
//  setClassroom
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, serverTimestamp, updateDoc, getDoc, setDoc, collection, query, where, onSnapshot } from "firebase/firestore";
import { customAlphabet } from 'nanoid';
import { ToastContainer, toast } from 'react-toastify';
import { useTable, useSortBy } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import CopyToClipboardDiv from '../general/CopyToClipboardDiv';
import Lottie from "lottie-react";
import loadingAnimation from '../../images/loading-animation.json';

// readingAdventureTeacherClassroomCurrentId
export default function ReadingAdventures({ currentUser, firestoreUserData }) {
  const [readingLevel, setReadingLevel] = useState('');
  const [language, setLanguage] = useState('');
  const [groupName, setGroupName] = useState('');
  const [theme, setTheme] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [adventures, setAdventures] = useState([]);
  const [vocabularyWords, setVocabularyWords] = useState([]);
  const [classroom, setClassroom] = useState(null);
  const [showCurrentClassroom, setShowCurrentClassroom] = useState(true);
  const [loading, setLoading] = useState(false);


  const nanoid = customAlphabet('23456789ABCDEFGHJKMNPQRSTUVWXYZ', 6);
  const navigate = useNavigate();

  const { userDisplayName, avatarSeed, userId, membership, readingAdventureGroups = [], readingAdventureTeacherClassroomCurrentId } = firestoreUserData || {};

  useEffect(() => {
    const fetchClassroomData = async () => {

      if (!readingAdventureTeacherClassroomCurrentId) {
        navigate('/reading-adventure-teacher-classrooms');
      } else {
        setLoading(true);

        const currentClassroomDocRef = doc(db, "users", currentUser.uid, "classrooms", readingAdventureTeacherClassroomCurrentId);
        const docSnap = await getDoc(currentClassroomDocRef);

        if (docSnap.exists()) {

          setClassroom(docSnap.data() || null);
        } else {
          console.log('No such document!'); // Logging absence of document
        }
        setLoading(false);
      }
    }
    fetchClassroomData();
  }, [readingAdventureTeacherClassroomCurrentId]);

  useEffect(() => {
    if (currentUser) {
      const q = query(collection(db, "adventures"), where("userId", "==", currentUser.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedAdventures = [];
        querySnapshot.forEach((doc) => {
          fetchedAdventures.push(doc.data());
        });


        if (showCurrentClassroom) {
          const filteredAdventures = fetchedAdventures.filter(adventure => adventure.classroomId === readingAdventureTeacherClassroomCurrentId);

          setAdventures(filteredAdventures);
        } else {
          setAdventures(fetchedAdventures);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser, showCurrentClassroom]);

  function generateSixDigitCode() { return nanoid(); }

  const handleSubmitAdventure = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!groupName || !readingLevel || !language) {
      toast.error("All fields are required. Please complete the form.");
      return;
    }

    const joinCode = generateSixDigitCode();
    const adventureId = uuidv4();

    const newAdventure = {
      userId: currentUser.uid,
      adventureId,
      groupName,
      readingLevel,
      readers: [],
      language,
      theme,
      joinCode,
      wordBank: vocabularyWords,
      dateCreated: serverTimestamp(),
      dateEdited: serverTimestamp(),
      classroomId: readingAdventureTeacherClassroomCurrentId,
      classroomTeacher: { id: userId, displayName: userDisplayName }
    };

    const adventureDocRef = doc(db, "adventures", adventureId);
    const userDocRef = doc(db, "users", currentUser.uid);

    try {
      const docSnapshot = await getDoc(adventureDocRef);
      if (!docSnapshot.exists()) {
        await setDoc(adventureDocRef, newAdventure);

        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const updatedAdventures = userData.readingAdventureGroups
            ? [...userData.readingAdventureGroups, adventureId]
            : [adventureId];
          await updateDoc(userDocRef, { readingAdventureGroups: updatedAdventures });
        }
        setShowForm(false);
        setLoading(false);
      } else {
        console.log("Adventure with this ID already exists");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating adventure:", error);
      setLoading(false);
    }
  }


  const handleVocabChange = (index) => (event) => {
    const newVocabWords = [...vocabularyWords];
    newVocabWords[index] = event.target.value;
    setVocabularyWords(newVocabWords);
  };

  const data = useMemo(() => adventures, [adventures]);

  const columns = useMemo(
    () => [
      { Header: "Adventure Name", accessor: "groupName" },
      { Header: "Reading Level", accessor: "readingLevel" },
      { Header: "Language", accessor: "language" },
      { Header: "Join Code", accessor: "joinCode", Cell: ({ cell: { value } }) => <CopyToClipboardDiv text={value}>{value}</CopyToClipboardDiv>, },
      { Header: "Theme", accessor: "theme" },

      { Header: "Readers", accessor: "readers", Cell: ({ cell: { value } }) => value ? value.length : 0, },

      {
        Header: "Edited", accessor: "dateEdited", Cell: ({ cell: { value } }) => value ? new Date(value.seconds * 1000).toLocaleDateString() : null, sortType: (a, b) => {
          const dateA = a.original.dateEdited ? new Date(a.original.dateEdited.seconds * 1000) : null;
          const dateB = b.original.dateEdited ? new Date(b.original.dateEdited.seconds * 1000) : null;
          if (dateA < dateB) return -1
          if (dateA > dateB) return 1
          return 0
        },
      },
      { Header: "Classroom Id", accessor: "classroomId" }
    ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data,
    initialState: { sortBy: [{ id: 'dateEdited', desc: true }] }
  }, useSortBy);

  if (!membership) {
    navigate('/');
    return null;
  }

  if (!currentUser) {
    navigate('/');
    return null;
  }


  if (loading) {
    return <Lottie animationData={loadingAnimation} loop={true} style={{ height: 600, position: "relative" }} />
  }

  return (
    <Container>
      <ToastContainer />
      <br />
      <h2 className="center" style={{ fontSize: "3em" }}>Reading Adventures</h2>


      {!showForm && (
        <React.Fragment>

          <h5 className="center"
            onClick={() => navigate('/reading-adventure-teacher-classrooms')}
            style={{ fontSize: "1.5em", cursor: "pointer" }}>
            Classroom: {classroom?.classroomName}
          </h5>
          <h5 className="center"
            onClick={() => navigate('/reading-adventure-teacher-classrooms')}
            style={{ fontSize: ".5em", cursor: "pointer" }}>
            Classroom Id: {readingAdventureTeacherClassroomCurrentId}
          </h5>

          {adventures.length === 0 ? (

            <h1>There are no Reading Adventure for this classroom. Click below to add on.</h1>
          ) : (
            <table {...getTableProps()} style={{ borderCollapse: 'collapse' }}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ cursor: "pointer", border: '1px solid black', fontSize: '1.2em', padding: '10px' }}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} style={{ cursor: 'pointer' }} onClick={() => navigate(`/adventure-details/${row.original.adventureId}`)}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()} style={{ border: '1px solid black', fontSize: '1.1em', padding: '10px' }}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <br />
          <div className="center">
            <Button color='black' onClick={() => setShowForm(true)}>Add New Group
            </Button>
          </div>


        </React.Fragment>
      )}

      {showForm && (
        <>
          <h5 className="center">NEW GROUP</h5>

          <Form onSubmit={handleSubmitAdventure}>
            <Form.Input
              label='Group Name'
              placeholder='Group Name'
              name='group'
              onChange={(e, { value }) => setGroupName(value)}
            />

            <Form.Field
              label='Reading Level'
              control={Dropdown}
              placeholder='Reading Level'
              name='readingLevel'
              onChange={(e, { value }) => setReadingLevel(value)}
              options={[
                { key: 'k-1', value: 'Kinder - 1st', text: 'Kinder - 1st' },
                { key: '2-3', value: '2nd to 3rd', text: '2nd to 3rd' },
                { key: '4-5', value: '4th to 5th', text: '4th to 5th' },
                { key: '6-above', value: '6th and above', text: '6th and above' },
              ]}
            />

            <Form.Field
              label='Language'
              control={Dropdown}
              placeholder='Language'
              name='language'
              onChange={(e, { value }) => setLanguage(value)}
              options={[
                { key: 'english', value: 'English', text: 'English' },
                { key: 'spanish', value: 'Spanish', text: 'Spanish' },
              ]}
            />

            <Form.Input
              label='Theme (Optional)'
              placeholder='Theme'
              name='theme'
              onChange={(e, { value }) => setTheme(value)}
            />

            <Form.Field
              label='Word Bank'
              control='textarea'
              placeholder="Enter up to 5 words to include in the story"
              name='wordBank'
              style={{
                fontSize: '1.5em',
                fontFamily: 'Courier, monospace',
                boxSizing: 'border-box'
              }}
              onChange={(e) => setVocabularyWords(e.target.value.split('\n'))}
            />

            <br />

            <div className="center">
              <Button type='submit' color='green'>Save</Button>
              <Button color='black' onClick={() => setShowForm(false)}>Cancel</Button>
            </div>
            <br />
          </Form>
        </>

      )}


      <div className="center">
        <br />
        <br />
        <Button color="blue" size="tiny" onClick={() => setShowCurrentClassroom(!showCurrentClassroom)}>
          {showCurrentClassroom ? "Show All" : "Show Current Classroom"}
        </Button>
      </div>
    </Container>
  );
}
