// Home.jsx

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//import Logout from '../general/Logout'
import { Container } from 'semantic-ui-react'
import DisplayFirestoreImagesUser from '../general/DisplayFirestoreImagesUser';
import useFirebaseQuery from '../hooks/useFirebaseQuery'
import { createAvatar } from '@dicebear/core';
//import { botttsNeutral } from '@dicebear/collection';
import { adventurer } from '@dicebear/collection';
import Lottie from "lottie-react";
import simpleLoader from '../../images/simpleLoader.json';
// import * as style from '@dicebear/avatars-bottts-sprites';

import { homeMap } from '../../constants/siteTextMap'


function Home(props) {
  const { currentUser, db, firestoreUserData, setShowNavbar } = props
  const { loading: loadingQuery, error: errorQuery, data: dataQuery } = useFirebaseQuery(currentUser?.uid, db, "stories", "dateCreated")

  const { userDisplayName, avatarSeed, userId, currentLanguage, newUser, googleAuth } = firestoreUserData || {}

  const avatar = createAvatar(adventurer, { seed: avatarSeed ? avatarSeed : userId });
  const svgAvatar = avatar.toDataUriSync();

  let navigate = useNavigate()
  const showStory = (storyId) => { navigate('/storydisplaymain/' + storyId) }
  // const handleProfileClick = () => { navigate("/profile") }

  useEffect(() => {
    setShowNavbar(true);
  }, []);

  if (newUser) navigate("/new-user-agreement")

  if (!currentUser) { return navigate("/") }
  if (errorQuery) return <h1>{errorQuery?.message}</h1>
  if (loadingQuery) return <div className="center">
    <Lottie animationData={simpleLoader} loop={true} style={{ height: 600, position: "relative" }} />
  </div>

  if (!currentUser?.emailVerified) {
    navigate('/loginemailconfirm')
    console.log("❌ NOT VERIFIED ❌  ")
  }


  return (<>

    <Container>

      {userDisplayName && <h2 className="center" style={{ fontSize: "3.5em" }}>{userDisplayName}</h2>}

      <div className="center">
        {svgAvatar && <img src={svgAvatar} alt="" height="200px" width="200px"

        // onClick={handleProfileClick} 
        />}
      </div>

      {dataQuery && <h2 className="center">
        {homeMap[currentLanguage]?.stories || homeMap['English'].stories} {dataQuery?.length}
      </h2>}


      <DisplayFirestoreImagesUser
        userId={currentUser?.uid}
        size={"40px"}
        showStory={showStory}
        {...props}
      />

    </Container >
  </>
  )
}

export default Home



{/* <h5>Simple version of profile page</h5>
<h5>Make all of these big button</h5>

<h1>Create Story</h1>
<h5>Special: Birthday / Event / Holidy</h5>

<h1>Chat</h1>
<h5>Free chat for paid members </h5>

<h1>Account:</h1>
<h5>Version: Free or Paid (show credits)</h5>
<h5>GPT Version{chatModelVersion}</h5>
<h5>Language: {language?.name} {language?.flag}</h5>
<h5>Open AI Server status </h5> */}