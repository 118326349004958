import React from 'react'
import { Container } from 'semantic-ui-react';

export default function TermsOfServiceText() {
  return (
    <Container>
      <h1 className="center" style={{ fontSize: "3em", marginBottom: '-2px' }}>Picture Books Terms and Conditions</h1>

      <h1 style={{ marginBottom: '4px' }}>Acceptance of Terms</h1>
      <p>
        By accessing or using the services provided by BeechTree Ed LLC, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, you must not use our services. These terms are a legal agreement between you and BeechTree Ed LLC, and govern your use of the services provided. Your use of our services indicates your acceptance of these terms and conditions. We reserve the right to change or modify these terms at any time without prior notice.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Collection and Use of Personal Information</h1>
      <p>
        We collect and retain only your name and email address for the purpose of providing and improving our services. No other personal information is collected or retained. The information collected is used to ensure a smooth user experience and to tailor our services to your preferences. It's crucial for us to have a means of communication with you for delivering our services effectively. By providing us your email address, you agree to receive communications from us concerning our services.
      </p>

      <h1 style={{ marginBottom: '6px' }}>No Sharing of Information</h1>
      <p>
        We will not share, sell, rent, or otherwise disclose your personal information to any third parties, including other companies or organizations. Your trust is important to us and maintaining your privacy is a priority. This policy of non-disclosure extends to any third-party services we may use to provide our services, which will also not have access to your personal information.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Compliance with COPPA</h1>
      <p>
        We comply with the Children's Online Privacy Protection Act (COPPA). Our services are not directed to children under 13 years of age, and we do not knowingly collect personal information from children under 13. It is a violation of our terms for children under 13 to use our services without parental consent. We take the privacy of all our users seriously and have measures in place to ensure the protection of minors.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Age Requirements</h1>
      <p>
        You must be at least 13 years old to use our services. By using our services, you represent that you are 13 years of age or older, and that you have the authority to agree to these terms. It's imperative that we adhere to legal guidelines regarding age restrictions to ensure a safe and compliant user environment.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Compliance with EU Regulations</h1>
      <p>
        We comply with all applicable European Union regulations, including but not limited to the General Data Protection Regulation (GDPR). We take measures to ensure that the data of our users from the EU and EEA are handled with the utmost care and in compliance with GDPR guidelines. Your privacy and data security are paramount to us, and we strive to uphold the highest standards of data protection.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Changes to This Agreement</h1>
      <p>
        We reserve the right to modify this user agreement at any time, and changes will be effective upon posting on our website or application. Continued use of our services following any changes constitutes your acceptance of the updated terms. It's important to review these terms periodically to stay informed of any updates or modifications.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Contact Information</h1>
      <p>
        If you have any questions or concerns regarding this agreement, please contact us at <a href="mailto:support@beechtree.ai">support@beechtree.ai</a>.
      </p>

      <h1 style={{ marginBottom: '6px' }}>Governing Law</h1>
      <p>
        This user agreement shall be governed by the laws of Minnesota, without regard to its conflict of law principles. It's understood that the jurisdiction for any disputes arising from or related to this agreement shall be in the courts of Minnesota. By using our services, you agree to be bound by this user agreement and to abide by the governing laws as stated.
      </p>
      <p>
        Last updated: October 1, 2023
      </p>
    </Container>

  )
}
