// TermsOfService.jsx

import React from 'react';
import TermsOfService from '../../content/TermsOfServiceText';

function TermsOfServicePage() {

  return (
    <div>
      <br />
      {TermsOfService()}
    </div>
  );
}

export default TermsOfServicePage;
