import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './BillingUpgradePlan';

function BillingUpgradePlan({ currentUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <div className="billing-upgrade">
      <h1>Become a Children's Picture Book Creator</h1>
      <p>Create enchanting picture books that spark imagination and joy in children.</p>
      <div className="plans-container">
        <div className="plan">
          <div className="plan-details">
            <h2>Monthly Plan</h2>
            <p className="price">$29 <span>/ month</span></p>
            <ul>
              <li>Access to book templates</li>
              <li>Interactive story builder</li>
              <li>Monthly illustration packs</li>
              <li>Private creators community access</li>
              <li>Print & delivery discounts</li>
            </ul>
            <button className="subscribe-button">Subscribe</button>
          </div>
        </div>
        <div className="plan lifetime">
          <div className="plan-details">
            <h2>Lifetime Access</h2>
            <p className="price">$399 <span>once</span></p>
            <ul>
              <li>All monthly plan benefits</li>
              <li>Unlimited story hosting</li>
              <li>Custom illustration services</li>
              <li>Free featured book promotions</li>
              <li>Lifetime updates and features</li>
            </ul>
            <button className="subscribe-button lifetime">Upgrade for Life</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingUpgradePlan;
