//  Navigation.js

import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { WORLD_COUNTRIES_ALL } from './constants/world-countries-all_DIALECTS'
//import updateUser from './components/api/updateUser';
import { createAvatar } from '@dicebear/core';
import { adventurer } from '@dicebear/collection';
import { handleLogout } from './components/helpers/handleLogout'
import { auth } from './firebase'
import logoImage from '../src/images/logo/logo-navbar2.png'
import { Button } from 'semantic-ui-react';
import { navbarMap, languageSelectionMap } from './constants/siteTextMap';
import './styles/Navigation.css'
import './styles/App.css';


const Navigation = ({ currentUser, imageApi, firestoreUserData, db, setImageUrls, setFirestoreUserData }) => {

  const [currentCountryIndex, setCurrentCountryIndex] = useState(0);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isCreateMenuOpen, setCreateMenuOpen] = useState(false);
  const [language, setLanguage] = useState("English");
  const [isMobile, setMobile] = useState(window.innerWidth < 600);
  const { avatarSeed, userId, membership } = firestoreUserData || {}
  const buttonGroupWidth = membership ? 7 : 6;
  const { currentLanguage } = firestoreUserData || {}

  let navigate = useNavigate()


  const buttonStyles = {
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    margin: "0px"
  };


  const handleMenuOpen = () => setProfileMenuOpen(true);
  const handleMenuClose = () => setProfileMenuOpen(false);
  const handleMenuToggle = () => setProfileMenuOpen(!isProfileMenuOpen);

  const handleCreateMenuOpen = () => setCreateMenuOpen(true);
  const handleCreateMenuClose = () => setCreateMenuOpen(false);



  const avatar = createAvatar(adventurer, { seed: avatarSeed ? avatarSeed : userId });

  const svgAvatar = avatar.toDataUriSync();

  const currentCountry = WORLD_COUNTRIES_ALL[0][currentCountryIndex];

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    if (firestoreUserData?.currentLanguage) {
      setLanguage(firestoreUserData.currentLanguage);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [firestoreUserData]);


  let createUrl = "/createthemedstory"

  // let createUrl = "/createdalle3"

  // if (imageApi === "Dalle2") {
  //   createUrl = "/createdalle2"
  // } else if (imageApi === "Stable Diffusion XL") {
  //   createUrl = "/createstablediff"
  // } else if (imageApi === "down") {
  //   createUrl = "/createdown"
  // }


  const handleCreateButtonClick = () => {
    navigate(createUrl);
  };


  return (
    <>
      <div className="button-group-container">
        <Button.Group widths={buttonGroupWidth} vertical={isMobile}>

          <Button
            as={NavLink}
            to="/home"
            style={buttonStyles}>
            <img src={logoImage} alt="Logo" />
          </Button>


          <Button
            onMouseEnter={handleCreateMenuOpen}
            onMouseLeave={handleCreateMenuClose}
            onClick={handleCreateButtonClick}
            style={buttonStyles}>
            <div className="center">
              {navbarMap[language]?.create || navbarMap['English'].create}
            </div>
          </Button>


          <Button
            as={NavLink}
            to="/stories"
            style={buttonStyles}>
            {navbarMap[language]?.bookshelf || navbarMap['English'].bookshelf}
          </Button>


          {membership &&
            <Button
              as={NavLink}
              to="/adventures"
              style={buttonStyles}>
              {navbarMap[language]?.adventure || navbarMap['English'].adventure}
            </Button>
          }

          <Button
            as={NavLink}
            to="/payment"
            style={buttonStyles}>
            {navbarMap[language]?.credits || navbarMap['English'].credits}
            : {firestoreUserData?.credit}
          </Button>



          <Button
            as={NavLink}
            to="/language-selection"
            style={buttonStyles}>
            <span style={{ fontSize: "32px", marginRight: "3px", cursor: "pointer" }}>
              {firestoreUserData?.currentFlag ? firestoreUserData.currentFlag : currentCountry?.emoji}
            </span>

            <span style={{ color: "black", cursor: "pointer" }}>
              {languageSelectionMap[currentLanguage]
                ? languageSelectionMap[currentLanguage][currentLanguage.toLowerCase()]
                : languageSelectionMap['English'][currentLanguage?.toLowerCase()]}
            </span>
          </Button>


          <Button
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                handleMenuToggle();
              }
            }}
            style={buttonStyles}>
            <div className="center">
              {svgAvatar &&
                <img src={svgAvatar} alt="" height="40px" width="40px"
                  onClick={(e) => { e.stopPropagation(); navigate('/profile'); }} />}
              {isProfileMenuOpen && (
                <div className="dropdown-menu">
                  <div onClick={() => navigate('/profile')}>              {navbarMap[language]?.profile || navbarMap['English'].profile}
                  </div>
                  <div onClick={() => navigate('/payment')}>              {navbarMap[language]?.credits || navbarMap['English'].credits}
                  </div>
                  <div onClick={() => navigate('/about')}>              {navbarMap[language]?.about || navbarMap['English'].about}
                  </div>
                  <div onClick={() => handleLogout({ auth, setImageUrls, setFirestoreUserData, navigate })}>              {navbarMap[language]?.logout || navbarMap['English'].logout}
                  </div>
                </div>
              )}
            </div>
          </Button>


        </Button.Group>

      </div>
    </>
  );
};


export default Navigation;


