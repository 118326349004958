// buildStoryApi.js

import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;

const buildStory = ({
  userPrompt,
  additionalData,
  chatModelVersion,
  cancelToken,
  intervalIdRef1 }) => {

  const storyRequest = {
    model: chatModelVersion,
    prompt: userPrompt,
    theme: additionalData?.theme,
    userId: additionalData?.userId,
    language: additionalData?.language
  };


  return new Promise((resolve, reject) => {
    if (!cancelToken) {
      reject(new Error("Cancel token is not initialized"));
      return;
    }

    axios.post(baseUrl + "/gpt4-1106-a-redis/story-request/", storyRequest, {
      cancelToken // Use the token directly
    })

      .then(response => {
        const jobId = response.data.jobId;
        console.log("Job ID retrieved:", jobId);

        intervalIdRef1.current = setInterval(() => {
          axios.get(baseUrl + `/gpt4-1106-a-redis/story-request/status/${jobId}`, { cancelToken })

            .then(response => {
              const { status, data, returnvalue } = response.data;

              if (status === 'completed') {

                clearInterval(intervalIdRef1.current);

                resolve({
                  originalData: data,
                  returnvalue,
                  storyData: JSON.parse(returnvalue.storyData)
                });

              } else if (status === 'failed') {
                clearInterval(intervalIdRef1.current);
                reject(new Error('Job failed'));
              }

              console.log("Status for build story job:", status)

            })

            .catch(err => {
              clearInterval(intervalIdRef1.current);
              if (axios.isCancel(err)) {
                console.log("Story build request cancelled by the user");
              } else {
                console.log("Error in submitting story prompt:", err.message);
                reject(err);
              }
            });



        }, 5000);
      })

      .catch(err => {
        console.log("Error in submitting the story prompt:", err.message);
        reject(err);
      });
  });
};

export default buildStory;
