/* eslint-disable react/prop-types */
// ReadingAdventuresDetailes
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Form, Button, Container, Dropdown, Modal } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchAdventure, updateAdventure, deleteAdventure } from '../api/adventureService.js'
import { deleteAdventureStory } from '../api/adventureStoryService.js'


function getDate(date) {
  let dateObj;
  if (typeof date === 'string' || typeof date === 'number') {
    dateObj = new Date(date);
  } else if (date?.toDate) {
    dateObj = date.toDate();
  }
  return dateObj ? dateObj.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "Not Available";
}

export default function ReadingAdventureDetails({ currentUser }) {

  const [showSimpleDeleteModal, setShowSimpleDeleteModal] = useState(false);
  const [showComplexDeleteModal, setShowComplexDeleteModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [adventure, setAdventure] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [wordBankInput, setWordBankInput] = useState('');
  const [updatedFields, setUpdatedFields] = useState({
    groupName: '',
    readingLevel: '',
    language: '',
    theme: '',
    wordBank: []
  });
  const [loading, setLoading] = useState(false)
  const [readersData, setReadersData] = useState([])

  const { adventureId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const adventureData = await fetchAdventure(adventureId);

      if (adventureData) {
        setAdventure(adventureData);
        setUpdatedFields({
          groupName: adventureData.groupName,
          readingLevel: adventureData.readingLevel,
          language: adventureData.language,
          theme: adventureData.theme,
          wordBank: adventureData.wordBank || [] // provide a default value of an empty array if wordBank is undefined
        });
        setWordBankInput(Array.isArray(adventureData?.wordBank) ? adventureData.wordBank.join("\n") : '');
        setReadersData(adventureData.readersData || {})
      }

    };

    fetchData();
  }, [adventureId]);

  const handleDelete = async () => {
    if (readersData && Object.entries(readersData).length > 0) {
      setShowComplexDeleteModal(true)
    } else {
      setShowSimpleDeleteModal(true)
    }
  }

  const confirmDelete = async () => {
    await deleteAdventure(adventureId);
    navigate('/adventures');
  };



  const confirmComplexDelete = async () => {
    const readersArray = Object.entries(readersData);
    console.log("readersArray", readersArray);

    const arrayOfStoryIds = [];

    readersArray.forEach(([, readerData]) => {
      Object.keys(readerData).forEach(key => {
        if (key !== 'readerDisplayName') {
          arrayOfStoryIds.push(key);
        }
      });
    });

    try {
      for (const storyId of arrayOfStoryIds) {
        // const item = storyId;
        await deleteAdventureStory(storyId);
      }
      await deleteAdventure(adventureId);
      navigate('/adventures')
    }
    catch (err) {
      console.log("err", err)
    }
  };


  const handleInputChange = (event, { name, value }) => {
    event.key === 'Enter' && event.preventDefault();
    setUpdatedFields(prevState => {
      const newState = { ...prevState, [name]: value };
      setHasChanges(!isEqual(adventure, newState));
      return newState;
    });
  };

  const handleDropdownChange = (event, { name, value }) => {
    setUpdatedFields(prevState => {
      const newState = { ...prevState, [name]: value };
      setHasChanges(!isEqual(adventure, newState));
      return newState;
    });
  };

  const handleWordBankInputChange = (event) => {
    const input = event.target.value ?? ''; // provide a default value of an empty string if the value is `null` or `undefined`
    setWordBankInput(input);
    const value = input.split(/\s*[,|\n]\s*/).filter(item => item !== '');
    setUpdatedFields(prevState => {
      const newState = { ...prevState, wordBank: value };
      setHasChanges(!isEqual(adventure, newState));
      return newState;
    });
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("UPDATING")

    setLoading(true);

    await updateAdventure(adventureId, { ...updatedFields, dateEdited: new Date() });

    setAdventure({ ...adventure, ...updatedFields, joinCode: adventure.joinCode });

    setIsEditing(false);
    setHasChanges(false);

    setLoading(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUpdatedFields({
      groupName: adventure.groupName,
      readingLevel: adventure.readingLevel,
      language: adventure.language,
      theme: adventure.theme,
      wordBank: adventure.wordBank
    });
    setWordBankInput(Array.isArray(adventure?.wordBank) ? adventure.wordBank.join("\n") : '');

    setHasChanges(false);
  };

  // const handleStoryClick = (adventureStoryId) => {
  //   console.log("adventureStoryId====>>>", adventureStoryId);
  //   navigate(`/adventure-story/${adventureStoryId}`);
  // };

  const handleStoryClick = (adventureStoryId) => {
    console.log("adventureStoryId====>>>", adventureStoryId);

    navigate(`/adventure-story/${adventureStoryId}`, { state: { adventure } });
  };



  const handleReaderClick = (readerId) => {
    console.log(readerId);
    // you might want to navigate to the reader stats page here
    // navigate(`/reader/${readerId}`);
  };

  console.log("!!!!!! adventure====>", adventure)

  if (!currentUser) {
    navigate('/');
    return null;
  }

  if (!adventure) { return <Container>Loading...</Container> }

  return (
    <>
      <Container>
        <Modal open={showSimpleDeleteModal} onClose={() => setShowSimpleDeleteModal(false)}>
          <Modal.Header>
            <div className="center">
              <h1>Delete Adventure Group</h1>
            </div>
          </Modal.Header>
          <Modal.Content>
            <h4 style={{ color: "black" }}>Are you sure you want to delete the {updatedFields.groupName}? This action is not reversable.</h4>
          </Modal.Content>
          <Modal.Actions>
            <div className="center">
              <Button onClick={() => setShowSimpleDeleteModal(false)}>No</Button>
              <Button color='red' onClick={confirmDelete}>Yes</Button>
            </div>
          </Modal.Actions>
        </Modal>

        <Modal
          open={showComplexDeleteModal}
          onClose={() => setShowComplexDeleteModal(false)}>
          <Modal.Header>
            <div className="center">
              Delete Adventure Group
            </div>
          </Modal.Header>
          <Modal.Content>
            <h4 style={{ color: "black" }}>The following readers have created stories. {"\n"}Are you sure you want to delete the {updatedFields.groupName}? All stories will be PERMANENTLY erased.</h4>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowComplexDeleteModal(false)}>No</Button>
            <Button color='red' onClick={confirmComplexDelete}>Yes</Button>
          </Modal.Actions>
        </Modal>

        <h2 className="center">{adventure.groupName}</h2>

        {isEditing ? (
          <>
            <h5 className="center">EDIT</h5>

            <Form onSubmit={handleUpdateSubmit}>
              <Form.Input
                label='Group Name'
                name='groupName'
                value={updatedFields.groupName}
                onChange={handleInputChange}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />

              <Form.Field
                label='Reading Level'
                control={Dropdown}
                placeholder='Reading Level'
                name='readingLevel'
                value={updatedFields.readingLevel}
                onChange={handleDropdownChange}
                options={[
                  { key: 'k-1', value: 'Kinder - 1st', text: 'Kinder - 1st' },
                  { key: '2-3', value: '2nd to 3rd', text: '2nd to 3rd' },
                  { key: '4-5', value: '4th to 5th', text: '4th to 5th' },
                  { key: '6-above', value: '6th and above', text: '6th and above' },
                ]}
              />

              <Form.Field
                label='Language'
                control={Dropdown}
                placeholder='Language'
                name='language'
                value={updatedFields.language}
                onChange={handleDropdownChange}
                options={[
                  { key: 'english', value: 'English', text: 'English' },
                  { key: 'spanish', value: 'Spanish', text: 'Spanish' },
                ]}
              />

              <Form.Input
                label='Theme'
                name='theme'
                value={updatedFields.theme}
                onChange={handleInputChange}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />

              <Form.Field
                label='Word Bank / Phonics'
                control='textarea'
                name='wordBank'
                value={wordBankInput}
                onChange={handleWordBankInputChange}
              />


              <h3 className="center" style={{ fontSize: "1em", color: "black" }}>
                Last Edited: {getDate(adventure?.dateEdited)}
              </h3>

              <br />

              <div className="center">
                <Button
                  type='submit'
                  loading={loading}
                  color={!loading && hasChanges ? 'blue' : null}
                  disabled={!hasChanges || loading}>
                  Update
                </Button>


                <Button onClick={handleCancel}>Cancel</Button>
              </div>

              <br />


            </Form>

          </>
        ) : (
          <>

            {readersData && Object.entries(readersData).length > 0 && (
              <div className="section">
                <div className="section-title">
                  <h2>Readers {Object.entries(readersData).length}</h2>
                </div>
                <ul>
                  {Object.entries(readersData).map(([readerId, readerData]) => (
                    <React.Fragment key={readerId}>
                      <li>
                        <a href="#" onClick={() => handleReaderClick(readerId)}>
                          {readerData.readerDisplayName}
                        </a>
                      </li>
                      {Object.entries(readerData).map(([storyId, storyData]) => {
                        if (storyId !== 'readerDisplayName') {
                          return (

                            storyData ?
                              <>
                                <ul key={storyId}>
                                  <li>
                                    <a href="#" onClick={() => handleStoryClick(storyId)}>
                                      {storyData[0]}
                                    </a>
                                  </li>
                                </ul>
                              </>
                              : null

                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            )}

            <div className="section">
              <div className="section-title">
                <h2>Adventure Details</h2>
              </div>
              <p>Reading Level: {adventure.readingLevel}</p>
              <p>Language: {adventure.language}</p>
              <p>Theme: {adventure.theme}</p>
              {updatedFields?.wordBank
                ?
                <p>Word Bank / Phonics: {typeof adventure.wordBank === "string" ? adventure.wordBank : adventure.wordBank ? adventure.wordBank.join(", ") : ''}</p>

                : null}
              <p>Join Code: {adventure.joinCode}</p>

              <div className="center">
                <Button color="black" onClick={() => setIsEditing(true)}>Edit</Button>
                <Button color="black" onClick={() => navigate('/adventures')}>Cancel</Button>



              </div>

            </div>
          </>
        )
        }

        <div className="center">
          <Button style={{ backgroundColor: "#ff8080" }} onClick={handleDelete}> ❌ Delete  ❌</Button>
        </div>
      </Container >
    </>
  );
}
