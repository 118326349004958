import React from 'react'
import { Button, Container, Icon, Modal } from 'semantic-ui-react'
import RevealText from './RevealText';
import RevealImage from './RevealImage';
import RevealTitle from './RevealTitle';
import Lottie from "lottie-react";
import AiOrbLoader from '../../images/136808-ai-orb.json';
import WrapInSpanDisplay from './WrapInSpanDisplay';
import { currentStoryDisplayStableDiffMap } from '../../constants/siteTextMap'


// {currentStoryDisplayStableDiffMap[language]?.illustrationStyleSelction || currentStoryDisplayStableDiffMap['English'].illustrationStyleSelction}

export default function CurrentStoryDisplayStableDiff(props) {
  const { keeper, clearAllPageState, newPicture, size, text, title, imageURL, responseAI, wordBank = [], openAbort2Modal, setOpenAbort2Modal, confirmStoryCancel, language } = props


  console.log("languaue=====?", language)

  const ModalCancelPart2 = () => (
    <Modal
      open={openAbort2Modal}
      onClose={() => setOpenAbort2Modal(false)}>
      <Modal.Header>{currentStoryDisplayStableDiffMap[language]?.confirmCancel || currentStoryDisplayStableDiffMap['English'].confirmCancel}</Modal.Header>

      <Modal.Content>
        <p>{currentStoryDisplayStableDiffMap[language]?.confirmCancelMessage || currentStoryDisplayStableDiffMap['English'].confirmCancelMessage}</p>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={(e) => {
          e.stopPropagation();
          confirmStoryCancel();
          setOpenAbort2Modal(false);
        }}>{currentStoryDisplayStableDiffMap[language]?.yes || currentStoryDisplayStableDiffMap['English'].yes}
        </Button>
        <Button onClick={(e) => {
          e.stopPropagation();
          setOpenAbort2Modal(false);
        }}>{currentStoryDisplayStableDiffMap[language]?.no || currentStoryDisplayStableDiffMap['English'].no}
        </Button>
      </Modal.Actions>

    </Modal>
  );

  console.log("openAbort2Modal==--===>>", openAbort2Modal)

  return (<>
    <ModalCancelPart2 />
    <br />
    <br />
    {title && <h2>{title}</h2>}

    {imageURL
      ?
      <div className="center">
        <RevealImage
          src={imageURL}
          onClick={newPicture}
          alt="ai_image"
        />
      </div>
      :
      <Lottie animationData={AiOrbLoader} loop={true} style={{ height: 200, position: "relative" }} />}


    {text && <WrapInSpanDisplay storyText={text} wordBank={wordBank} />}


    {responseAI === "part3"
      ? <>
        <br />
        <Button size="huge" onClick={keeper} color="green">{currentStoryDisplayStableDiffMap[language]?.keeper || currentStoryDisplayStableDiffMap['English'].keeper}</Button>
        <br />
        <br />
        <div>
          <Button size="medium" onClick={clearAllPageState} color="yellow">{currentStoryDisplayStableDiffMap[language]?.tryAgain || currentStoryDisplayStableDiffMap['English'].tryAgain}</Button>
        </div>
        <br />
      </>
      :


      <div className="center" onClick={() => { console.log("--- DIV CLICKED ---"); setOpenAbort2Modal(true); }}>
        <Icon color="black" inverted circular name='cancel' size="large" />
      </div>


    }


  </>)
}
