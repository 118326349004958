// PrivacyPolicy.jsx
import React from 'react';
import PrivacyPolicy from '../../content/PrivacyPolicyText';


function PrivacyPolicyPage() {

  return (
    <div>
      <br />
      {PrivacyPolicy()}

    </div>
  );
}

export default PrivacyPolicyPage;
