import React, { useState, useEffect } from "react";
import { Slider } from "react-semantic-ui-range";
import { Grid, Input } from "semantic-ui-react";

const SliderComponent = ({ onSliderChange, DRA_Level = 35 }) => {
  const [value, setValue] = useState(DRA_Level === "A" ? 0 : DRA_Level);

  useEffect(() => {
    setValue(DRA_Level === "A" ? 0 : DRA_Level);
  }, [DRA_Level]);

  const settings = {
    start: DRA_Level === "A" ? 0 : DRA_Level,
    min: 0,
    max: 80,
    step: 1,
    onChange: value => {
      const newValue = value <= 80 ? value : 80;
      setValue(newValue);
      onSliderChange(newValue === 0 ? "A" : newValue);
    }
  };

  const handleValueChange = e => {
    let value = e.target.value.toUpperCase();
    if (value === "A") {
      setValue(0);
      onSliderChange("A");
    } else {
      let intValue = parseInt(value);
      if (isNaN(intValue)) {
        intValue = 0;
      } else {
        intValue = Math.min(80, intValue);
      }
      setValue(intValue);
      onSliderChange(intValue === 0 ? "A" : intValue);
    }
  };

  return (
    <Grid>
      <Grid.Column width={16}>
        <div style={{ width: '65%', margin: 'auto' }}>
          <Slider value={value} color="green" settings={settings} />
        </div>
      </Grid.Column>
      <Grid.Column width={16}>
        <Input value={value === 0 ? "A" : value} placeholder="Enter DRA Level" onChange={handleValueChange} />
      </Grid.Column>
    </Grid>
  );
};

export default SliderComponent;
