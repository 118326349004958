// generateQuestion.js

import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;

const requestedItemToGenerate="newQuestion"

const generateQuestion = ({
  userId,
  storyId,
  language,
  chatModelVersion,

  title,
  text,
  summary,
  theme,
  vocabulary,
  quizArray,
  setting,
  characters,
  conflict,
  resolution,
  prompt,

  cancelToken,
  intervalIdRef1
}) => {


  const enhanceStoryRequest = {
    userId,
    storyId,
    language,
    chatModelVersion,

    title,
    text,
    summary,
    theme,
    vocabulary,
    quizArray,
    setting,
    characters,
    conflict,
    resolution,
    prompt,

    requestedItemToGenerate // temp added here 
  
  };


  
  return new Promise((resolve, reject) => {
    if (!cancelToken) {
      reject(new Error("Cancel token is not initialized"));
      return;
    }

    axios.post(baseUrl + "/gpt4-1106-c-redis/generate-enhancements/", enhanceStoryRequest, { cancelToken // Use the token directly
    } )

    .then(response => {
      const jobId = response.data.jobId;
      console.log("Job CreateNewQuestion ID retrieved for UPDATE story:", jobId);

      intervalIdRef1.current = setInterval(() => {
        axios.get(baseUrl + `/gpt4-1106-c-redis/generate-enhancements/status/${jobId}`, { cancelToken })

          .then(response => {
            const { status, data, returnvalue} = response.data;

// console.log("returnvalue", returnvalue)
//             if (status === 'completed') {
//               clearInterval(intervalIdRef1.current);
//               resolve(data);
//             } else if (status === 'failed') {
//               clearInterval(intervalIdRef1.current);
//               reject(new Error('Edit job failed'));
//             }

            if (status === 'completed') {
              clearInterval(intervalIdRef1.current);
              // Make sure to parse returnvalue.newQuestion since it's a JSON string
              const questionObject = JSON.parse(returnvalue.newQuestion);
              resolve(questionObject);
          } else if (status === 'failed') {
              clearInterval(intervalIdRef1.current);
              reject(new Error('Edit job failed'));
          }
          

            console.log("Status for UPDATE story job:", status);
          })
          .catch(err => {
            clearInterval(intervalIdRef1.current);
            // Commented out the cancel check as cancelToken usage has been commented out
            // if (axios.isCancel(err)) {
            //   consaole.log("Edit story request cancelled by the user");
            // } else {
            console.log("Error checking UPDATE story status:", err.message);
            reject(err);
            // }
          });
      }, 5000);
    })
    .catch(err => {
      console.log("Error submitting UPDATE story request:", err.message);
      reject(err);
    });
  });
};


export default generateQuestion;