//App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { onSnapshot, doc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import "./styles/App.css";

// Context - FINISH
import { StoryProvider } from "./contexts/StoryContext";

import Home from "./components/pages/Home";
import CreateDalle2 from "./components/pages/CreateDalle2";
import CreateDalle3 from "./components/pages/CreateDalle3";
import CreateStableDiff from "./components/pages/CreateStableDiff";

import CreateDown from "./components/pages/CreateDown";
//import CreateStoryMain from "./components/pages/CreateStoryMain"

import LoginMain from "./components/pages/LoginMain";
import LoginGoogle from "./components/pages/LoginGoogle";
import LoginSpecialPromo from "./components/pages/LoginSpecialPromo";
import LoginEmailConfirm from "./components/pages/LoginEmailConfirm";
import About from "./components/pages/About";
import Billing from "./components/payment/Billing.jsx";
import BillingUpgradePlan from "./components/payment/BillingUpgradePlan.jsx";
import Crypto from "./components/pages/Crypto";
import Stories from "./components/pages/Stories";
import StoriesArchived from "./components/pages/StoriesArchived";
import StoryDisplayMain from "./components/pages/StoryDisplayMain";
import StoryRestore from "./components/pages/StoryRestore";
import StoryEditMode from "./components/pages/StoryEditMode";
import Profile from "./components/pages/Profile";
import ErrorPage from "./components/pages/ErrorPage";
import Responsive from "./components/pages/Responsive";
import TheBots from "./components/pages/TheBots";
import Payment from "./components/payment/Payment";
import Membership from "./components/payment/Membership";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import PaymentFailure from "./components/payment/PaymentFailure";
import Stripe from "./components/payment/Stripe";

import CreateThemedStory from "./components/pages/CreateComponents/CreateThemedStory";
import EnhanceMyStory from "./components/pages/EnhanceMyStory";
import NewStoryDisplay from "./components/pages/CreateComponents/NewStoryDisplay";
import NewStoryAddPicture from "./components/pages/NewStoryAddPicture";

import TermsOfService from "./components/pages/TermsOfService";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Contact from "./components/pages/Contact";

// import StripeCheckoutForm from "./cmponents/payment/StripeCheckoutForm";

import Venmo from "./components/payment/Venmo";
import PayPal from "./components/payment/PayPal";
import ApplePay from "./components/payment/ApplePay";
import PasswordReset from "./components/pages/PasswordReset";

import NewUserAgreement from "./components/pages/NewUserAgreement";
import NewUserDeclined from "./components/pages/NewUserDeclined";
import NewUserSetup from "./components/pages/NewUserSetup";

import LanguageSelection from "./components/pages/LanguageSelection";
import ReadingAdventures from "./components/pages/ReadingAdventures";
import ReadingAdventureDetails from "./components/pages/ReadingAdventureDetails";
import ReadingAdventureStoryDisplay from "./components/pages/ReadingAdventureStoryDisplay";
import ReadingAdventureStoryEdit from "./components/pages/ReadingAdventureStoryEdit";
import ReadingAdventureTeacherClassrooms from "./components/pages/ReadingAdventureTeacherClassrooms.jsx";

import Promo from "./components/pages/Promo";
import Navigation from "./Navigation";

function App(props) {
  const { db, auth, onAuthStateChanged, storage } = props;
  const [currentUser, setCurrentUserTruth] = useState(null);
  const [firestoreUserData, setFirestoreUserDataTruth] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [showNavbar, setShowNavbar] = useState(true);
  const [avatar, setAvatar] = useState(null);

  const [siteOnline, setSiteOnline] = useState(
    process.env.NODE_ENV === "development" ? true : false
  );

  const [imageApi, setImageApi] = useState("Dalle3");

  const [chatModelVersion, setChatModelVersion] = useState("gpt-4");

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUserTruth(user);
    });
    return () => {
      console.log(" ********* UNMOUNT  ********* ");
      unsubAuth();
    };
  }, []);

  // realtime
  useEffect(() => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser?.uid);
      onSnapshot(docRef, (snapshot) => {
        const newData = snapshot.data();
        setFirestoreUserDataTruth(() => newData);
      });
    }
  }, [currentUser]);

  // siteOnline
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      // console.log("process.env.NODE_ENV", process.env.NODE_ENV)
      const siteDocRef = doc(db, "picturebooks-settings", "website");
      const unsubSite = onSnapshot(siteDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const onlineStatus = docSnap.data().siteOnline;
          setSiteOnline(onlineStatus);
          if (!onlineStatus && currentUser) {
            // Log out the user if the site is offline
            signOut(auth).then(() => {
              // Redirect or show a notification here
              console.log("Logged out due to maintenance");
            });
          }
        }
      });
      return () => unsubSite();
    }
  }, [currentUser]);

  // imageApi
  useEffect(() => {
    const docRef = doc(db, "picturebooks-settings", "website");
    const unsubImageApi = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();

        if (data.imageApi) {
          setImageApi(data.imageApi);
        } else {
          console.error("imageApi field not found in the data:", data);
        }
      } else {
        console.error("Document does not exist");
      }
    });
    return () => unsubImageApi();
  }, []);

  // chatModelVersion
  useEffect(() => {
    const docRef = doc(db, "picturebooks-settings", "website");
    const unsubChatModelVersion = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();

        if (data.chatModelVersion) {
          setChatModelVersion(data.chatModelVersion);
        } else {
          console.error("chatModelVersion field not found in the data:", data);
        }
      } else {
        console.error("Document does not exist");
      }
    });
    return () => unsubChatModelVersion();
  }, []);

  const setFirestoreUserData = (fsud) => {
    setFirestoreUserDataTruth(() => fsud);
  };

  const commonProps = {
    ...props,
    currentUser,

    setFirestoreUserData,
    firestoreUserData,

    showNavbar,
    setShowNavbar,

    imageUrls,
    setImageUrls,

    chatModelVersion,
    avatar,

    siteOnline,

    imageApi,
  };

  return (
    <StoryProvider>
      <Router>
        {currentUser ? (
          <Container>{showNavbar && <Navigation {...commonProps} />}</Container>
        ) : null}

        <Routes>
          <Route path="/" element={<LoginMain {...commonProps} />} />

          <Route exact path="/home" element={<Home {...commonProps} />} />

          <Route
            exact
            path="/createdalle2"
            element={<CreateDalle2 {...commonProps} />}
          />

          <Route
            exact
            path="/createstablediff"
            element={<CreateStableDiff {...commonProps} />}
          />

          <Route
            exact
            path="/createdalle3"
            element={<CreateDalle3 {...commonProps} />}
          />

          <Route
            exact
            path="/newstorydisplay"
            element={<NewStoryDisplay {...commonProps} />}
          />

          <Route
            exact
            path="/newstoryaddpicture"
            element={<NewStoryAddPicture {...commonProps} />}
          />

          <Route
            path="/createthemedstory"
            element={<CreateThemedStory {...commonProps} />}
          />
          <Route
            path="/enhancemystory"
            element={<EnhanceMyStory {...commonProps} />}
          />

          {/* 
        <Route exact path="/createstorymain"
          element={<CreateStoryMain {...commonProps} />} /> */}

          <Route
            exact
            path="/createdown"
            element={<CreateDown {...commonProps} />}
          />

          <Route exact path="/about" element={<About {...commonProps} />} />

          <Route exact path="/login" element={<LoginMain {...commonProps} />} />

          <Route
            exact
            path="/loginspecialpromo"
            element={<LoginSpecialPromo {...commonProps} />}
          />

          <Route
            exact
            path="/logingoogle"
            element={<LoginGoogle {...commonProps} />}
          />

          <Route exact path="/billing" element={<Billing {...commonProps} />} />

          <Route exact path="/upgradeplan" element={<BillingUpgradePlan {...commonProps} />} />

          <Route exact path="/crypto" element={<Crypto {...commonProps} />} />

          <Route exact path="/stories" element={<Stories {...commonProps} />} />

          <Route
            exact
            path="/storiesdeleted"
            element={<StoriesArchived {...commonProps} />}
          />

          <Route
            exact
            path="/storydisplaymain/:storyId"
            element={<StoryDisplayMain {...commonProps} />}
          />

          <Route
            exact
            path="/storyeditmode/:storyId"
            element={<StoryEditMode {...commonProps} />}
          />

          <Route
            exact
            path="/storyrestore/:storyId"
            element={<StoryRestore {...commonProps} />}
          />

          <Route exact path="/profile" element={<Profile {...commonProps} />} />

          <Route
            exact
            path="/loginemailconfirm"
            element={<LoginEmailConfirm {...commonProps} />}
          />

          <Route
            exact
            path="/passwordreset"
            element={<PasswordReset {...commonProps} />}
          />

          <Route exact path="/stripe" element={<Stripe {...commonProps} />} />

          <Route
            exact
            path="/membership"
            element={<Membership {...commonProps} />}
          />

          <Route exact path="/venmo" element={<Venmo {...commonProps} />} />

          <Route exact path="/applepay" element={<ApplePay {...commonProps} />} />

          <Route exact path="/paypal" element={<PayPal {...commonProps} />} />

          <Route exact path="/promo" element={<Promo {...commonProps} />} />

          <Route
            path="/new-user-agreement"
            element={<NewUserAgreement {...commonProps} />}
          />

          <Route
            path="/new-user-setup"
            element={<NewUserSetup {...commonProps} />}
          />

          <Route
            path="/new-user-declined"
            element={<NewUserDeclined {...commonProps} />}
          />

          <Route
            path="/language-selection"
            element={<LanguageSelection {...commonProps} />}
          />

          <Route
            path="/adventures"
            element={<ReadingAdventures {...commonProps} />}
          />

          <Route
            path="/adventure-details/:adventureId"
            element={<ReadingAdventureDetails {...commonProps} />}
          />

          <Route
            path="/adventure-story/:adventureStoryId"
            element={<ReadingAdventureStoryDisplay {...commonProps} />}
          />

          <Route
            path="/adventure-story-edit/:adventureStoryId"
            element={<ReadingAdventureStoryEdit {...commonProps} />}
          />

          <Route
            path="/reading-adventure-teacher-classrooms"
            element={<ReadingAdventureTeacherClassrooms {...commonProps} />}
          />

          <Route exact path="/payment" element={<Payment {...commonProps} />} />

          <Route
            exact
            path="/completion"
            element={<PaymentSuccess {...commonProps} />}
          />

          <Route
            exact
            path="/payment-failure"
            element={<PaymentFailure {...commonProps} />}
          />

          <Route path="/responsive" element={<Responsive />} />
          <Route path="/thebots" element={<TheBots />} />

          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <br />
        <br />
        <br />
        <br />
        <br />
        <footer className="center">
  <a href="https://beechtree.ai" target="_blank" rel="noopener noreferrer">
    © BeechTree 2024
  </a>
</footer>

        <br />
      </Router>
    </StoryProvider>
  );
}

export default App;
