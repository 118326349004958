// updateUderPromise.jsx

import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';

const updateUserPromise = ({ userId, updatedFields }) => {
  const docRef = doc(db, 'users', userId);
  return updateDoc(docRef, updatedFields); // Return the promise
};

export default updateUserPromise;
