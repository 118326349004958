// LoginSignupEmail.jsx

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { HELP_EMAIL } from "../../constants/adminConsts.js"
import { BEGINNING_CREDITS } from '../../constants/newUserConsts';
import checkDeletedUser from '../api/checkDeletedUser';

export default function LoginSignupEmail(props) {
  const { setShowSignUp, showErrorDisplay } = props;
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const handleSignUp = async (evt) => {
    evt.preventDefault();

    try {
      const emailAddressIsBanned = await checkDeletedUser(email);

      if (emailAddressIsBanned) {
        showErrorDisplay(
          `Sorry, but that email can not be used to create a new account. If this is an error or you have any questions, email ${HELP_EMAIL} `
        );
        return;
      }


      const cred = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(cred.user);

      const userId = cred.user.uid;

      const newUser = {
        accountType: 'free',
        adminMessage: null,
        avatarSeed: userId,
        credit: BEGINNING_CREDITS,
        defaultLanguage: "English",
        email: cred.user.email,
        googleAuth: false,
        emailPasswordAuth: true,
        numberOfLogins: 1,
        newUser: true,
        newUserTermsAgreement: false,
        photoURL: cred.user.photoURL,
        suspendedAccount: false,
        suspensionMessage: null,
        userId,
        userDisplayName: cred.user.displayName,
        dateCreated: serverTimestamp(),
        dateLastLoggedIn: serverTimestamp(),
      };

      await setDoc(doc(db, 'users', userId), newUser);
    } catch (err) {

      showErrorDisplay(err.message);
      console.error('Error:', err);

    }
  };

  return (
    <>
      <style>
        {`
        @media (max-width: 600px) {
          .login-form-container {
            width: 90%;
          }
        }
      `}
      </style>
      <div className="center">
        <div className="login-form-container">
          <h1 className="center" style={{ color: 'orange', fontSize: '2.5em' }}>Please Enter:</h1>
          <form onSubmit={handleSignUp} className="login-form" autocomplete="off">
            <div className="form-group">
              <label>Email:</label>
              <input
                className="form-input"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                className="form-input"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)} />
            </div>
            <br />

            <div className="center">
              <Button size="massive" color="orange" type="submit">Create Account</Button>
            </div>

          </form>
          <div className="center">
            <br />
            <br />
            <br />
            <Button size="tiny" color="black" onClick={() => setShowSignUp(true)}>Return to Sign-in</Button>
          </div>
        </div>
      </div>
    </>
  );
}

