import React from 'react'
import { Container } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom'

export default function Venmo({ currentUser }) {

  const navigate = useNavigate();

  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (
    <Container>
      <h2 className="center" style={{ fontSize: "5em" }}>Venmo</h2>
      <h2 className="center" style={{ fontSize: "2em" }}>Coming Soon!</h2>
    </Container >
  )
}
