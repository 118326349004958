// ReadingAdvenureTeacherClassroom.jsx

import React, { useState, useEffect } from 'react';
import { Container, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
// import { serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { getTeacherClassrooms, updateCurrentClassroom, createNewClassroom } from '../api/readingAdventureTeacherClassroomsService';
// import Lottie from "lottie-react";
// import loadingAnimation from '../../images/loading-animation.json';



export default function ReadingAdventureTeacherClassrooms({ firestoreUserData, currentUser }) {
  const [newClassroomName, setNewClassroomName] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState(null);
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (firestoreUserData && firestoreUserData.userId) {
      // setLoading(true)
      getTeacherClassrooms(firestoreUserData.userId)
        .then((classroomsData) => {
          setClassrooms(classroomsData);
          setSelectedClassroomId(firestoreUserData.readingAdventureTeacherClassroomCurrentId);
          // setLoading(false)
        });
    }
  }, [firestoreUserData]);


  function handleNewClassroomNameChange(event) {
    setNewClassroomName(event.target.value);
  }

  async function handleCreateNewClassroom() {
    if (newClassroomName.trim() === "") {
      console.error("Classroom name cannot be empty");
      return;
    }

    const classroomId = uuidv4();
    const newClassroom = {
      classroomId: classroomId,
      classroomName: newClassroomName,
      students: [],
    };
    // setLoading(true)
    await createNewClassroom(firestoreUserData.userId, newClassroom);
    setClassrooms([...classrooms, newClassroom]);
    setNewClassroomName("");
    // setLoading(false)
  }


  async function handleClassroomClick(classroomId) {
    await updateCurrentClassroom(firestoreUserData.userId, classroomId);
    setSelectedClassroomId(classroomId);
    navigate('/adventures');
  }

  // if (loading) {
  //   return <Lottie animationData={loadingAnimation} loop={true} style={{ height: 600, position: "relative" }} />
  // }

  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (
    <Container>
      <h2 className="center">Classrooms</h2>

      {classrooms && classrooms.map((classroom) => (
        <div
          key={classroom.classroomId}
          onClick={() => handleClassroomClick(classroom.classroomId)}
          style={{
            backgroundColor: selectedClassroomId === classroom.classroomId ? 'yellow' : 'transparent',
            cursor: 'pointer'
          }}
        >
          <h3>{classroom.classroomName} - {classroom.classroomId}</h3>
          {/* You might want to display the list of students here */}
        </div>
      ))}

      <br />

      <div className="center">

        <input
          type="text"
          placeholder="Create New Classroom"
          value={newClassroomName}
          onChange={handleNewClassroomNameChange}
          style={{ fontSize: '2em', width: '70%' }} // added style here
        />

      </div>

      <div className="center">
        <Button color="black" size="large" onClick={handleCreateNewClassroom}>Add</Button>
      </div>
    </Container>
  );
}
