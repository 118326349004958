/* eslint-disable react/prop-types */
// ReadingAdventureStoryDisplay.jsx

import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import { fetchAdventureStory } from '../api/adventureStoryService'

const ReadingAdventureStoryShow = ({ currentUser }) => {

  const { adventureStoryId } = useParams();
  const [story, setStory] = useState(null);
  const [wordBankDictionary, setWordBankDictionary] = useState({});


  const location = useLocation();
  const [adventure, setAdventure] = useState(location?.state?.adventure);  // change here

  let navigate = useNavigate();

  const editStory = () => navigate('/adventure-story-edit/' + adventureStoryId, { state: { adventure } });


  const wordStyle = { color: 'black', cursor: 'pointer', fontWeight: 300 };
  const wordBankWord = {
    color: 'green',
    cursor: 'pointer',
    fontWeight: '900',
    fontFamily: 'Arial Black, Arial, sans-serif'
  };

  function checkDictionary(words) {
    function recursiveCheck(index, remainingPhrase) {
      if (remainingPhrase.length === 0) return true; // whole phrase matched
      if (index >= words.length) return false; // end of words reached
      if (words[index].toLowerCase() === remainingPhrase[0].toLowerCase()) {
        // word matched, so check for next word
        return recursiveCheck(index + 1, remainingPhrase.slice(1));
      }
      return false; // word didn't match
    }

    for (let i = 0; i < words.length; i++) {
      for (const phrase in wordBankDictionary) {
        if (words[i].toLowerCase() === phrase.split(" ")[0].toLowerCase()) {
          const remainingPhrase = phrase.split(" ").slice(1);
          if (recursiveCheck(i + 1, remainingPhrase)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function WrapInSpanDisplay(props) {
    const { storyText } = props;
    const storyTextArray = storyText?.split(/(\s+|\n+)/);

    return (
      <div style={{ textAlign: 'left', fontWeight: 'normal' }}>
        {storyTextArray.map((text, index, array) => {
          if (text === '\n') {
            return <br key={index} />;
          }
          if (text.match(/\s+/)) {
            return (
              <span key={index} style={{ whiteSpace: 'pre' }}>
                {text}
              </span>
            );
          }

          // prepare an array of words from this word to the end of the sentence
          let words = [];
          let i = index;
          while (array[i] && !array[i].match(/\s+/) && !array[i].match(/\n/)) {
            words.push(array[i]);
            i++;
          }

          return (
            <span key={index} style={checkDictionary(words) ? wordBankWord : wordStyle}>
              {`${text} `}
            </span>
          );
        })}
      </div>
    );
  }

  console.log("adventure=====>>==>>", adventure)


  useEffect(() => {
    const wordBankArray = story?.wordBank;
    let wbd = {};

    wordBankArray?.forEach((word) => {
      if (/^[a-zA-Z ]+$/.test(word.trim())) {
        wbd[word.trim().toLowerCase()] = true;
      }
    });

    setWordBankDictionary(wbd);
  }, [story?.wordBank]);


  useEffect(() => {
    console.log("adventureStoryId==>>", adventureStoryId)
    const fetchStory = async () => {
      const storyData = await fetchAdventureStory(adventureStoryId);
      if (storyData) {
        setStory(storyData);
      } else {
        console.log("No such document!");
      }
    };
    fetchStory();
  }, [adventureStoryId]);

  useEffect(() => {
    if (!adventure && location?.state?.adventure) {
      setAdventure(location.state.adventure);
    }
  }, [location]); // change here


  if (!currentUser) {
    navigate('/');
    return null;
  }



  if (!story) {
    return <Container>
      < div>Loading...</div>
    </Container>
  }

  return (
    <Container>
      <div className="center" >
        <h1 style={{ fontSize: "2.5em" }}>{story.title}</h1>
      </div>
      {story.imageMediumDownloadURL && <div className="center">
        <img src={story.imageMediumDownloadURL} alt="Story" height="400px" width="400px" />
      </div>}

      {story?.text && <h3> <WrapInSpanDisplay storyText={story.text} /></h3>
      }

      <br />
      <br />

      <div className="center">
        <Button color="black" size="large" onClick={editStory}>
          EDIT MODE
        </Button>
      </div>

      <br />

      <div className="center">
        <Button color="blue" onClick={() => navigate('/adventure-details/' + story?.adventureData?.adventureId)}>Back to {story?.adventureData?.groupName}</Button>
      </div>
    </Container>
  );
}

export default ReadingAdventureStoryShow;
