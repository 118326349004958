// TextContentEditModal.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { story2Map } from '../../constants/siteTextMap'

import '../../styles/TextContentEditModal.css';

const TextContentEditModal = ({ open, content, contentType, onSave, onClose, currentLanguage }) => {
  const [editedContent, setEditedContent] = useState(content);

  useEffect(() => {
    setEditedContent(content);
  }, [content]);

  const handleSave = () => {
    onSave(editedContent, contentType);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} size="tiny">
      <Modal.Header>
        {/* Edit {contentType.charAt(0).toUpperCase() + contentType.slice(1)} */}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            label={story2Map[currentLanguage]?.edit || story2Map['English'].edit}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}> {story2Map[currentLanguage]?.cancel || story2Map['English'].cancel}</Button>
        <Button positive onClick={handleSave}> {story2Map[currentLanguage]?.save || story2Map['English'].save}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TextContentEditModal;
