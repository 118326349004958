// NewUserAgreement.jsx

import React, { useState, useEffect } from 'react';
import { Button, Container, Checkbox } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import updateUserPromise from '../api/updateUserPromise';
import { handleLogoutPromise } from '../helpers/handleLogoutPromise';
import deleteUserAccountPromise from '../api/deleteUserAccountPromise'
import TermsOfService from '../../content/TermsOfServiceText';

function NewUserAgreement(props) {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const controls = useAnimation();
  const navigate = useNavigate();

  const { firestoreUserData, setShowNavbar, setFirestoreUserData, currentUser } = props || {}
  const uid = props && props.currentUser ? props.currentUser.uid : null;

  useEffect(() => { setShowNavbar(false) }, []);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) { navigate('/login') }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Disable back navigation by pushing the same state again
    window.history.pushState(null, document.title, window.location.href);
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/new-user-agreement');
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);


  useEffect(() => {
    if (firestoreUserData && firestoreUserData.newUserTermsAgreement) {
      navigate('/new-user-setup');
    }
  }, [firestoreUserData, navigate]);


  const acceptTerms = async () => {
    if (termsAccepted) {
      await updateUserPromise({
        userId: uid,
        updatedFields: { newUserTermsAgreement: true },
      });
      navigate('/new-user-setup');
    }
    else {
      controls.start({
        x: [0, -5, 5, -5, 5, 0], // This creates a gentle shake effect
        // color: "blue",
        transition: { duration: 0.5 }
      });
    }
  };





  const cancelSignup = async () => {
    console.log("CANCEL");

    if (currentUser) {
      try {
        await deleteUserAccountPromise(currentUser.uid);
        await handleLogoutPromise({ setFirestoreUserData, navigate });
        navigate('/');
      } catch (error) {
        console.error('Failed to delete account or log out:', error);
      }
    }
  };


  return (
    <Container>
      {TermsOfService()}
      <br />
      <motion.div animate={controls}>
        <Checkbox
          label={{ children: "I agree to the Terms and Conditions", style: { fontFamily: 'Arial' } }}
          onChange={(e, data) => setTermsAccepted(data.checked)}
        />
      </motion.div>

      <br />
      <br />
      <div className="center">
        <Button
          onClick={acceptTerms}
          color={termsAccepted ? 'green' : null}>
          {termsAccepted ? 'Submit Agreement' : 'Check Agree'}
        </Button>
      </div>
      <br />
      <br />
      <br />
      <div className="center">
        <Button
          onClick={cancelSignup}
          color="black"
          size="small"
        >Cancel
        </Button>
      </div>
    </Container>
  );
}

export default NewUserAgreement;

