// LoginMain.jsx

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { Button, Container } from 'semantic-ui-react'
import Lottie from 'lottie-react'
import GoogleButton from 'react-google-button'
import artGraphic from '../../images/71308-artificial-intelligence-lottie-animation.json'
import { ToastContainer, toast } from 'react-toastify';
import websiteMaintenance from '../../images/webpage-under-maintenance.json';

import LoginEmail from './LoginEmail.jsx'
import LoginSignupEmail from './LoginSignupEmail.jsx'
import TermsOfService from './TermsOfService.jsx';
import PrivacyPolicy from './PrivacyPolicy.jsx';
import Contact from './Contact.jsx';

import { loginMainMap } from '../../constants/siteTextMap'
import { motion, AnimatePresence } from "framer-motion";
import '../../styles/LoginStyles.css';

const languagesArray = ["English", "Spanish", "French", "German"];


export default function Login(props) {
  const { currentUser, siteOnline } = props
  const [showSignUp, setShowSignUp] = useState("hello-user")
  const [showEmailUI, setShowEmailUI] = useState(false)
  const [SHOW_MAINTENANCE_PAGE, setSHOW_MAINTENANCE_PAGE] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("English");


  const navigate = useNavigate()
  const toggleMain = () => setShowEmailUI(!showEmailUI)

  useEffect(() => {
    const languageChangeInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * languagesArray.length);
      setCurrentLanguage(languagesArray[randomIndex]);
    }, 7000);

    return () => clearInterval(languageChangeInterval); // Cleanup
  }, []);


  useEffect(() => {
    const userDocRef = doc(db, 'picturebooks-settings', 'website');

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        setSHOW_MAINTENANCE_PAGE(!docSnap.data().siteOnline);
      } else {
        console.error('Document does not exist');
      }
    });



    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe();

  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser, navigate]);


  const MAINTENANCE_PAGE_MESSAGE = null

  const showErrorDisplay = (message) => {
    console.log("X", message, "X");
    let messageDisplay = message
    if (message === "Firebase: Error (auth/user-not-found).") {
      messageDisplay = "Sorry, either the username or password is incorrect."
    }
    toast.error(messageDisplay, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: "light"
    });
  };

  const passwordReset = () => navigate('/passwordreset')

  if (!siteOnline && SHOW_MAINTENANCE_PAGE) {
    return <Container>
      <br />
      <br />
      <div >
        <Lottie animationData={websiteMaintenance} loop={false} style={{ height: 300, position: "relative" }} />
        <h2 className="center">Picture Books is currently down for maintenance.</h2>
        {MAINTENANCE_PAGE_MESSAGE && <h3 className="center" >{MAINTENANCE_PAGE_MESSAGE}</h3>}
      </div>
      <br />


    </Container>
  }

  // if (currentUser) return navigate("/home")

  return (<Container>
    < ToastContainer />
    < h1 className="center" style={{ color: "blue", fontSize: "7em", fontFamily: "'Paytone One', sans-serif" }
    }> Picture Books</h1 >

    <AnimatePresence mode='wait'>
      <motion.h5
        key={currentLanguage}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2, type: 'tween' }}
        className="center"
      >
        {loginMainMap[currentLanguage]?.bringingImagination || loginMainMap['English'].bringingImagination}
      </motion.h5>
    </AnimatePresence>


    <Lottie
      style={{ height: 300 }}
      animationData={artGraphic}
      loop={false} />
    <br />


    <br />
    {
      showEmailUI
        ? <>
          {showSignUp
            ?
            <LoginEmail
              showErrorDisplay={showErrorDisplay}
              setShowSignUp={setShowSignUp}
              passwordReset={passwordReset}
              {...props} />
            :
            <LoginSignupEmail
              setShowSignUp={setShowSignUp}
              showErrorDisplay={showErrorDisplay}
              {...props} />
          }
        </>
        : null
    }

    <div className="center">
      {!showEmailUI && <GoogleButton onClick={() => navigate('logingoogle')} />}
    </div>
    <br />
    <div className="center">
      {!showEmailUI && <Button onClick={toggleMain} size="huge" color="black">Email and Password</Button>}
    </div>
    <br />
    <div style={{ textAlign: "center" }}>
      <h6>
        <Link to="/privacy-policy" style={{ color: "blue", fontSize: ".5em", fontFamily: "'Paytone One', sans-serif", padding: "0 10px" }}>Privacy</Link>

        <Link to="/terms-of-service" style={{ color: "blue", fontSize: ".5em", fontFamily: "'Paytone One', sans-serif", padding: "0 10px" }}>Terms of Service</Link>

        <Link to="/contact" style={{ color: "blue", fontSize: ".5em", fontFamily: "'Paytone One', sans-serif", padding: "0 10px" }}>Contact</Link>
      </h6>
    </div>
    <br />

  </Container >

  );
}
