// Stripe.jsx

import { useEffect, useState } from "react";
import { Container, Icon } from "semantic-ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { useNavigate } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Stripe(props) {
  const { db, firestoreUserData, setFirestoreUserData, currentUser, setShowNavbar } = props;

  const [loading, setLoading] = useState(false);
  const [processPayment, setProcessingPayment] = useState(false);

  const [failureExplanation, setFailureExplanation] = useState(null);


  const navigate = useNavigate();


  useEffect(() => {
    // Disable back navigation by pushing the same state again
    window.history.pushState(null, document.title, window.location.href);

    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/home');
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);


  useEffect(() => {
    if (!currentUser && !loading) {
      navigate('/');
    }
  }, [currentUser, loading, navigate]);


  const handleSuccess = (newCredits) => {
    navigate('/completion', { state: { newCredits, paymentCompleted: true } });
  };

  const handleFailure = (message) => {
    setFailureExplanation(message);
    navigate('/payment-failure', { state: { failureExplanation: message, paymentCompleted: false } });
  };

  // console.log('loading', loading)

  // if (loading) return (<h1>Loading</h1>)
  // if (processPayment) {
  //   return <h1>PROCESSING</h1>
  // }

  return (
    <>
      <Container className="credit-card-form-container">
        <h2 className="center" style={{ fontSize: "3.5em" }}>Stripe</h2>
        {/* <h2 className="center" style={{ fontSize: "1.5em" }}>One credit = One Picture Book</h2> */}
        <br />
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            firestoreUserData={firestoreUserData}
            setFirestoreUserData={setFirestoreUserData}
            db={db}
            currentUser={currentUser}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            setShowNavbar={setShowNavbar}
            loading={loading}
            setLoading={setLoading}
            setProcessingPayment={setProcessingPayment}
          />
        </Elements>
      </Container>
    </>
  );
}

export default Stripe;