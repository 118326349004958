// DesignChoicesStableDiff.jsx

import React, { useState, useEffect } from 'react'

import { ILLUSTRATION_BUTTON_IMAGE_URLS } from '../../constants/illustrationsConsts'
import { ILLUSTRATION_STYLES } from '../../constants/illustrationsConsts'
import { motion, AnimatePresence } from 'framer-motion';

import { illustrationStylesMap, createStoryMap } from '../../constants/siteTextMap'
import '../../styles/DesignChoices.css';
import useFetchPrompts from '../hooks/useFetchPrompts'


import image1 from '../../images/button-images/image1_oil.jpg';
import image2 from '../../images/button-images/image2_cartoon.jpg';
import image3 from '../../images/button-images/image3_realistic.jpg';

import image4 from '../../images/button-images/image4_comic.jpg';

import image5 from '../../images/button-images/image5_line.jpg';
import image6 from '../../images/button-images/image6_abstract.jpg';

import image7 from '../../images/button-images/image7_dreamlike.jpg';

import image8 from '../../images/button-images/image8_vintage.jpg';

import image9 from '../../images/button-images/image9_cyberpunk.jpg';


import image10 from '../../images/button-images/image10_picasso.jpg';
import image11 from '../../images/button-images/image11_vangogh.jpg';
import image12 from '../../images/button-images/image12_subdued.jpg';
import image13 from '../../images/button-images/image13_manga.jpg';
import image14 from '../../images/button-images/image14_collage.jpg';
import image15 from '../../images/button-images/image15_watercolor.jpg';
import image16 from '../../images/button-images/image16_emoji.jpg'



export default function DesignChoicesStableDiff(props) {
  const {
    language,
    illustrationType,
    setIllustrationType,
    illustrationObject,
    setIllustrationObject,
  } = props

  const [gridVisible, setGridVisible] = useState(false);
  const [activityTimeout, setActivityTimeout] = useState(null);

  const illustrationPromptsFirebase = useFetchPrompts();



  const illustrationImages = [
    image1,
    image2,
    image3,
    image4, // comic
    image5,
    image6,
    image7, // dreamlike
    image8,
    image9, // cyberpunk
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16 // emoji
  ];

  useEffect(() => {
    const tempValue = 0 // later store this in state at applevl

    if (illustrationPromptsFirebase) {

      setIllustrationObject({
        tempValue,
        styleSelected,
        buttonUrl: ILLUSTRATION_BUTTON_IMAGE_URLS[tempValue],
        firebaseSeederPrompt: illustrationPromptsFirebase[styleSelected],
        styleSelectedPresentationText: illustrationStylesMap[language]
          ? illustrationStylesMap[language][styleSelected]
          : illustrationStylesMap['English'][styleSelected]
      })
    }

  }, [illustrationPromptsFirebase])


  // useEffect(() => {
  //   if (gridVisible) {
  //     clearTimeout(activityTimeout);
  //     setActivityTimeout(setTimeout(() => {
  //       setGridVisible(false);
  //     }, 6000));
  //   }
  // }, [gridVisible]);


  const toggleGridVisibility = () => {
    setGridVisible(!gridVisible);
  };



  const handleGridItemClick = (value, e) => {
    const gridItemElement = e.currentTarget;

    // Apply the pressed effect
    gridItemElement.classList.add('gridItemPressed');

    // Clear any existing timeout
    clearTimeout(activityTimeout);

    // Reset the activity timeout
    // setActivityTimeout(setTimeout(() => {
    //   setGridVisible(false);
    // }, 6000));

    // Remove the pressed effect after 100ms
    setTimeout(() => {
      gridItemElement.classList.remove('gridItemPressed');
    }, 100);

    // Update the illustrationType state based on the clicked grid item
    const styleSelected = ILLUSTRATION_STYLES[value];
    setIllustrationType([
      value,
      styleSelected,
      ILLUSTRATION_BUTTON_IMAGE_URLS[value],
      illustrationPromptsFirebase[styleSelected]
    ]);

    setIllustrationObject({
      value,
      styleSelected,
      buttonUrl: ILLUSTRATION_BUTTON_IMAGE_URLS[value],
      firebaseSeederPrompt: illustrationPromptsFirebase[styleSelected],
      styleSelectedPresentationText: illustrationStylesMap[language]
        ? illustrationStylesMap[language][styleSelected]
        : illustrationStylesMap['English'][styleSelected]
    })


  };


  const styleSelected = illustrationType[1]
  console.log("styleSelected===>>", styleSelected)
  console.log("illustrationPromptsFirebase===>>", illustrationPromptsFirebase ? illustrationPromptsFirebase[styleSelected] : null);


  return (
    <>
      <div className="gridContainer">


        <img
          onClick={toggleGridVisibility}
          src={illustrationImages[illustrationType[0]]}
          alt="illustration"
          className="thickBlackBorder"
          style={{ width: '150px', height: '150px' }}
        />

        <h4 style={{ color: "grey", fontSize: "1.5em" }} onClick={toggleGridVisibility}>
          {illustrationStylesMap[language]
            ? illustrationStylesMap[language][styleSelected]
            : illustrationStylesMap['English'][styleSelected]}
        </h4>


        <AnimatePresence>
          {gridVisible && (<>
            <div style={{ border: "4px solid grey", backgroundColor: "grey" }}>
              <motion.div
                className="gridMenu"
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }} >

                {illustrationImages.map((imgSrc, index) => (
                  <div
                    key={index}
                    onClick={(e) => handleGridItemClick(index, e)}
                    className="gridItem"
                  >
                    <div className="gridItemImageContainer">
                      <img src={imgSrc} alt={`illustration-${index}`} className="fixedSizeImage" />
                    </div>
                  </div>
                ))}

              </motion.div>
              <h4 style={{ fontFamily: "Arial Black", color: "black" }}>
                {createStoryMap[language]?.chooseIllustrationStyle || createStoryMap['English'].chooseIllustrationStyle}
              </h4>

            </div>

          </>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
