
const WrapInSpanDisplay = (props) => {
  const { storyText, wordBank } = props;
  const storyTextArray = storyText?.split(/(\s+|\n+)/);
  const wordBankWord = { color: 'green', cursor: 'pointer', fontSize: '1.5em', fontWeight: 900 };
  const wordStyle = { color: 'black', fontSize: '1.5em', cursor: 'pointer' };

  function checkDictionary(word) {
    let cleanWord = word.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ').toLowerCase();
    if (wordBank.includes(cleanWord)) {
      return true;
    }
    if (cleanWord.endsWith('s')) {
      let singularWord = cleanWord.slice(0, -1);
      if (wordBank.includes(singularWord)) {
        return true;
      }
    }
    return false;
  }


  return (
    <div style={{ textAlign: 'left', fontWeight: 'normal' }}>
      {storyTextArray.map((text, index) => {
        if (text === '\n') { return <br key={index} /> }
        if (text.match(/\s+/)) {
          return (
            <span key={index} style={{ whiteSpace: 'pre' }}>
              {text}
            </span>
          );
        }
        return (
          <span key={index} style={wordBank.length > 0 && checkDictionary(text) ? wordBankWord : wordStyle}>
            {`${text} `}
          </span>
        );
      })}
    </div>
  );
}

export default WrapInSpanDisplay