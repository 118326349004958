// adventureService.js

import { db } from "../../firebase";
import { doc, getDoc, updateDoc, deleteDoc, serverTimestamp, collection, query, getDocs, deleteField } from "firebase/firestore";


export async function fetchAdventure(adventureId) {
  const docRef = doc(db, "adventures", adventureId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let adventureData = docSnap.data();

    // Fetch 'readersData' subcollection
    const readersDataCollectionRef = collection(docRef, 'readersData');
    const readersDataQuerySnap = await getDocs(readersDataCollectionRef);
    adventureData.readersData = readersDataQuerySnap.docs.reduce((acc, doc) => {
      if (doc.id === 'readerDisplayName') {
        // Skip this document
        return acc;
      }

      const dataArray = doc.data();
      // Here, we set the key as the doc ID and the value as the dataArray itself
      acc[doc.id] = dataArray;

      return acc;
    }, {});

    return adventureData;
  } else {
    console.log("No such document!");
    return null;
  }
}


export async function updateAdventure(adventureId, updatedFields) {
  const adventureRef = doc(db, "adventures", adventureId);
  try {
    await updateDoc(adventureRef, {
      ...updatedFields,
      dateEdited: serverTimestamp()
    });
    console.log("Adventure updated successfully");
  } catch (error) {
    console.error("Error updating adventure:", error);
  }
}


export async function deleteAdventure(adventureId) {
  const adventureRef = doc(db, "adventures", adventureId);

  try {
    await deleteDoc(adventureRef);
    console.log("Adventure deleted successfully");
  } catch (error) {
    console.error("Error deleting adventure:", error);
  }
}


export async function updateAdventureReadersData(adventureId, storyId, newTitle, userId) {

  // Fetch the specific reader's data
  const readerDataDoc = doc(db, 'adventures', adventureId, 'readersData', userId);
  const readerDocSnapshot = await getDoc(readerDataDoc);

  if (readerDocSnapshot.exists()) {
    let readerData = readerDocSnapshot.data();
    // If this user has read this story, update the title
    if (readerData[storyId]) {
      readerData[storyId][0] = newTitle;

      // Update the specific user's readersData document
      await updateDoc(readerDataDoc, readerData);
    }
  }
}

export async function deleteAdventureReadersData(adventureId, storyId, userId) {

  try {
    // Fetch the specific reader's data
    const readerDataDoc = doc(db, 'adventures', adventureId, 'readersData', userId);

    // Use update to delete the storyId field
    await updateDoc(readerDataDoc, { [storyId]: deleteField() });
  }
  catch (err) {
    console.error(err.message)
  }
}
