import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Button } from 'semantic-ui-react';
import { doc, serverTimestamp, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { HELP_EMAIL } from '../../constants/adminConsts';
import checkDeletedUser from '../api/checkDeletedUser';

export default function LoginEmail(props) {
  const { auth, db, showErrorDisplay, setShowSignUp, passwordReset } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (evt) => {
    evt.preventDefault();
    try {
      const emailExistsInDeleted = await checkDeletedUser(email);
      if (emailExistsInDeleted) {
        showErrorDisplay(`Oops! Unfortunately this email address can no longer be used. Email ${HELP_EMAIL} for assistance.`);
        return;
      }

      const cred = await signInWithEmailAndPassword(auth, email, password);
      const userId = cred.user.uid;
      const docRef = doc(db, 'users', userId);
      const { emailVerified } = cred.user;

      if (!emailVerified) { navigate('/loginemailconfirm') }

      const docSnapshot = await getDoc(docRef);
      const currentFirebaseUser = docSnapshot.data();

      const dataToUpdate = {
        numberOfLogins: currentFirebaseUser.numberOfLogins + 1,
        dateLastLoggedIn: serverTimestamp(),
      };

      await updateDoc(docRef, dataToUpdate);
    } catch (err) {
      showErrorDisplay(err.message);
    }
  };

  return (
    <>
      <style>
        {`
        @media (max-width: 600px) {
          .login-form-container {
            width: 90%;
          }
        }
      `}
      </style>
      <div className="center">
        <div className="login-form-container">
          <form onSubmit={handleSignIn} className="login-form" autoComplete="off">
            <div className="form-group">
              <label>Email:</label>
              <input
                className="form-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                className="form-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br />
            <div className="center">
              <Button size="massive" color="blue" type="submit">Login</Button>
            </div>
          </form>
          <div className="center">
            <br />
            <br />
            <br />
            <Button size="tiny" color="black" onClick={() => setShowSignUp(false)}>Create Account</Button>
          </div>
          <br />
          <h5 onClick={passwordReset} className="password-reset center">Password Reset</h5>
        </div>
      </div>
    </>
  );
}
