// StoryEditMode.jsx

import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Button } from 'semantic-ui-react'
import { serverTimestamp } from "firebase/firestore";

import useGetStory from '../hooks/useGetStory'
import updateStory from '../api/updateStory';
import deleteItem from '../api/deleteStory'

import Lottie from "lottie-react";
import trashBin from '../../images/111436-move-to-trash.json';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css'


function StoryEditMode({ currentUser, db }) {
  let navigate = useNavigate()
  const [storyData, setStoryData] = useState(null)
  const [originalText, setOriginalText] = useState(null)
  const [edittedText, setEdittedText] = useState(null)
  const [deleteSuccessful, setDeleteSuccessful] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  const [editedTitle, setEditedTitle] = useState(null);
  const [originalTitle, setOriginalTitle] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  let { storyId } = useParams();

  const { loading, error, data } = useGetStory(currentUser?.uid, db, storyId)

  const { title, prompt, text, imageDownloadURL, imageMediumDownloadURL } = data || {}

  // TEST
  const textAreaRef = useRef(); // Create a ref for the EditTextarea

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };

  const successfulDeleteToastie = (message) => {
    const toastOptions = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => navigate('/stories/')
    }
    toast.warning(message, toastOptions);
  }


  useEffect(() => {
    setStoryData(() => data);
    if (data) {
      setOriginalText(data.text);
      setEdittedText(data.text);
      setOriginalTitle(data.title);
      setEditedTitle(data.title);
    }
    adjustTextAreaHeight();
  }, [data]);


  useEffect(() => {
    if (deleteSuccessful) {
      successfulDeleteToastie(originalTitle + " Deleted")
    }
  }, [deleteSuccessful]);


  const deleteStory = () => {
    setDeleteInProgress(() => true)
    const item = { ...data }


    deleteItem({ userId: currentUser?.uid, item, db })
      .then(() => {
        setDeleteSuccessful(() => true)
      })
      .catch((error) => {
        console.error('Error deleting story: ', error);
      });
  };


  const editPicture = () => { console.log("EDIT / REPLACE PICTURE") }
  const editStory = () => { console.log("EDIT  MODE") }
  const returnToStory = () => navigate('/storydisplaymain/' + storyId)

  const calculateRows = (text) => {
    if (!text) return 4; // At least 4 rows by default

    const numOfLines = (text.match(/\n/g) || []).length + 1;
    const numOfTextRows = Math.ceil(text.length / 40); // Adjust the value based on the desired text length per row
    return Math.max(numOfLines, numOfTextRows, 4); // At least 4 rows by default
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setEdittedText(e.target.value);
    }
  };

  const handleSavedChanges = () => {
    setIsEditing(() => false);

    const updatedFields = {
      text: edittedText,
      title: editedTitle,
      dateModified: serverTimestamp()
    };

    updateStory({ userId: currentUser?.uid, db, storyId, updatedFields })
      .then(() => {
        returnToStory()
      })
      .catch((error) => {
        console.error('Error updating story: ', error);
      });
  };

  const handleChange = (e) => {
    setEdittedText(e.target.value);
    setIsEditing(true);
    adjustTextAreaHeight();
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
    setIsEditing(true);
  };


  const discardChanges = () => {
    setIsEditing(() => false);
    setEdittedText(() => originalText)


    // toastie
    // route back to storydisplaymain

  };


  const aiButtons = <>
    <div className="center">
      <Button color="yellow" size="small" onClick={editStory}>👾 - Decrease Reading Level - 👾</Button >
      <Button color="yellow" size="small" onClick={editStory}>👾 + Increase Reading Level + 👾</Button >
    </div>
    <div className="center">
      <Button color="green" size="small" onClick={editStory}>👾 Embellish the story 👾</Button >
    </div>
    <div className="center">
      <Button color="green" size="small" onClick={editStory}> 👾 Create comprehension questions 👾</Button >
    </div>
  </>

  const generalButtons = <>
    <div className="center">
      <Button color="black" size="huge" onClick={returnToStory}>RETURN TO STORY MODE</Button >
    </div>
    <br />
    <div className="center">
      <Button color="white" size="tiny" onClick={deleteStory}> ❌ Delete Story ❌</Button >
    </div>
  </>


  if (!currentUser) {
    navigate('/');
    return null;
  }


  return (<>
    <ToastContainer />
    <Container>

      {deleteInProgress ? <Lottie animationData={trashBin} loop={true} style={{ height: 600, position: "relative" }} /> :

        (
          <>

            <h2 className="center" style={{ fontSize: "5em", color: "blue" }}>EDIT MODE</h2>

            <div className="center">
              <h2 style={{ width: '100%', minWidth: '100%', display: 'inline-block' }}>
                <EditText
                  name="title"
                  defaultValue={title}
                  style={{
                    color: "blue",
                    fontSize: '1em',
                    lineHeight: '1.1',
                    width: '100%', // Add this to limit the width
                    minWidth: '100%', // Add this to ensure the minimum width is not too narrow
                    whiteSpace: 'pre-wrap', // Add this to enable text wrapping
                    overflowWrap: 'break-word', // Add this to break words if needed
                  }}
                  value={editedTitle || ''}
                  onChange={handleTitleChange}
                />
              </h2>
            </div>
            <div className="center" onClick={editPicture}>
              <img
                src={imageMediumDownloadURL}
                height="300px"
                width="300px"
                alt="" />
            </div>


            <EditTextarea
              name="textbox2"
              defaultValue={text}
              style={{
                padding: '10px',
                color: "blue",
                fontSize: '2em',
                lineHeight: '1.5',
                overflowY: 'auto',
                // maxHeight: '600px'
              }}
              rows={text ? calculateRows(text) : 4}
              value={edittedText || ''}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />

            {isEditing ?
              <>
                <br />
                <div className="center">
                  <Button color="green" size="huge" onClick={handleSavedChanges}>
                    Save Updates
                  </Button>
                </div>
                <br />
                <div className="center">
                  <Button color="white" size="tiny" onClick={discardChanges}> ❌ Discard Changes</Button >
                </div>
              </>
              : generalButtons}

            <br />
            <br />
          </>

        )}
    </Container> </>
  )
}

export default StoryEditMode
