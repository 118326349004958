import React, { useEffect, useState } from 'react';
import { auth, onAuthStateChanged, sendEmailVerification } from '../../firebase'
import { Container, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Logout from '../general/Logout';

function LoginConfirmEmail(props) {
  const { currentUser, setShowNavbar } = props; //auth
  const [showErrorDisplay, setShowErrorDisplay] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const { emailVerified } = currentUser || {};

  let navigate = useNavigate();

  const checkEmailVerification = async (user) => {
    await user.reload();
    if (user.emailVerified) {
      navigate('/home');
    }
  };

  useEffect(() => {
    setShowNavbar(false);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkEmailVerification(user);
      }
    });

    return () => {
      unsubscribe();
      setShowNavbar(true);
    };
  }, [auth, navigate, setShowNavbar]);

  useEffect(() => {
    // Disable back navigation by pushing the same state again
    window.history.pushState(null, document.title, window.location.href);

    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/loginemailconfirm');
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);




  if (!currentUser) {
    setShowNavbar(true);
    navigate('/');
  }

  const resendEmailVerification = async () => {
    setEmailSent(true)
    try {
      if (currentUser && !currentUser.emailVerified) {
        await sendEmailVerification(currentUser);
        setEmailSent(true);
        console.log('Email verification sent');
      } else {
        console.log('User is either not logged in or already verified');
      }
    } catch (err) {
      setShowErrorDisplay(err.message);
      console.error('Error:', err);
    }
  };

  if (showErrorDisplay) {
    return (
      <>
        <h3 className="center">Hmm... Something is not working as expected. Try closing your browser and starting again.</h3>
        <Logout {...props} color="black" />
      </>
    );
  }

  return (
    <>
      <style>
        {`
          .login-confirm-container {
            width: 90%;
            margin: 0 auto;
          }

          @media (max-width: 767px) {
            h1 {
              font-size: 1.5em;
            }

            h2 {
              font-size: 1.25em;
            }
          }
        `}
      </style>
      <Container>
        <div className="login-confirm-container">

          <br />
          <br />

          {!emailSent && (
            <h1 className="center">
              Confirmation link sent to:{'  '}
              <span style={{ color: 'blue' }}> {'  '}{currentUser?.email}</span>{' '}
            </h1>
          )}

          {emailSent && (
            <h2 className="center" style={{ color: 'green' }}>
              Email verification resent.
            </h2>
          )}


          <div className="center">
            <Logout {...props} color="black" />
          </div>


          <br />

          <div className="center">
            {!emailSent && (
              <Button color="blue" size="tiny" onClick={resendEmailVerification}>
                Resend
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default LoginConfirmEmail;


