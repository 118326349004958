// PaymentFailuer.jsx

import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import Lottie from "lottie-react";
import errorIcon from '../../images/error-icon.json';

function PaymentFailure({ currentUser, setShowNavbar }) {
  const navigate = useNavigate();
  const location = useLocation();


  const failureExplanation = location.state?.failureExplanation;

  const handleTryAgain = () => {
    navigate('/payment');
  };

  const handleContactSupport = () => {
    navigate('/contact');
  };


  useEffect(() => {
    // Redirect to home if user is not authenticated
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Disable back button navigation
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/home');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [navigate]);


  return (
    <>
      <Container>
        <br />
        <h3 className="center" style={{ fontSize: "2em" }}>Ooop. Unfortunately your payment was not accepted.</h3>
        <div className="center">
          <Lottie animationData={errorIcon} loop={false} style={{ height: 200, position: "relative" }} />
        </div>
        <h3 className="center" style={{ fontSize: "1.5em" }}>{failureExplanation}</h3>
        <br />
        <div className="center">
          <Button size="huge" onClick={handleTryAgain}>Try Again</Button>
          <Button size="huge" onClick={handleContactSupport}>Contact Support</Button>
        </div>
      </Container>
    </>
  );
}

export default PaymentFailure;
