import { useState, useEffect } from 'react'
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";

const useFirebaseQuery = (userId, db, collectionName, sortTerm = "dateCreated") => {

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (userId) {

      let isMounted = true;

      setLoading(true);

      const colRef = collection(db, "users", userId, collectionName)

      // const q = query(citiesRef, orderBy("name", "desc"), limit(3));
      // const q = query(colRef, orderBy("name"), limit(3));


      getDocs(query(colRef, orderBy(sortTerm)))

        .then((querySnapshot) => {
          if (isMounted) {

            const responseArray = []

            querySnapshot.forEach((doc) => {
              //console.log(doc.id, " => ", doc.data());
              responseArray.push(doc.data())
            });

            setData(responseArray)
            setError(null)
          }
        })
        .catch(error => {
          if (isMounted) {
            setError(error)
            setData(null)
          }
        })
        .finally(() => isMounted && setLoading(false))

      return () => (isMounted = false)
    }

  }, [userId, db]);

  return { loading, error, data }

}

export default useFirebaseQuery