import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useGetStory from '../hooks/useGetStory'
import { Container, Button } from 'semantic-ui-react'
import deleteStory from '../api/deleteStory';
import updateStory from '../api/updateStory';

const wordStyle = { color: "black", fontSize: "1.5em", cursor: "pointer" }

const wordBankWord = { color: "green", cursor: "pointer", fontSize: "1.5em", fontWeight: 900 }

// return text array and length
function splitWords(text) {
  if (!text) return [null, null]
  return [text.split(" "), text.split(" ").length]
}

const StoryRestore = (props) => {
  let navigate = useNavigate()
  const [storyData, setStoryData] = useState(null)
  const [storyWordDictionary, setStoryWordDictionary] = useState({})
  const [wordBankDictionary, setWordBankDictionary] = useState({})


  let { storyId } = useParams();
  const { currentUser, db } = props
  const { loading, error, data } = useGetStory(currentUser?.uid, db, storyId)

  const { title, prompt, text, imageDownloadURL, wordBank, ageLevel } = data || {}


  function checkDictionary(word) {
    if (wordBankDictionary.hasOwnProperty(word.toLowerCase())) {
      console.log("YES", word)
      return true
    }
    return false
  }

  function WrapInSpanDisplay(props) {
    const { storyText } = props;
    const storyTextArray = storyText?.split(" ")

    return (
      <div>
        {storyTextArray.map((text, index) => (
          <span
            style={checkDictionary(text) ? wordBankWord : wordStyle}
            key={index}>{text}{" "}
          </span>
        ))}
      </div>
    );
  }

  const deleteStoryAndReturn = () => {
    data.id = data.storyId
    deleteStory({ userId: currentUser?.uid, item: data, db })

    navigate('/storiesdeleted/')
  }

  const unarchiveItem = () => {
    data.id = data.storyId
    updateStory({ userId: currentUser?.uid, item: data, db, updatedFields: { archived: false } })
    navigate('/stories/')
  }


  // useEffect(() => {
  //   let textDictionary = {}
  //   const storyTextArray = text?.split(" ")
  //   storyTextArray && storyTextArray.forEach(word => {
  //     if (/^[a-zA-Z ]+$/.test(word)) {
  //       textDictionary[word.toLowerCase()] = true
  //     }
  //   })
  //   setStoryWordDictionary(textDictionary)

  //   let wbd = {}
  //   const wordBankArray = wordBank?.split(",")

  //   wordBankArray?.forEach(word => {
  //     if (/^[a-zA-Z ]+$/.test(word.trim())) {
  //       wbd[word.trim().toLowerCase()] = true
  //     }
  //   })
  //   setWordBankDictionary(wbd)
  // }, [text, wordBank])

  useEffect(() => { setStoryData(() => data) }, [data])

  return (<Container>
    <div className="center">
      <h1 style={{ color: "red" }}>ARCHIVED: </h1>
    </div>
    <div className="center">
      <h2>{title}</h2>
    </div>

    <div className="center">
      <img
        src={imageDownloadURL}
        height="500px"
        width="500px"
        alt="" />
    </div>
    <br />
    {text && <WrapInSpanDisplay storyText={text} />}


    <br />
    <div className="center">
      <Button color="green" size="huge" onClick={unarchiveItem}>Restore</Button >
    </div>

    <br />
    <div className="center">
      <Button
        color="red"
        size="tiny"
        onClick={deleteStoryAndReturn}>
        Permanently Delete
      </Button >
    </div>

  </Container>

  )
}

export default StoryRestore

