import React from 'react'
import { Container } from 'semantic-ui-react'

export default function TheBots() {
  return (<Container>
    <br />
    <h1 style={{ fontSize: "4em" }}>The Bot Family</h1>
    <h2>Coming soon...</h2>
  </Container>
  )
}
