// LanguageSelection.jsx

import React, { useState, useEffect } from 'react';
import { Container, Grid, Card } from 'semantic-ui-react';
import { WORLD_COUNTRIES_ALL } from '../../constants/world-countries-all';
import updateUser from '../../components/api/updateUser';
import { useNavigate } from 'react-router-dom';
import { languageSelectionMap } from '../../constants/siteTextMap'



function LanguageSelection({ currentUser, firestoreUserData, db }) {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedLanguage(firestoreUserData?.currentLanguage || 'English');
  }, [firestoreUserData]);

  const updateUserDefaultCountry = (countryIndex) => {
    const selectedCountry = WORLD_COUNTRIES_ALL[0][countryIndex];
    updateUser({
      userId: currentUser?.uid,
      db,
      updatedFields: {
        currentLanguage: selectedCountry.primaryLanguageSpoken,
        currentFlag: selectedCountry.emoji,
      },
    });
    setSelectedLanguage(selectedCountry.primaryLanguageSpoken);
  };

  if (!currentUser) {
    navigate('/');
    return null;
  }

  const isSelectedLanguage = (language) => {
    return language === selectedLanguage;
  };



  return (
    <Container>
      <h2 className="center">
        {languageSelectionMap[selectedLanguage]
          ? languageSelectionMap[selectedLanguage].languageSelection
          : languageSelectionMap['English'].languageSelection}
      </h2>
      <Grid columns={5} stackable>
        {WORLD_COUNTRIES_ALL[0].map((country, index) => (
          <Grid.Column key={index}>
            <div
              className="language-card"
              onClick={() => updateUserDefaultCountry(index)}
            >
              <Card className={isSelectedLanguage(country.primaryLanguageSpoken)
                ? 'selected-language'
                : ''}>
                <Card.Content className="center-text">
                  <Card.Header>
                    {languageSelectionMap[selectedLanguage]
                      ? languageSelectionMap[selectedLanguage][country.primaryLanguageSpoken.toLowerCase()]
                      : languageSelectionMap['English'][country.primaryLanguageSpoken.toLowerCase()]}
                  </Card.Header>
                  <Card.Description
                    style={{
                      fontSize: '6em',
                      lineHeight: '1em',
                      marginTop: '-10px',
                    }}
                  >
                    {country.emoji}
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          </Grid.Column>
        ))}
      </Grid>
      <style>
        {`
          .center-text {
            text-align: center;
          }

          .language-card {
            cursor: pointer;
            display: flex; // Add display: flex
            justify-content: center; // Add justify-content: center
          }

          .selected-language {
            border: 4px solid #007bff !important;
            border-radius: 4px;
            box-sizing: border-box;
          }

          @media (max-width: 768px) {
            .language-card {
              display: flex;
              justify-content: center;
            }
          }
        `}
      </style>

    </Container>
  );
}

export default LanguageSelection;