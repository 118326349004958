import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const RevealImage = ({ src, alt }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      scale: 1,
      opacity: 1,
      transition: { duration: 1.5, ease: 'easeInOut' },
    });
  }, [controls]);

  return (
    <motion.img
      src={src}
      alt={alt}
      initial={{ scale: 0.1, opacity: 0 }}
      animate={controls}
      style={{ display: 'block', maxWidth: '70%' }}
    />
  );
};

export default RevealImage;
