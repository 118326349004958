import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Button, Dropdown, Form } from "semantic-ui-react";
import updateUser from '../api/updateUser';
import { serverTimestamp } from "firebase/firestore";


function Membership({ currentUser, firestoreUserData }) {
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');
  const [promoSuccess, setPromoSuccess] = useState(false);
  const [membershipPurchase, setMembershipPurchase] = useState('');

  const [price, setPrice] = useState(0);

  const handlePromoSubmit = (e) => {
    e.preventDefault();
    if (promoCode === "ra2023month") {
      setPromoError('');
      setPromoSuccess(true);
      setPrice(0);

      setMembershipPurchase('Monthly');
    } else if (promoCode === "ra2023deluxe") {
      setPromoError('');
      setPromoSuccess(true);
      setPrice(0);

      setMembershipPurchase('Annual');
    } else {
      setPromoError('Invalid promo code');
    }
  };

  const { credit, userDisplayName, avatarSeed, userId, currentLanguage, currentFlag, dateLastLoggedIn, dateCreated, membership: membershipStatusSaved } = firestoreUserData || {}



  const handleUpdateMembership = async () => {
    if (membershipPurchase) {
      const updatedFields = {
        membership: membershipPurchase,
        membershipDatePurchased: serverTimestamp()
      };

      try {
        await updateUser({ userId: currentUser.uid, updatedFields });
        console.log("Membership updated successfully");
      } catch (error) {
        console.error("Error updating membership:", error);
      } finally {
        navigate("/profile");
      }


    }
  };



  useEffect(() => {
    if (membershipPurchase === 'Classroom Monthly') {
      setPrice(25);
    }
    else if (membershipPurchase === 'Classroom Annual') {
      setPrice(195);
    }
  }, [membershipPurchase]);

  const membershipOptions = [
    { key: 'monthly', text: 'Classroom Monthly $25', value: 'Classroom Monthly' },
    { key: 'annual', text: 'Classroom Annual $195', value: 'Classroom Annual' }
  ];



  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

      <br />
      <h3 style={{ fontSize: "3em", color: "blue" }}>Current Membership: {membershipStatusSaved || 'Basic'}</h3>

      <br />
      {!promoSuccess && (
        <>
          <Form onSubmit={handlePromoSubmit}>
            <Form.Field>
              <label>Enter Promo Code</label>
              <input
                placeholder='Promo Code'
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </Form.Field>
            <Button color="black" size="tiny" type='submit'>Submit</Button>
            {promoError && <p style={{ color: 'red' }}>{promoError}</p>}
          </Form>
          <br />
        </>
      )}
      <br />
      {promoSuccess && (
        <>
          <h3>Promo code successfully applied!</h3>
          <h3>Your {membershipPurchase} begining today.</h3>
          <br />
          <br />
          <br />
          <Button
            color="green"
            onClick={() => handleUpdateMembership()}>
            Update membership!
          </Button>
        </>
      )}
    </Container>
  );
}

export default Membership;
