import { db } from '../../firebase';
import { doc, deleteDoc } from "firebase/firestore";

const deleteUserAccountPromise = (currentUserId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userRef = doc(db, "users", currentUserId);
      const apiUrl = process.env.REACT_APP_API_URL;

      // #2 delete from Firestore
      await deleteDoc(userRef);
      // console.log('Deleted from Firestore ID', currentUserId);

      // #3 Delete Auth Record
      const response = await fetch(`${apiUrl}/user-management/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: currentUserId, requestingUserId: currentUserId })
      });

      const responseData = await response.json();

      if (responseData.success) {
        console.log('User auth record deleted successfully');
        resolve();
      } else {
        console.error('Error deleting auth record:', responseData.error);
        reject(new Error(responseData.error));
      }

    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
};

export default deleteUserAccountPromise;
