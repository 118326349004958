import React from 'react'
import { Button, Container } from 'semantic-ui-react'
import RevealText from './RevealText';
import RevealImage from './RevealImage';
import RevealTitle from './RevealTitle';
import Lottie from "lottie-react";
import AiOrbLoader from '../../images/136808-ai-orb.json';

// Assuming you have imported the necessary components and styles for WrapInSpanDisplay.
import WrapInSpanDisplay from '../general/WrapInSpanDisplay'

export default function StoryDisplay35(props) {
  const { keeper, clearEntry, newPicture, size, text, title, imageURL, responseAI, wordBank = [] } = props

  return (<>
    <br />
    <br />
    {title && <h2>{title}</h2>}
    {imageURL ?
      <div className="center">
        <RevealImage
          src={imageURL}
          onClick={newPicture}
          alt="ai_image"
        />
      </div> :
      <Lottie animationData={AiOrbLoader} loop={true} style={{ height: 200, position: "relative" }} />}

    {text && <WrapInSpanDisplay storyText={text} wordBank={wordBank} />}

    {responseAI === "part3"
      ? <>
        <br />
        <Button size="huge" onClick={keeper} color="green">Keeper!</Button>
        <br />
        <br />
        <div>
          <Button size="medium" onClick={clearEntry} color="yellow">Try Again</Button>

        </div>
        <br />
      </> : null}

  </>
  )
}
