import React from 'react'
import { Container, Button } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom'

export default function ApplePay({ currentUser }) {

  const navigate = useNavigate();

  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (
    <Container>
      <h2 className="center" style={{ fontSize: "5em" }}>Apple Pay</h2>
      <h2 className="center" style={{ fontSize: "2em" }}>Coming Soon!</h2>
      <div className="center">
        <Button
          onClick={() => navigate("/stripe")}
          style={{ cursor: "arrow", color: "blue" }}
        >You can use Stripe today.</Button>
        <br />
        <br />
        <br />
      </div>
    </Container >
  )
}
