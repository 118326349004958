// Promo.jsx

import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import websiteMaintenance from '../../images/webpage-under-maintenance.json';
import Lottie from 'lottie-react';
import '../../styles/Promo.css';

const allCurrentPromoCodes = ['intro2023'];


function Promo(props) {

  const [SHOW_MAINTENANCE_PAGE, setSHOW_MAINTENANCE_PAGE] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(null);
  const { setShowNavbar, currentUser, siteOnline } = props

  const MAINTENANCE_PAGE_MESSAGE = null
  useEffect(() => { setShowNavbar(false); }, []);  // Correct

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    setQueryParams(params);
  }, [location.search]);

  if (!siteOnline && SHOW_MAINTENANCE_PAGE) {
    return <Container>
      <br />
      <br />
      <div >
        <Lottie animationData={websiteMaintenance} loop={true} style={{ height: 300, position: "relative" }} />
        <h2 className="center">Picture Books is currently down for maintenance.</h2>
        {MAINTENANCE_PAGE_MESSAGE && <h3 className="center" >{MAINTENANCE_PAGE_MESSAGE}</h3>}
      </div>
    </Container>
  }

  if (currentUser) return navigate("/home")


  const promocode = queryParams ? queryParams.promocode : null;

  const navigateToLoginSpecialPromo = () => {
    navigate('/loginspecialpromo?promocode=' + promocode);
  };

  // use later?
  const navigateToHolidayPromo = () => {
    navigate('/loginspecialpromo?promocode=' + promocode);
  };

  console.log("promocode==>>>", promocode)


  if (promocode === "intro2023") {
    const Intro2023Promo = React.lazy(() => import('../promos/Intro2023'));
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Intro2023Promo
          navigateToLoginSpecialPromo={navigateToLoginSpecialPromo} />
      </React.Suspense>
    );
  }

  if (promocode === "holiday2023") {
    const Holiday2023 = React.lazy(() => import('../promos/Holiday2023'));
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Holiday2023
          navigateToLoginSpecialPromo={navigateToLoginSpecialPromo} />
      </React.Suspense>
    );
  }


  return <h1>Invalid code</h1>
}

export default Promo;
