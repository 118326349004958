import React from 'react';
import { Button } from 'semantic-ui-react';
import QRCode from 'qrcode.react';

const DisplayLinkQrQuizEdit = ({
  showQRCode,
  generatedStaticLink,
  generateQuestionAI,
  generateNewStaticLink,
  qrCodeSize,
  handleQRCodeClick,
  linkCopied,
  graphicExport,
  graphicQR,
  generateQRCode,
  // showTestMaker,
  // testImage,
  editStory,


  setLoadingStaticLink,
  title,
  text,
  quizArray,

  imageDownloadURL,
  imageMediumDownloadURL,

  currentUser,
  baseUrl,
  storyId,
  updateStory,
  db,
  setGeneratedStaticLink,
  setLinkCopied,

  story2Map,
  currentLanguage

}) => {


  return (<div className="center">

    <div className="center">


      {showQRCode && generatedStaticLink
        ?
        (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          <QRCode value={generatedStaticLink} size={qrCodeSize} onClick={handleQRCodeClick} />

          <h5 style={{ textAlign: 'center', marginTop: '1rem', color: 'black', fontSize: '0.9rem' }}>
            <a href={generatedStaticLink} target="_blank" rel="noopener noreferrer">
              {generatedStaticLink}
            </a>
          </h5>
        </div>)

        :

        (<>
          {linkCopied && generatedStaticLink
            ?
            (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <h4 className="desktop-only"> ✓ Link Copied</h4>
              <h5 style={{ textAlign: 'center', marginTop: '1rem', color: 'black', fontSize: '0.9rem' }}>
                <a href={generatedStaticLink} target="_blank" rel="noopener noreferrer">
                  {generatedStaticLink}
                </a>
              </h5>
            </div>)
            :


            (
              null

              // <div style={{ display: 'grid', justifyContent: 'center', gap: '1rem' }}>

              //   <div style={{ display: 'flex', justifyContent: 'center' }}>
              //     <div style={{ padding: "10px" }}>
              //       <img src={graphicExport} onClick={() => generateNewStaticLink({
              //         setLoadingStaticLink,
              //         title,
              //         text,
              //         quizArray,
              //         imageDownloadURL,
              //         imageMediumDownloadURL,
              //         currentUser,
              //         baseUrl,
              //         storyId,
              //         updateStory,
              //         db,
              //         setGeneratedStaticLink,
              //         setLinkCopied
              //       })}
              //         height="40" width="40" alt="" />
              //     </div>

              //     <div style={{ padding: "10px 10px 0px 10px" }}>
              //       <img src={graphicQR} onClick={generateQRCode}
              //         height="30" width="30" alt="" />
              //     </div>

              //   </div>


              // </div>


            )}
        </>
        )}
    </div>


  </div>
  )
}

export default DisplayLinkQrQuizEdit;
