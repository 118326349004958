// buildVisualPromptGetImageApi.js

import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL

// import from .env or firebase
// alt: "gpt-3.5-turbo-1106"
const textModel = "gpt-4-1106-preview"
const imageModel = "dall-e-3"

const buildVisualPromptGetImage = ({
  userId,

  theme,
  title,
  summary,
  setting,
  charactersDescription,

  illustrationType,
  generatedStoryId
},

  cancelToken, intervalIdRef) => {


  return new Promise((resolve, reject) => {
    if (!cancelToken) {
      reject(new Error("Cancel token is not initialized"));
      return;
    }
    const promptAndImageRequest = {
      userId,

      textModel,
      theme, // later add setupTheme ?
      title,
      summary,
      setting,
      charactersDescription, // where?

      imageModel,
      illustrationType,
      generatedStoryId
    };


    axios.post(baseUrl + "/gpt4-1106-b-redis/build-prompt-get-image/", promptAndImageRequest, { cancelToken })

      .then(response => {
        const jobId = response.data.jobId;
        console.log("Job ID for visual prompt retrieved:", jobId);

        intervalIdRef.current = setInterval(() => {
          axios.get(baseUrl + `/gpt4-1106-b-redis/build-prompt-get-image/status/${jobId}`, { cancelToken })

            .then(response => {

              const { status, returnvalue } = response.data;

              if (status === 'completed') {
                clearInterval(intervalIdRef.current);
                resolve(returnvalue);
              }

              else if (status === 'failed') {
                clearInterval(intervalIdRef.current);
                reject(new Error('Job failed'));
              }

              console.log(`Status for jobId: ${jobId}:   ${status}`);

            }).catch(err => {
              clearInterval(intervalIdRef.current);
              reject(err);
            });
        }, 5000);
      })

      .catch(err => {
        console.error("Error in submitting visual prompt:", err.message);
        reject(err);
      });
  });
};

export default buildVisualPromptGetImage;
