import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const ThemeToggleCheckbox = ({ selectedThemeKey, toggleTheme, themes }) => {
    const theme = themes[selectedThemeKey];

    return (
        <div className="center">
            <Checkbox
                toggle
                label={theme.title || "Regular Mode"} // Dynamically set label based on the current theme
                checked={selectedThemeKey !== 'defaultTheme'} // Adjust based on your logic
                onChange={toggleTheme} // Call the toggle function
                style={{
                    fontSize: '1.5em',
                    color: theme.style?.color // Adjust this if you want to use different style attributes
                }}
            />
        </div>
    );
};

export default ThemeToggleCheckbox;
