// CreateDown.jsx

import React from 'react'
import { Button, Container, Icon, Modal } from 'semantic-ui-react'
import Lottie from "lottie-react";
import ConstructionCone from '../../images/construction-cone.json';


export default function CreateDown() {
  return (
    <Container>
      <br />
      <div className="center">
        <Lottie animationData={ConstructionCone} loop={true} style={{ height: 200, position: "relative" }} />
      </div>

      <div className="center">
        <h1>Creating new stories is currently unavaible. <br />Check back soon.
        </h1>
      </div>

    </Container>
  )
}
