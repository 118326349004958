// generatePDF.js

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// const toBase64 = (url) =>
//   fetch(url)
//     .then(response => response.blob())
//     .then(blob => new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     }));

const generatePDF = (title, text, imageBase64) => {
  const docDefinition = {
    content: [
      { text: title, style: 'header' },
      {
        image: imageBase64,
        width: 500,
        height: 500
      },
      { text: text, margin: [0, 20, 0, 0] }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      }
    }
  };

  pdfMake.createPdf(docDefinition).download('story.pdf');
};

export default generatePDF;
