// updateStory.js

import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';


const updateStory = async ({ 
  userId, 
  storyId, 
  //db, 
  updatedFields 
}) => {
  const storyRef = doc(db, "users", userId, "stories", storyId);

  const updatedFieldsWithTimestamp = {
    ...updatedFields,
    dateModified: serverTimestamp()
  };

  await updateDoc(storyRef, updatedFieldsWithTimestamp);
};

export default updateStory;

