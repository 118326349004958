// illustrationConsts.js

export const ILLUSTRATION_STYLES = [
  "oil",//1
  "cartoon", //2
  "realistic", //3
  "comicBook", //4
  "lineDrawing",//5
  "abstract", //6
  "dreamlike", //7
  "vintage", //8
  "cyberpunk",//9
  "picasso",//10
  "vanGogh",//11
  "subdued", //12
  "manga",//13
  "paperCollage",//14
  "waterColor",  //15
  "emoji"  //16
];
// add pencil drawing / stain glass




export const ILLUSTRATION_BUTTON_IMAGE_URLS = [

  "/image1_oil.jpeg",
  "/image2_cartoon.jpeg",
  "/image3_realistic.jpeg",
  // "/image4_comicBook.png", // comic book
  "/image4_comicBook.jpeg", // comic book

  "/image5_line.jpeg",
  "/image6_abstract.jpeg",

  // "/image7_dreamlike.png",// dreamlike
  "/image7_dreamlike.jpeg",// dreamlike

  "/image8_vintage.jpeg",

  // "/image9_cyberpunk.png", // cyberpunk
  "/image9_cyberpunk.jpeg", // cyberpunk

  "/image10_picasso.jpeg",
  "/image11_vangogh.jpeg",
  "/image12_subdued.jpeg",
  "/image13_manga.jpeg",
  "/image14_collage.jpeg",
  "/image15_watercolor.jpeg",
  "/image16_emoji.jpeg", //emoji
];


export const ILLUSTRATION_AI_PROMPTS = [
  // "a typical children's book illustration", //0
  "an oil painting with thick broad strokes, plenyty of bright colores",
  "a classical cartoon which looks like it came from a Walt Disney animation ",
  "a high definition photorealistic depiction that looks like it came from a digital camera.",
  // "a wonderous and fantasy illustration",
  "a black and white ink draking that could be used a as coloring book drawing",
  "an abstract illustration with vivid colors and shapes that could be from a dream",
  // "an exaggerated and dreamlike illustration with fantastic swirling colors",
  "a traditional vintage illustration, with dull color and a mix of pencil and other medium, with an esthetic that evokes the 1950's or 1960s. ",
  //"a bold, bright, and colorful illustration",
  "a cubist painting in the style of Picasso, colorful geometric shapes",
  "an oil painting with broad strokes invoking movement, like Van Gogh's Starry Night",
  "a subdued and somber illustration with grey and dark colors, evoking sadmess and melancholy, ",
  "a Japanese Manga anime cartoon illustration where character have incredibly big eyes", //13 - manga
  "a paper cut-out collage in the style of the Hungry Caterpillar ", //14 - collage
  "a watercolor illustration ", //15 - water color
  "a brightly colored illustration made of all emojis", //16 emoji
];




// a detailed collage of high definition photographs
// realistic high definition photograph "

// a classic cartoon raccoon looking at the view in the style of bugs bunny