export const WORLD_COUNTRIES_ALL = [
  [
    { "name": "United States", "code": "US", "emoji": "🇺🇸", "unicode": "U+1F1FA U+1F1F8", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg", "primaryLanguageSpoken": "English", "dialect": "American English" },

    { "name": "United Kingdom", "code": "GB", "emoji": "🇬🇧", "unicode": "U+1F1EC U+1F1E7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg", "primaryLanguageSpoken": "English", "dialect": "British English" },

    { "name": "Australia", "code": "AU", "emoji": "🇦🇺", "unicode": "U+1F1E6 U+1F1FA", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg", "primaryLanguageSpoken": "English", "dialect": "Australian English" },

    { "name": "Spain", "code": "ES", "emoji": "🇪🇸", "unicode": "U+1F1EA U+1F1F8", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg", "primaryLanguageSpoken": "Spanish" },

    { "name": "France", "code": "FR", "emoji": "🇫🇷", "unicode": "U+1F1EB U+1F1F7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg", "primaryLanguageSpoken": "French" },

    { "name": "Germany", "code": "DE", "emoji": "🇩🇪", "unicode": "U+1F1E9 U+1F1EA", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg", "primaryLanguageSpoken": "German" },

    { "name": "Italy", "code": "IT", "emoji": "🇮🇹", "unicode": "U+1F1EE U+1F1F9", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg", "primaryLanguageSpoken": "Italian" },

    { "name": "Portugal", "code": "PT", "emoji": "🇵🇹", "unicode": "U+1F1F5 U+1F1F9", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg", "primaryLanguageSpoken": "Portuguese" },

    { "name": "Philippines", "code": "PH", "emoji": "🇵🇭", "unicode": "U+1F1F5 U+1F1ED", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg", "primaryLanguageSpoken": "Tagalog" },

    { "name": "Netherlands", "code": "NL", "emoji": "🇳🇱", "unicode": "U+1F1F3 U+1F1F1", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg", "primaryLanguageSpoken": "Dutch" },

    { "name": "Poland", "code": "PL", "emoji": "🇵🇱", "unicode": "U+1F1F5 U+1F1F1", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg", "primaryLanguageSpoken": "Polish" },

    { "name": "Norway", "code": "NO", "emoji": "🇳🇴", "unicode": "U+1F1F3 U+1F1F4", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg", "primaryLanguageSpoken": "Norwegian" },

    { "name": "Sweden", "code": "SE", "emoji": "🇸🇪", "unicode": "U+1F1F8 U+1F1EA", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg", "primaryLanguageSpoken": "Swedish" },

    { "name": "Japan", "code": "JP", "emoji": "🇯🇵", "unicode": "U+1F1EF U+1F1F5", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg", "primaryLanguageSpoken": "Japanese" },

    { "name": "South Korea", "code": "KR", "emoji": "🇰🇷", "unicode": "U+1F1F0 U+1F1F7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg", "primaryLanguageSpoken": "Korean" },

    { "name": "Russia", "code": "RU", "emoji": "🇷🇺", "unicode": "U+1F1F7 U+1F1FA", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg", "primaryLanguageSpoken": "Russian" },

    { "name": "China", "code": "CN", "emoji": "🇨🇳", "unicode": "U+1F1E8 U+1F1F3", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg", "primaryLanguageSpoken": "Mandarin" },

    { "name": "Iran", "code": "IR", "emoji": "🇮🇷", "unicode": "U+1F1EE U+1F1F7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg", "primaryLanguageSpoken": "Persian" },

    { "name": "Iceland", "code": "IS", "emoji": "🇮🇸", "unicode": "U+1F1EE U+1F1F8", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg", "primaryLanguageSpoken": "Icelandic" },

    { "name": "India", "code": "IN", "emoji": "🇮🇳", "unicode": "U+1F1EE U+1F1F3", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg", "primaryLanguageSpoken": "Hindi" },

    { "name": "Thailand", "code": "TH", "emoji": "🇹🇭", "unicode": "U+1F1F9 U+1F1ED", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg", "primaryLanguageSpoken": "Thai" },

    { "name": "Turkey", "code": "TR", "emoji": "🇹🇷", "unicode": "U+1F1F9 U+1F1F7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg", "primaryLanguageSpoken": "Turkish" },

    { "name": "Greece", "code": "GR", "emoji": "🇬🇷", "unicode": "U+1F1EC U+1F1F7", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg", "primaryLanguageSpoken": "Greek" },

    { "name": "Israel", "code": "IL", "emoji": "🇮🇱", "unicode": "U+1F1EE U+1F1F1", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg", "primaryLanguageSpoken": "Hebrew" },

    { "name": "Indonesia", "code": "ID", "emoji": "🇮🇩", "unicode": "U+1F1EE U+1F1E9", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg", "primaryLanguageSpoken": "Indonesian" },

    { "name": "United Arab Emirates", "code": "AE", "emoji": "🇦🇪", "unicode": "U+1F1E6 U+1F1EA", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg", "primaryLanguageSpoken": "Arabic" },

    { "name": "Afghanistan", "code": "AF", "emoji": "🇦🇫", "unicode": "U+1F1E6 U+1F1EB", "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg", "primaryLanguageSpoken": "Dari" }
  ]
]






