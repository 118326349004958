
// Stories.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'semantic-ui-react';
import DisplayStories from '../general/DisplayStories';
import { collection, onSnapshot } from "firebase/firestore";
import { bookshelfMap } from '../../constants/siteTextMap';
import speakingIcon from '../../images/ui/talking.png';
import listIcon from '../../images/ui/list.png';
import tilesIcon from '../../images/ui/tiles.png';
import heartLineIcon from '../../images/ui/heart_line.png';
import heartRed from '../../images/ui/heart_red.png';
import calendarIcon from '../../images/ui/calendar.png';
import sortAtoZ from '../../images/ui/alpha-a-z.png';
import sortZtoA from '../../images/ui/alpha-z-a.png';
import updateUser from '../api/updateUser';


function Stories({ currentUser, db, firestoreUserData }) {
  let navigate = useNavigate();
  const [isListView, setIsListView] = useState(firestoreUserData?.settings?.isListView || false);
  const [savedStories, setSavedStories] = useState([]);
  const [sortMode, setSortMode] = useState(firestoreUserData?.settings?.sortMode || 0);
  const [showFavoritedOnly, setShowFavoritedOnly] = useState(firestoreUserData?.settings?.showFavoritedOnly || false);

  const { currentLanguage } = firestoreUserData || {}

  const showStory = (storyId) => {
    navigate('/storydisplaymain/' + storyId);
  };

  const nextSortMode = () => {
    setSortMode((prevMode) => (prevMode + 1) % 4);
  };

  const sortBy = () => {
    const sortedStories = [...savedStories].sort((a, b) => {
      switch (sortMode) {
        case 0: // A-Z
          return (a.title || '').localeCompare(b.title || '');
        case 1: // Z-A
          return (b.title || '').localeCompare(a.title || '');
        case 2: // Language
          return (a.languageEmoji || '').localeCompare(b.languageEmoji || '');
        case 3: // Date created
          return a.dateCreated.seconds - b.dateCreated.seconds;
        default:
          return 0;
      }
    });
    setSavedStories(sortedStories);
    nextSortMode();
  };


  const toggleDisplayFavorited = () => {
    setShowFavoritedOnly(prevState => !prevState);
  };

  const storiesToDisplay = showFavoritedOnly ? savedStories.filter(story => story.favorited) : savedStories;


  const toggleView = () => setIsListView(!isListView);


  function handleUpdateUserSettings() {
    const settingsUpdate = {
      isListView,
      savedStories: savedStories.map(story => ({
        id: story.id, // Store only necessary story details, like IDs, to refer to them later.
        favorited: story.favorited,
      })),
      sortMode,
      showFavoritedOnly,
    };

    // Prepare the updated settings object.
    const updatedSettings = firestoreUserData.settings
      ? { ...firestoreUserData.settings, ...settingsUpdate }
      : settingsUpdate;

    // Update user with new settings.
    updateUser({
      userId: currentUser.uid,
      updatedFields: {
        settings: updatedSettings,
      },
    });
  }

  // Subscribe to Firestore to listen for stories updates
  useEffect(() => {
    if (!currentUser) { navigate('/'); return; }

    const unsubscribe = onSnapshot(collection(db, "users", currentUser.uid, "stories"), (snapshot) => {
      const stories = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSavedStories(stories);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [currentUser, db, navigate]);

  // Update user settings in Firestore when they change
  useEffect(() => {
    if (currentUser) {
      const settings = { isListView, sortMode, showFavoritedOnly };
      updateUser({ userId: currentUser.uid, updatedFields: { settings } });
    }
  }, [isListView, sortMode, showFavoritedOnly, currentUser]);



  if (!currentUser) {
    navigate('/');
    return null;
  }



  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (


    <>
      <Container>
        <h2 className="center" style={{ fontSize: "4em" }}>{bookshelfMap[currentLanguage]?.bookshelf || bookshelfMap['English'].bookshelf}</h2>


        <div className="center">

          <Button
            onClick={() => setIsListView(!isListView)}
            size="lare"
            content={isListView
              ?
              <img
                src={tilesIcon}
                height="30px"
                alt="talk"
                style={{ padding: '0px' }} />
              :
              <img
                src={listIcon}
                height="30px"
                alt="talk"
                style={{ padding: '0px' }} />
            }
          />

          <Button
            onClick={() => sortBy()}
            size="large"
            content={
              sortMode === 0
                ? <img
                  src={sortZtoA}
                  height="25px"
                  alt="talk"
                />

                : sortMode === 1
                  ? <img
                    src={sortAtoZ}
                    height="25px"
                    alt="talk"
                  />

                  : sortMode === 2
                    ?
                    <img
                      src={speakingIcon}
                      height="25px"
                      alt="talk"
                    // style={{ marginRight: '10px' }} 
                    />


                    : <img
                      src={calendarIcon}
                      height="25px"
                      alt="talk"
                    // style={{ marginRight: '10px' }} 
                    />
            }
          />


          {/* <Button
            onClick={() => sortBy()}
            content={
              sortMode === 0
                ? <Icon name='sort alphabet down' />

                : sortMode === 1
                  ? <Icon name='sort alphabet up' />

                  : sortMode === 2
                    ? 'Sort by Language'
                    : <Icon name='sort' />
            }
          /> */}

          <Button
            onClick={() => toggleDisplayFavorited()}
            content={showFavoritedOnly
              ? <img
                src={heartRed}
                height="30px"
                alt="favorite"
              />
              : <img
                src={heartLineIcon}
                height="30px"
                alt="favorite"

              />
            }
            style={{ display: savedStories !== null && savedStories.some(story => story.favorited) ? "inline-block" : "none" }}
          />

        </div>


        <br />
        <DisplayStories
          db={db}
          userId={currentUser?.uid}
          storyHandler={showStory}
          isListView={isListView}
          sortBy={sortBy}
          setSavedStories={setSavedStories}
          savedStories={storiesToDisplay}
        />
        <br />
      </Container >
    </>


  );
}

export default Stories;