import React, { useState } from 'react';
import { Container, Form, Button, TextArea, Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import contactGraphic from '../../images/contactGraphic.json';
import messageSent from '../../images/message-sent.json';


import '../../styles/ContactForm.css'


export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    comment: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);  // New state to track submission status

  const navigate = useNavigate();

  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleGoHome = () => {
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://formspree.io/f/xjvqlany', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      console.log('Form successfully submitted');
      setFormData({  // Clear form data
        name: '',
        email: '',
        role: '',
        comment: ''
      });
      setIsSubmitted(true);  // Set submission status to true
    } else {
      console.error('Form submission failed:', response.statusText);
    }
  };

  const roleOptions = [
    { key: 'teacher', value: 'Teacher', text: 'Teacher' },
    { key: 'parent', value: 'Parent/Family', text: 'Parent/Family' },
    { key: 'admin', value: 'Administrator', text: 'Administrator' },
    { key: 'student', value: 'Student', text: 'Student' },
    { key: 'other', value: 'Other', text: 'Other' }
  ];

  return (

    <>
      <Container>

        {isSubmitted
          ?
          <>
            <div className="center">
              <Lottie animationData={messageSent} loop={false} style={{ height: 200, position: "relative" }} />
            </div>
            <h1 className="center">Thank you. We will contact you soon.</h1>
            <div className="center">
              <Button size="small" color="black" onClick={handleGoHome}>Home</Button>
            </div>
          </>
          :
          <>
            <h1 className="center" style={{ fontSize: "3em", marginBottom: '-2px' }}>Picture Books Contact</h1>
            <Lottie animationData={contactGraphic} loop={false} style={{ height: 200, position: "relative" }} />
            <Form onSubmit={handleSubmit} className="contact-form">
              <div style={{ fontSize: '0.8em', color: '#333' }}>
                <Form.Input
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <Form.Input
                  type="email"
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <Form.Field
                  control={Dropdown}
                  label="I am a..."
                  name="role"
                  options={roleOptions}
                  value={formData.role}
                  onChange={handleInputChange}
                  placeholder="Select your role"
                  required
                  selection
                />
                <Form.Field
                  control={TextArea}
                  label="Question or Comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  required
                />
                <div className="button-container">
                  <Button size="tiny" type="submit" style={{ backgroundColor: '#3ce3fb', color: 'black' }}>Submit</Button>
                </div>


              </div>
            </Form>
          </>}
        <br /><br /><br />

      </Container>

    </>
  );
}
