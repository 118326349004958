// LoginSpecialPromo.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Button, Container } from 'semantic-ui-react'
import Lottie from 'lottie-react'
import GoogleButton from 'react-google-button'
import LoginSignupEmail from './LoginSignupEmail.jsx'
import wavingRobot from '../../images/promo/robot-wave-hand.json'
import welcomeSign from '../../images/promo/welcome.json'
import { ToastContainer, toast } from 'react-toastify';
import websiteMaintenance from '../../images/webpage-under-maintenance.json';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase.js';
import '../../styles/Promo.css';

import { loginMainMap } from '../../constants/siteTextMap.js'
import { motion, AnimatePresence } from "framer-motion";

import Promo from "./Promo.jsx";

const languagesArray = ["English", "Spanish", "French", "German"];


export default function Login(props) {
  const { currentUser, siteOnline } = props
  const [showSignUp, setShowSignUp] = useState("hello-user")
  const [showEmailUI, setShowEmailUI] = useState(false)
  const [SHOW_MAINTENANCE_PAGE, setSHOW_MAINTENANCE_PAGE] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const navigate = useNavigate()
  const toggleMain = () => setShowEmailUI(!showEmailUI)

  const dropIn = {
    initial: { y: -500 },
    animate: { y: 0 },
    transition: { type: 'spring', damping: 20, stiffness: 300, duration: 15 }  // increased duration for slower entry
  };

  useEffect(() => {
    const languageChangeInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * languagesArray.length);
      setCurrentLanguage(languagesArray[randomIndex]);
    }, 7000);

    return () => clearInterval(languageChangeInterval); // Cleanup
  }, []);

  useEffect(() => {
    const userDocRef = doc(db, 'picturebooks-settings', 'website');
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        setSHOW_MAINTENANCE_PAGE(!docSnap.data().siteOnline);
      } else {
        console.error('Document does not exist');
      }
    });

    return () => unsubscribe();

  }, []);

  const MAINTENANCE_PAGE_MESSAGE = null

  const showErrorDisplay = (message) => {
    console.log("X", message, "X");
    let messageDisplay = message
    if (message === "Firebase: Error (auth/user-not-found).") {
      messageDisplay = "Sorry, either the username or password is incorrect."
    }
    toast.error(messageDisplay, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: "light"
    });
  };

  if (!siteOnline && SHOW_MAINTENANCE_PAGE) {
    return <Container>
      <br />
      <br />
      <div >
        <Lottie animationData={websiteMaintenance} loop={true} style={{ height: 300, position: "relative" }} />
        <h2 className="center">Picture Books is currently down for maintenance.</h2>
        {MAINTENANCE_PAGE_MESSAGE && <h3 className="center" >{MAINTENANCE_PAGE_MESSAGE}</h3>}
      </div>
    </Container>
  }

  if (currentUser) return navigate("/home")

  return (<Container>

    <ToastContainer />

    <h1 className="center" style={{
      color: "blue",
      fontSize: "7em",
      fontFamily: "'Paytone One', sans-serif",
      marginBottom: '0'   // reduced margin-bottom for closer Welcome graphic
    }}>

      Picture Books</h1>
    <Lottie animationData={welcomeSign} loop={false} style={{
      height: 300,
      position: "relative",
      marginTop: '-75px'  // reduced margin-top for closer Welcome graphic
    }} />
    <motion.div {...dropIn} style={{ marginTop: '-70px' }}>  {/* reduced margin-top for closer Robot graphic */}
      <Lottie animationData={wavingRobot} loop={false} style={{ height: 300, position: "relative" }} />
    </motion.div>
    <h3 className="center"
      style={{
        color: "blue",
        fontSize: "1.5em",
        fontFamily: "'Paytone One', sans-serif",
        marginBottom: '15px'   // reduced margin-bottom for closer Welcome graphic
      }}
    >Begin with either a Google account or a username and password.</h3>
    {showEmailUI
      ? <>
        {showSignUp &&
          <LoginSignupEmail
            setShowSignUp={setShowSignUp}
            showErrorDisplay={showErrorDisplay}
            {...props} />
        }
      </>
      : null
    }

    <div className="center">
      {!showEmailUI && <GoogleButton onClick={() => navigate('/logingoogle')} />}
    </div>
    <br />
    <div className="center">
      {!showEmailUI && <Button onClick={toggleMain} size="huge" color="black">Email and Password</Button>}
    </div>
    <br />
    <br />

    <br />
    <h6 style={{ color: "black", fontSize: ".75em" }} className="center">BeechTree Ed LLC (c) 2023</h6>
  </Container>

  );
}