/* eslint-disable react/prop-types */
// ReadingAdventureStoryEdit.jsx
import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Container, Button } from 'semantic-ui-react'
import { serverTimestamp } from "firebase/firestore";

import { fetchAdventureStory, updateAdventureStory, deleteAdventureStory } from '../api/adventureStoryService';

import { updateAdventureReadersData, deleteAdventureReadersData } from '../api/adventureService'

import Lottie from "lottie-react";
import trashBin from '../../images/111436-move-to-trash.json';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css'


function ReadingAdventureStoryEdit({ currentUser }) {
  let navigate = useNavigate()
  const [storyData, setStoryData] = useState(null)
  const [originalText, setOriginalText] = useState(null)
  const [edittedText, setEdittedText] = useState(null)
  const [deleteSuccessful, setDeleteSuccessful] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [editedTitle, setEditedTitle] = useState(null);
  const [originalTitle, setOriginalTitle] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [story, setStory] = useState(null);

  const { adventureStoryId } = useParams();

  useEffect(() => {
    const fetchStory = async () => {
      const storyData = await fetchAdventureStory(adventureStoryId);
      if (storyData) {
        setStory(storyData);
      } else {
        console.log("No such document!");
      }
    };
    fetchStory();
  }, [adventureStoryId]);

  const { title, prompt, text, imageDownloadURL, imageMediumDownloadURL } = story || {}

  const textAreaRef = useRef();

  const adventureId = story?.adventureData?.adventureId;

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };

  const successfulDeleteToastie = (message) => {
    const toastOptions = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => navigate('/adventure-details/' + adventureId)
    }
    toast.warning(message, toastOptions);
  }

  useEffect(() => {
    if (story) {
      setOriginalText(story.text);
      setEdittedText(story.text);
      setOriginalTitle(story.title);
      setEditedTitle(story.title);
    }
    adjustTextAreaHeight();
  }, [story]);

  useEffect(() => {
    if (deleteSuccessful) {
      successfulDeleteToastie(title + " Deleted")
    }
  }, [deleteSuccessful]);



  const editPicture = () => { console.log("EDIT / REPLACE PICTURE") }
  const editStory = () => { console.log("EDIT  MODE") }

  const returnToStory = () => navigate('/adventure-story/' + adventureStoryId)

  const returnToAdventureList = () => navigate('/adventure-details/' + adventureId)

  const calculateRows = (text) => {
    if (!text) return 4; // At least 4 rows by default

    const numOfLines = (text.match(/\n/g) || []).length + 1;
    const numOfTextRows = Math.ceil(text.length / 40); // Adjust the value based on the desired text length per row
    return Math.max(numOfLines, numOfTextRows, 4); // At least 4 rows by default
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setEdittedText(e.target.value);
    }
  };


  const handleUpdates = async () => {
    setIsEditing(() => false);

    console.log("adventureId", adventureId)
    console.log("story", story)
    console.log("story.id", story.id)
    console.log("story.userId", story.userId)

    const updatedFields = {
      text: edittedText,
      title: editedTitle,
      dateModified: serverTimestamp()
    };

    try {
      await updateAdventureStory(story.id, updatedFields);

      // Check if the title has been edited
      if (story.title !== editedTitle) {
        // If so, also update the adventure record
        await updateAdventureReadersData(adventureId, story.id, editedTitle, story.userId);
        returnToStory();
      }
    } catch (error) {
      console.error('Error updating story: ', error);
    }
  };


  const handleDeleteAdventureStory = async () => {
    setDeleteInProgress(() => true)

    try {
      // delete the adventure story
      await deleteAdventureStory(story.id);

      // --- UNDO ---
      await deleteAdventureReadersData(adventureId, story.id, story.userId);

      setDeleteSuccessful(() => true)
      returnToAdventureList()
    } catch (error) {
      console.error('Error deleting story: ', error);
    }
  };



  const handleChange = (e) => {
    setEdittedText(e.target.value);
    setIsEditing(true);
    adjustTextAreaHeight();
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
    setIsEditing(true);
  };


  const discardChanges = () => {
    setIsEditing(() => false);
    setEdittedText(() => originalText)
    // toastie
    // route back to storydisplaymain
  };


  const aiButtons = <>
    <div className="center">
      <Button color="yellow" size="small" onClick={editStory}>👾 - Decrease Reading Level - 👾</Button >
      <Button color="yellow" size="small" onClick={editStory}>👾 + Increase Reading Level + 👾</Button >
    </div>
    <div className="center">
      <Button color="green" size="small" onClick={editStory}>👾 Embellish the story 👾</Button >
    </div>
    <div className="center">
      <Button color="green" size="small" onClick={editStory}> 👾 Create comprehension questions 👾</Button >
    </div>
  </>

  const generalButtons = <>
    <div className="center">
      <Button color="black" size="huge" onClick={returnToStory}>RETURN TO STORY MODE</Button >
    </div>
    <br />
    <div className="center">
      <Button color="white" size="tiny" onClick={handleDeleteAdventureStory}> ❌ Delete Story ❌</Button >
    </div>
  </>

  if (!currentUser) {
    navigate('/');
    return null;
  }



  return (<>
    <ToastContainer />
    <Container>

      {deleteInProgress ? <Lottie animationData={trashBin} loop={true} style={{ height: 600, position: "relative" }} /> :
        (
          <>
            <h2 className="center" style={{ fontSize: "5em", color: "blue" }}>EDIT MODE</h2>

            <div className="center">
              <h2 style={{ width: '100%', minWidth: '100%', display: 'inline-block' }}>
                <EditText
                  name="title"
                  defaultValue={title}
                  style={{
                    color: "blue",
                    fontSize: '1em',
                    lineHeight: '1.1',
                    width: '100%', // Add this to limit the width
                    minWidth: '100%', // Add this to ensure the minimum width is not too narrow
                    whiteSpace: 'pre-wrap', // Add this to enable text wrapping
                    overflowWrap: 'break-word', // Add this to break words if needed
                  }}
                  value={editedTitle || ''}
                  onChange={handleTitleChange}
                />
              </h2>
            </div>
            <div className="center" onClick={editPicture}>
              <img
                src={imageMediumDownloadURL}
                height="300px"
                width="300px"
                alt="" />
            </div>


            <EditTextarea
              name="textbox2"
              defaultValue={text}
              style={{
                padding: '10px',
                color: "blue",
                fontSize: '2em',
                lineHeight: '1.5',
                overflowY: 'auto',
                // maxHeight: '600px'
              }}
              rows={text ? calculateRows(text) : 4}
              value={edittedText || ''}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />

            {isEditing ?
              <>
                <br />
                <div className="center">
                  <Button color="green" size="huge" onClick={handleUpdates}>
                    Save Updates
                  </Button>
                </div>
                <br />
                <div className="center">
                  <Button color="white" size="tiny" onClick={discardChanges}> ❌ Discard Changes</Button >
                </div>
              </>
              : generalButtons}

            <br />
            <br />
          </>

        )}
    </Container> </>
  )
}

export default ReadingAdventureStoryEdit



// handleChange
// OLD VERSION : Written as if readerData is an object rather than a subcolleciion
// const handleUpdates = async () => {
//   setIsEditing(() => false);

//   const updatedFields = {
//     text: edittedText,
//     title: editedTitle,
//     dateModified: serverTimestamp()
//   };

//   try {
//     await updateAdventureStory(story.id, updatedFields);
//     console.log("🟠🟠🟠🟠🟠 Adventure story updated");


//     // Check if the title has been edited
//     if (story.title !== editedTitle) {
//       // If so, also update the adventure record

//       // First, fetch the adventure to get the existing readersData
//       const adventureDoc = await fetchAdventure(story?.adventureData?.adventureId);
//       console.log("🟠🟠🟠🟠🟠 Fetched adventure document", adventureDoc);

//       let updatedReadersData = { ...adventureDoc.readersData };

//       // Loop through each userId in readersData
//       for (let userId in updatedReadersData) {
//         // If this user has read this story, update the title
//         if (updatedReadersData[userId][story.id]) {
//           updatedReadersData[userId][story.id][0] = editedTitle;
//         }
//       }

//       console.log("🟠🟠🟠🟠🟠 updatedReadersData", updatedReadersData)


//       const adventureUpdateFields = {
//         readersData: updatedReadersData
//       };

//       console.log("🟠🟠🟠🟠🟠 adventureUpdateFields", adventureUpdateFields)

//       await updateAdventure(story?.adventureData?.adventureId, adventureUpdateFields);
//       console.log("🟠🟠🟠🟠🟠 Adventure updated successfully");

//       returnToStory()
//     } else {
//       console.log("🌎🌎🌎 ADVENTURE UPDATE NOT NEEDED 🌎🌎🌎")
//     }
//   } catch (error) {
//     console.error('Error updating story: ', error);
//   }
// };
