import React, { useState } from 'react';
import { Button, Form, Input, Container } from 'semantic-ui-react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

function PasswordReset(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      // setMessage('A password reset email has been sent to your email address.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const formStyle = {
    width: '50%',
    margin: 'auto',
  };

  const handleBackToHome = () => {
    navigate('/');
  };


  return (
    <Container>
      {success ? (
        <>
          <br />
          <br />
          <br />
          <h1 className="center">Reset Email Sent</h1>
          <div className="center">
            <Button color="black" onClick={handleBackToHome}>Home</Button>
          </div>
        </>
      ) :

        <>
          <br />
          <br />
          <h1 className="center">Reset Password</h1>
          <Form onSubmit={handleSubmit} style={formStyle}>
            <Form.Field>

              <Input
                size='massive'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                required
              />
            </Form.Field>
            <div className="center">

              <Button color="blue" type="submit">Send Reset Email</Button>
            </div>
          </Form>
          {message && <p>{message}</p>}



        </>}



    </Container>
  );
}

export default PasswordReset;




// import React, { useState } from 'react';
// import { Button, Form, Input } from 'semantic-ui-react';
// import { sendPasswordResetEmail } from 'firebase/auth';
// import { auth } from '../../firebase';
// import { useNavigate } from 'react-router-dom';

// function PasswordReset() {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');


//  

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await sendPasswordResetEmail(auth, email);
//       setMessage('A password reset email has been sent to your email address.');
//       setSuccess(true);
//     } catch (error) {
//       setMessage(`Error: ${error.message}`);
//     }
//   };

//   const formStyle = {
//     width: '50%',
//     margin: 'auto',
//   };

//   const handleBackToHome = () => {
//     navigate('/');
//   };

//   return (
//     <div>
//       {success ? (
//         <div>
//           <h1>Success</h1>
//           <Button onClick={handleBackToHome}>Back to Home</Button>
//         </div>
//       ) : (
//         <>
//           <h1>Reset Password</h1>
//           <Form onSubmit={handleSubmit} style={formStyle}>
//             <Form.Field>
//               <label>Email</label>
//               <Input
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter your email address"
//                 required
//               />
//             </Form.Field>
//             <Button type="submit">Send Password Reset Email</Button>
//           </Form>
//           {message && <p>{message}</p>}
//         </>
//       )}
//     </div>
//   );
// }

// export default PasswordReset;
