// StoryResources.jsx

import React, { useState, useRef } from 'react';
import updateStory from '../api/updateStory.js';
import TextContentEditModal from '../modals/TextContentEditModal';
import QuizItemEditModal from '../modals/QuizItemEditModal.jsx'; // 
import VocabularyItemEditModal from '../modals/VocabularyItemEditModal.jsx';
import idea2 from '../../images/ui/idea2.png'
import generateQuestion from './CreateApi/generateQuestion.js';
import generateVocabulary from './CreateApi/generateVocabulary.js';
import axios from 'axios';
import { Button, Icon, Loader } from 'semantic-ui-react';


// cancelToken,
// intervalIdRef


const StoryResources = ({
    userId,
    storyId,
    currentLanguage,
    storyLanguage,
    chatModelVersion,

    showResources,
    toggleResources,
    selectedItem,
    toggleItem,
    storyMap,

    title = '',
    text = '',
    summary = '',
    vocabulary = [],
    quizArray = [],
    conflict = '',
    resolution = '',
    setting = '',
    characters = '',
    theme = '',
    prompt = '',

}) => {


    const cancelTokenSourceRef1 = useRef(null);
    const intervalIdRef1 = useRef(null);
    // const currentLanguage = language // temp

    const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentContentType, setCurrentContentType] = useState('');
    const [currentContent, setCurrentContent] = useState('');
    const [visibleVocabIndex, setVisibleVocabIndex] = useState(null);
    const [selectedQuizItem, setSelectedQuizItem] = useState(null);
    const [isQuizItemEditModalOpen, setIsQuizItemEditModalOpen] = useState(false);
    const [selectedVocabItem, setSelectedVocabItem] = useState(null);
    const [isVocabItemEditModalOpen, setIsVocabItemEditModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vocabDisplayMode, setVocabDisplayMode] = useState({});



    const toggleQuestionAnswer = (index) => {
        setVisibleAnswerIndex(prevIndex => prevIndex === index ? null : index);
    };


    const handleDoubleClick = (contentType) => {
        setCurrentContentType(contentType);
        let contentValue = '';
        switch (contentType) {
            case 'summary':
                contentValue = summary;
                break;
            case 'conflict':
                contentValue = conflict;
                break;
            case 'resolution':
                contentValue = resolution;
                break;
            case 'theme':
                contentValue = theme;
                break;
            default:
                console.error('Unknown content type');
        }
        setCurrentContent(contentValue);
        setIsEditModalOpen(true);
    };

    const handleSaveEditedContent = (editedContent, contentType) => {

        updateStory({
            userId,
            storyId,
            updatedFields: {
                [contentType]: editedContent,
            },
        })
            .then(() => console.log(`${contentType} updated successfully`))
            .catch((error) => console.error(`Error updating ${contentType}:`, error))
            .finally(() => setIsEditModalOpen(false));
    };

    const handleQuizItemEdit = (quizItem) => {
        setSelectedQuizItem(quizItem); // Set the selected quiz item
        setIsQuizItemEditModalOpen(true); // Open the modal
    };

    const handleSaveQuizItem = (editedQuizItem) => {
        const updatedQuizArray = quizArray.map(item =>
            item === selectedQuizItem ? editedQuizItem : item
        );
        updateStory({
            userId,
            storyId,
            updatedFields: {
                quizArray: updatedQuizArray,
            },
        })
            .then(() => {
                setIsQuizItemEditModalOpen(false);
                setSelectedQuizItem(null);
            })
            .catch((error) => console.error(`Error updating quiz item:`, error));
    };

    const handleVocabItemEdit = (vocabItem) => {
        setSelectedVocabItem(vocabItem);
        setIsVocabItemEditModalOpen(true);
    };

    const handleSaveVocabItem = (editedVocabItem) => {
        const updatedVocabulary = vocabulary.map(item =>
            item === selectedVocabItem ? editedVocabItem : item
        );
        updateStory({
            userId,
            storyId,
            updatedFields: {
                vocabulary: updatedVocabulary,
            },
        })
            .then(() => {
                setIsVocabItemEditModalOpen(false);
                setSelectedVocabItem(null);
            })
            .catch((error) => console.error(`Error updating vocabulary item:`, error));
    };

    const generateQuestionText = "✨ " + storyMap[currentLanguage]?.generateQuestion || storyMap['English']?.generateQuestion

    const handleDeleteQuestion = async (quizItemToDelete) => {
        const updatedQuizArray = quizArray.filter(item => item !== quizItemToDelete);

        try {
            await updateStory({
                userId,
                storyId,
                updatedFields: {
                    quizArray: updatedQuizArray,
                },
            });

        } catch (error) {
            console.error("Failed to delete question:", error);
            // Handle any error feedback to the user here
        }
    };

    const handleDeleteVocabItem = (vocabItemToDelete) => {
        const updatedVocabulary = vocabulary.filter(item => item !== vocabItemToDelete);

        updateStory({
            userId,
            storyId,
            updatedFields: {
                vocabulary: updatedVocabulary,
            },
        })
            .then(() => console.log("Vocabulary item deleted successfully"))
            .catch((error) => console.error("Failed to delete vocabulary item:", error));
        // Optionally, update your state to reflect the changes immediately
    };

    const handleGenerateQuestion = async () => {
        setIsLoading(true);
        cancelTokenSourceRef1.current = axios.CancelToken.source();

        try {
            const newQuestion = await generateQuestion({
                userId,
                storyId,
                language: storyLanguage,
                chatModelVersion,
                title,
                text,
                summary,
                theme,
                vocabulary,
                quizArray,
                setting,
                characters,
                conflict,
                resolution,
                prompt,
                cancelToken: cancelTokenSourceRef1.current.token,
                intervalIdRef1
            });
            console.log("Question added successfully");

            const updatedQuizArray = [...quizArray, newQuestion];

            updateStory({
                userId,
                storyId,
                updatedFields: {
                    quizArray: updatedQuizArray,
                },
            }).then(() => {
                console.log("Quiz updated successfully");
            }).catch(error => console.error("Failed to update quiz:", error));
        } catch (error) {
            console.error("Failed to add question:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGenerateVocabulary = async () => {
        setIsLoading(true);
        cancelTokenSourceRef1.current = axios.CancelToken.source();

        try {
            const newVocabularyWord = await generateVocabulary({
                userId,
                storyId,
                language: storyLanguage,
                chatModelVersion,
                title,
                text,
                summary,
                theme,
                vocabulary,
                quizArray,
                setting,
                characters,
                conflict,
                resolution,
                prompt,
                cancelToken: cancelTokenSourceRef1.current.token,
                intervalIdRef1
            });
            console.log("Question added successfully");

            const updatedVocabularyArray = [...vocabulary, newVocabularyWord];

            updateStory({
                userId,
                storyId,
                updatedFields: {
                    vocabulary: updatedVocabularyArray,
                },
            }).then(() => {
                console.log("Vocabulary updated successfully");
            }).catch(error => console.error("Failed to update vocab:", error));
        } catch (error) {
            console.error("Failed to add vocab word:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleVocabToggle = (index) => {
        let resetModes = {};
        vocabulary.forEach((_, i) => {
            resetModes[i] = 'word'; // Reset all items to 'word'
        });

        // Set the clicked item to 'definition' if it was on 'word', otherwise cycle through modes
        if (!vocabDisplayMode[index] || vocabDisplayMode[index] === 'word') {
            resetModes[index] = 'definition'; // Directly flip to 'definition' for the new item
        } else if (vocabDisplayMode[index] === 'definition') {
            resetModes[index] = vocabulary[index].sentence ? 'sentence' : 'word'; // Cycle to 'sentence' or back to 'word'
        } else {
            resetModes[index] = 'word'; // From 'sentence' back to 'word'
        }

        setVocabDisplayMode(resetModes);
        setVisibleVocabIndex(index); // Update which vocab item is visually focused
    };


    console.log("vocabulary ===>> ", vocabulary)

    return (
        <>
            {!showResources && (
                <div className="center" onClick={() => toggleResources(true)}>
                    <img src={idea2} height="50px" alt="Logo" />
                </div>
            )}

            {showResources && (
                <div
                    style={{
                        backgroundColor: '#dcedfc',
                        padding: '15px',
                        borderRadius: '10px',
                        border: '1px solid black'
                    }}>

                    <div className="story-controls" style={{
                        backgroundColor: '#dcedfc',
                        padding: '5px'
                    }}>
                        {['quiz', 'vocabulary', 'summary', 'conflict', 'resolution', 'theme'].map((item) => (
                            <Button
                                key={item}
                                color={selectedItem === item ? 'green' : 'blue'}
                                onClick={() => toggleItem(item)}>
                                {storyMap[currentLanguage]?.[item] || storyMap['English']?.[item] || item}
                            </Button>
                        ))}
                    </div>

                    <div className="story-content">
                        {selectedItem === 'summary' && (
                            <h4
                                onDoubleClick={() => handleDoubleClick('summary')}
                                className="blue-pointer">{summary}</h4>
                        )}



                        {selectedItem === 'vocabulary' && (
                            <div className="vocabulary-container">
                                {vocabulary.map((vocabItem, index) => (
                                    <div key={index} className="vocabulary-item">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button
                                                className="left-aligned-text"
                                                onClick={() => handleVocabToggle(index)}
                                                style={{
                                                    marginBottom: '10px',
                                                    // Adjust button and text colors based on the display mode
                                                    color: vocabDisplayMode[index] === 'definition' ? "white" :
                                                        vocabDisplayMode[index] === 'sentence' ? "white" : "black",
                                                    backgroundColor: vocabDisplayMode[index] === 'definition' ? "black" :
                                                        vocabDisplayMode[index] === 'sentence' ? "#8080ff" : "white", // Lighter grey for sentences
                                                }}
                                            >
                                                {vocabDisplayMode[index] === 'definition' ? vocabItem.definition :
                                                    vocabDisplayMode[index] === 'sentence' && vocabItem.sentence ? vocabItem.sentence :
                                                        vocabItem.word}
                                            </Button>

                                            <div onClick={() => handleVocabItemEdit(vocabItem)} style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                <Icon name='edit' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="add-vocabulary-button" style={{ marginTop: "20px" }}>
                                    <Button color="black" onClick={handleGenerateVocabulary}>
                                        {isLoading ? <Loader active inline size='small' /> : "✨ Add a Word"}
                                    </Button>
                                </div>
                            </div>
                        )}



                        {selectedItem === 'quiz' && (
                            <div className="quiz-container">
                                {quizArray.map((quizItem, index) => (
                                    <div key={index} className="quiz-item">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {visibleAnswerIndex === index ? (
                                                <Button
                                                    className="left-aligned-text"
                                                    color="black"
                                                    onClick={() => toggleQuestionAnswer(index)}
                                                    style={{ marginBottom: '10px' }}>
                                                    {quizItem.answer}
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="left-aligned-text"
                                                    onClick={() => toggleQuestionAnswer(index)}
                                                    style={{
                                                        marginBottom: '10px',
                                                        color: "black",
                                                        backgroundColor: "white"
                                                    }}>
                                                    #{index + 1}: {quizItem.question}
                                                </Button>
                                            )}
                                            <div onClick={() => handleQuizItemEdit(quizItem)} style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                <Icon name='edit' size='large' />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="add-question-button" style={{ marginTop: "20px" }}>
                                    <Button color="black" onClick={handleGenerateQuestion}>
                                        {isLoading
                                            ? <Loader active inline size='small' />
                                            : generateQuestionText}
                                    </Button>
                                </div>
                            </div>
                        )}

                        {
                            selectedItem === 'conflict' && (
                                <h4 onDoubleClick={() => handleDoubleClick('conflict')}
                                    className="blue-pointer"
                                >{conflict}</h4>
                            )
                        }
                        {
                            selectedItem === 'resolution' && (
                                <h4 onDoubleClick={() => handleDoubleClick('resolution')} className="blue-pointer">{resolution}</h4>
                            )
                        }
                        {
                            selectedItem === 'theme' && (
                                <h4 onDoubleClick={() => handleDoubleClick('theme')} className="blue-pointer">{theme}</h4>
                            )
                        }

                    </div >

                    <br />

                    <div className="center" onClick={() => toggleResources(false)}>
                        <span style={{ cursor: 'pointer' }}></span>
                        <Icon color="black" size="large" name='eye slash' />
                    </div>
                </div >
            )}


            <TextContentEditModal
                currentLanguage={currentLanguage}
                open={isEditModalOpen}
                content={currentContent}
                contentType={currentContentType}
                onSave={handleSaveEditedContent}
                onClose={() => setIsEditModalOpen(false)} />


            <QuizItemEditModal
                currentLanguage={currentLanguage}
                open={isQuizItemEditModalOpen}
                quizItem={selectedQuizItem}
                onSave={handleSaveQuizItem}
                onClose={() => setIsQuizItemEditModalOpen(false)}
                onDelete={handleDeleteQuestion}
            />


            <VocabularyItemEditModal
                currentLanguage={currentLanguage}
                open={isVocabItemEditModalOpen}
                vocabItem={selectedVocabItem}
                onSave={handleSaveVocabItem}
                onClose={() => setIsVocabItemEditModalOpen(false)}
                onDelete={handleDeleteVocabItem} />

        </>

    );
};

export default StoryResources;


