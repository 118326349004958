import React, { useEffect, useState } from 'react'
import { collection, onSnapshot } from "firebase/firestore"
import DisplayCard from './DisplayCard'
import { Grid } from 'semantic-ui-react'
import deleteStory from '../api/deleteStory';
import updateStory from '../api/updateStory';

export default function DisplayFirestoreArchivedDocs(props) {

  const { db, userId, storyHandler } = props;
  const storiesCollectionRef = collection(db, "users", userId, "stories")
  const [savedStories, setSavedStories] = useState(null)

  const keepFirstXWords = (text, numberOfWords) => {
    return text.split(" ").slice(0, numberOfWords)
  }

  useEffect(() => {

    onSnapshot(storiesCollectionRef, (snapshot) => {
      let dbStories = []

      snapshot.docs.forEach((doc) => {
        const docData = doc.data()

        // modify date
        docData.dateModifiedReadable = docData?.dateModified && new Date(docData.dateModified.seconds * 1000)
        docData.dateCreatedReadable = docData?.dateCreated && new Date(docData.dateCreated.seconds * 1000)


        dbStories.push({ ...doc.data(), id: doc.id })
      })

      // oldest on top
      dbStories.sort((a, b) => {
        return new Date(a.dateCreated?.seconds * 1000) - new Date(b.dateCreated?.seconds * 1000);
      });

      // newest on top
      // dbStories.sort((a, b) => {
      //   return new Date(b.dateCreated.seconds * 1000) - new Date(a.dateCreated.seconds * 1000);
      // });

      setSavedStories(dbStories)
    })
  }, [])



  const unarchiveItem = ({ userId, item, db }) => {
    updateStory({ userId, storyId: item.storyId, db, updatedFields: { archived: false } });
  };


  const savedStoryCollectionDisplay = savedStories && savedStories.map(item => {

    console.log("item", item)
    console.log("item?.archived", item.archived)

    if (!item?.archived) return null
    // const textDisplay = item.text && keepFirstXWords(item.text, 20).join(" ") + "..."

    return (<> <Grid.Column>
      <DisplayCard
        handleClick={() => storyHandler(item.storyId)}
        title={item?.title}
        imageURL={item?.imageDownloadURL}
        text={null}
        deleteStory={deleteStory}
        updateStory={updateStory}
        center />

      <div>

        <span onClick={() => unarchiveItem({ userId, item, db })}
          style={{ cursor: "pointer", color: "green" }}> 🟢 Unarchive
        </span>

        <span>{" - "}</span>

        <span onClick={() => deleteStory({ userId, item, db })}
          style={{ cursor: "pointer", color: "red" }}>❌ Delete
        </span>

      </div>

    </Grid.Column>

    </>)
  })

  return <> {savedStories && <Grid container columns={5} doubling stackable>
    {savedStoryCollectionDisplay} </Grid>}
  </>

}
