// CreateDalle2.jsx
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Container, Checkbox } from 'semantic-ui-react'
import axios from 'axios'
import { setDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid'
import Lottie from "lottie-react";
import 'react-toastify/dist/ReactToastify.css';

import coloredCircleAnimation from '../../images/94137-1hour-loading-2.json';
import errorCat from '../../images/9195-error.json';
import savingDots from '../../images/savingDots.json';
import animatedRobot from '../../images/99973-little-power-robot.json';
import CurrentStoryDisplay35 from '../general/CurrentStoryDisplay35';
import ReactTooltip from 'react-tooltip'
// import { motion } from "framer-motion";

import {
  ILLUSTRATION_AI_PROMPTS
} from '../../constants/illustrationsConsts'
import { ILLUSTRATION_BUTTON_IMAGE_URLS } from '../../constants/illustrationsConsts'
import { ILLUSTRATION_STYLES } from '../../constants/illustrationsConsts'

import image0 from '../../images/button-images/image0_suprise.jpg';
import image1 from '../../images/button-images/image1_oil.jpg';
import image2 from '../../images/button-images/image2_cartoon.jpg';
import image3 from '../../images/button-images/image3_realistic.jpg';
import image4 from '../../images/button-images/image4_comic.jpg';
import image5 from '../../images/button-images/image5_line.jpg';
import image6 from '../../images/button-images/image6_abstract.jpg';
// impor from '../../images/button-image_exaggerated_dreamlike.jpg';
import image8 from '../../images/button-images/image8_vintage.jpg';

import image9 from '../../images/button-images/image9_cyberpunk.jpg';

import image10 from '../../images/button-images/image10_picasso.jpg';
import image11 from '../../images/button-images/image11_vangogh.jpg';
import image12 from '../../images/button-images/image12_subdued.jpg';

const illustrationImages = [image0, image1, image2, image3, image4, image5, image6, image8, image9, image10, image11, image12];


// function parseText(text) {
//   if (!text || text?.length < 3) return null
//   return text.split(/[\s,\n]+/);
// }

const CreateDalle2 = (props) => {
  const { db, currentUser, firestoreUserData, showNavbar, setShowNavbar, chatModelVersion } = props


  let navigate = useNavigate()

  // update to progressively add chats
  const [fullChatRecord, setFullChatRecord] = useState(null)

  const [responseAI, setResponseAI] = useState(null)
  const [storyText1, setStoryText1] = useState(null)
  const [storyTitle2, setStoryTitle2] = useState(null)
  const [storySummary3, setStorySummary3] = useState(null)
  const [storyBlurb3b, setStoryBlurb3b] = useState(null)
  const [storyImagePrompt4, setStoryImagePrompt4] = useState(null)
  const [storyImageURL5, setStoryImageURL5] = useState(null)

  // new
  const [fullResponse, setFullResponse] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [language, setStoryLanguage] = useState(firestoreUserData?.currentLanguage ? firestoreUserData.currentLanguage : "English")

  const [languageEmoji, setLanguageEmoji] = useState(firestoreUserData?.currentFlag ? firestoreUserData.currentFlag : "🇺🇸")

  const [ageLevel, setAgeLevel] = useState({ display: "6-8 years old.", ageLevelPrefix: "6 to 8" })
  const [wordBankArray, setWordBankArray] = useState([])

  const [newStoryId, setNewStoryId] = useState(null)
  const [currentStoryCollection, setCurrentStoryCollection] = useState(null)
  const [userStoryPrompt, setUserStoryPrompt] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPart2, setIsLoadingPart2] = useState(false)

  const [keeperIsSaving, setKeeperIsSaving] = useState(false)
  const [showAdvancedUI, setShowAdvancedUI] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const [disableBackButton, setDisableBackButton] = useState(true);

  const [illustrationType, setIllustrationType] = useState([0, ILLUSTRATION_STYLES[0], ILLUSTRATION_BUTTON_IMAGE_URLS[0], ILLUSTRATION_AI_PROMPTS[0]])

  const [updatedFBData, setFBData] = useState(null)
  const [updateError, setUpdateError] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(null)
  const [selectedHeading, setSelectedHeading] = useState(null);
  const { register, handleSubmit, reset, errors } = useForm({}); // errors
  const notify = (message) => toast(message);

  const robotStyle = { height: 200 };
  const baseUrl = process.env.REACT_APP_API_URL
  const storage = getStorage();

  useEffect(() => { setNewStoryId(uuidv4()) }, [])

  useEffect(() => {
    console.log(`🦋${chatModelVersion}🦋`)
  }, [chatModelVersion])

  useEffect(() => {
    if (responseAI === "part2") {
      //console.log("REQUEST PIC AND TITLE")
      submitTitlePromptImage({ fullChatRecord, chatModelVersion, language, userId: currentUser?.uid, illustrationType, newStoryId })
    }
  }, [responseAI])

  useEffect(() => {
    // Disable back navigation by pushing the same state again
    window.history.pushState(null, document.title, window.location.href);

    setDisableBackButton(true)
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/create35');  // Navigate to home or some other page
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);



  // BUG TO FIX: Also check for puncuation
  const removeQuotesFromEnds = (string) => {
    return string.replace(/^['"]|['"]$/g, "")
  }

  const updateUserDoc = async (itemsToUpdate) => {
    const docRef = doc(db, "users", currentUser?.uid);
    try {
      await updateDoc(docRef, itemsToUpdate);
      setUpdateLoading(true);
      // after updating, get the updated data
      const docSnap = await docRef.get();
      const response = docSnap.data()
      setFBData(response)
      setUpdateError(null)

    } catch (error) {
      setUpdateError(error)
      setFBData(null)

    } finally {
      setUpdateLoading(false);
    }
  }

  function wordBankSplit(wordBank) {

    if (wordBank === "" || wordBank.trim() === "") {
      return []
    }

    const pattern = /,|\n/;
    let wordBankDivided = []

    if (wordBank) {
      wordBankDivided = wordBank.split(pattern)
    }

    // Filter out any empty strings and trim the words
    const finalwordBankArray = wordBankDivided.length > 0 ? wordBankDivided.map(word => word.trim()).filter(word => word !== "") : [...wordBankDivided]

    return finalwordBankArray;
  }

  const submitStoryPrompt = formInput => {
    const { userRequest, wordBank } = formInput;

    let wordBankArrayTemp = [];

    if (typeof wordBank === 'string' && wordBank.length > 0 && wordBank.replace(',', '').length > 0) {
      wordBankArrayTemp = wordBankSplit(wordBank);
    }

    setWordBankArray(() => wordBankArrayTemp);

    let storyRequest = {
      userRequest,
      userId: currentUser?.uid,
      ageLevel: ageLevel?.ageLevelPrefix,
      wordBank: wordBankArrayTemp,
      chatModelVersion,
      language
    };

    // TO DO: add abort button!
    setShowNavbar(false)
    setUserStoryPrompt(userRequest)
    setIsLoading(true)
    setResponseAI("part1")


    axios.post(baseUrl + "/gpt4-dalle2/storyonly/", storyRequest)
      .then((response) => {
        const jobId = response.data.jobId;

        const intervalId = setInterval(() => {
          axios.get(baseUrl + `/gpt4-dalle2/storyonly/status/${jobId}`)
            .then((response) => {
              const job = response.data;

              // console.log("job.returnvalue ====>>>>>", job.returnvalue)
              //               Please note that the returnvalue is attached to the job object when the done() function is called in the job processing code. Therefore, if getGpt4Story_11() function does not resolve (e.g., due to an unhandled error or an infinite loop), the done() function will not be called, and the returnvalue will not be set.

              // Also, note that the job's returnvalue will be undefined until the job has completed. If you attempt to access job.returnvalue while the job is still processing, it will be undefined. In your front-end code, you are correctly polling the job status and only attempting to access job.returnvalue once the job has completed.

              // Stop polling if the job is either completed or failed
              if (job.status === 'completed' || job.status === 'failed') {
                clearInterval(intervalId);
              }

              if (job.status === 'completed') {
                if (job.returnvalue && job.returnvalue.length > 1) {

                  const storyText = job.returnvalue;

                  setFullChatRecord(() => storyText[0])
                  setStoryText1(() => storyText[1])
                  setShowNavbar(true)
                  setIsLoading(false)
                  setResponseAI("part2")
                } else {
                  console.error("Job completed but return value is missing or invalid: ", jobId);
                  setIsLoading(false)
                  setShowNavbar(true)
                  notify("There was an issue processing your request. Please try again.");
                }
              } else if (job.status === 'failed') {
                // Handle job failure
                console.error("Job failed with ID: ", jobId);
                setIsLoading(false)
                setShowNavbar(true)
                setServerError(() => true)
                notify("Job failed. Please try again.");
              }
            })
            .catch(err => {
              setIsLoading(false)
              setShowNavbar(true)
              setServerError(() => true)
              notify("🙄 " + err?.message)
              console.error("❌❌❌", err.message, "❌❌❌")
            });
        }, 5000);
      })
      .catch(err => {
        console.error("❌❌❌", err.message, "❌❌❌")
      });
  };

  function getDownloadURLWithRetry(ref, path, retries = 5, delay = 1000) {

    return new Promise((resolve, reject) => {
      const attemptDownload = (retriesLeft) => {
        getDownloadURL(ref, path)
          .then(resolve)
          .catch((error) => {
            if (retriesLeft === 0) {
              reject(error);
            } else {
              setTimeout(() => attemptDownload(retriesLeft - 1), delay);
            }
          });
      };

      attemptDownload(retries);
    });
  }

  const submitTitlePromptImage = async ({ fullChatRecord, language, chatModelVersion, userId, illustrationType, newStoryId }) => {
    const titlePromptImageRequest = {
      fullChatRecord,
      language,
      chatModelVersion,
      userId,
      illustrationType: illustrationType[3],
      storyIdTitle: newStoryId
    };

    setIsLoadingPart2(true);

    try {
      const response = await axios.post(baseUrl + "/gpt4-dalle2/titlepromptimage/", titlePromptImageRequest);
      const jobId = response.data.jobId;
      //console.log("Job ID titlepromptimage:", jobId);

      const intervalId = setInterval(async () => {
        try {
          const jobStatusResponse = await axios.get(baseUrl + `/gpt4-dalle2/titlepromptimage/status/${jobId}`);
          const job = jobStatusResponse.data;
          // console.log("job ===>>>", job);

          if (job.status === 'completed') {
            clearInterval(intervalId);
            setIsLoadingPart2(false);

            const result = job.returnvalue;
            setFullResponse(result);
            setFullChatRecord(result[0].fullChatRecord || "Error: " + result[0].error);
            setStoryTitle2(result[2].result ? removeQuotesFromEnds(result[2].result) : "Error: " + result[2].error);
            setStorySummary3(result[3].result || "Error: " + result[3].error);
            setStoryBlurb3b(result[4].result || "Error: " + result[4].error);
            setStoryImagePrompt4(result[5].result || "Error: " + result[5].error);

            const suffixId = "_picturebook_DEC2022.png";
            const imageMediumFilePath = "images/USERSET_A_" + currentUser.uid + "/medium_" + newStoryId + suffixId;

            const downloadMediumURL = await getDownloadURLWithRetry(ref(storage, imageMediumFilePath));

            setStoryImageURL5(downloadMediumURL || "Error: " + result[7].error);

            setResponseAI("part3");

          } else if (job.status === 'failed') {
            clearInterval(intervalId);
            setIsLoadingPart2(false);
            const errorMessage = job.error;
            setErrorMessage(errorMessage);
          }

        } catch (err) {
          console.error(err);
          setIsLoadingPart2(false);
          setErrorMessage("An error occurred while checking job status.");
        }
      }, 5000);
    } catch (err) {
      console.error(err);
      setIsLoadingPart2(false);
      setErrorMessage("An error occurred while submitting the title prompt image request.");
    }

  };


  const clearEntry = () => {
    setResponseAI(null)
    reset()
  }


  const successfullySave = (message) => {
    const toastOptions = {
      position: "top-right",
      autoClose: 400,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    }
    toast.success(message, toastOptions)
  }

  const redoPicture = () => { console.log("NEW PICTURE REQUEST") }
  // easy redo or redo by editing the generatedPrompt

  const redoStory = () => { console.log("NEW STORY REQUEST") }


  const keeper35 = async () => {
    if (!currentUser) return null

    setKeeperIsSaving(() => true)
    setShowNavbar(false)

    let newStory = {
      userId: currentUser.uid,
      userDisplayName: currentUser?.displayName,
      userEmail: currentUser?.email,
      title: storyTitle2,
      text: storyText1,
      summary: storySummary3,
      blurb: storyBlurb3b,
      image: storyImageURL5, // 1 -remove?  imageTemp
      imagePrompt: storyImagePrompt4,
      illustrationType: illustrationType[3],
      // FIX
      ageLevel: ageLevel?.ageLevelPrefix,
      wordBank: wordBankArray,

      language,
      languageEmoji,

      fullChatRecord,
      chatModelVersion,
      imageGeneration: "Dalle2",
      storyId: newStoryId,
      publicAccess: true,
      archived: false,
      prompt: userStoryPrompt,
      dateCreated: serverTimestamp(),
      dateModified: serverTimestamp()
    }

    const suffixId = "_picturebook_DEC2022.png"

    const imageFilePath = "images/USERSET_A_" + currentUser.uid + "/" + newStoryId + suffixId

    const imageThumbFilePath = "images/USERSET_A_" + currentUser.uid + "/thumb_" + newStoryId + suffixId

    const imageMediumFilePath = "images/USERSET_A_" + currentUser.uid + "/medium_" + newStoryId + suffixId


    try {
      // ORIGINAL
      const imageDownloadURL = await getDownloadURL(ref(storage, imageFilePath))
      newStory.imageDownloadURL = imageDownloadURL

      // THUMBNAIL
      const downloadThumbURL = await getDownloadURL(ref(storage, imageThumbFilePath))
      newStory.imageThumbDownloadURL = downloadThumbURL

      // MEDIUM
      const downloadMediumURL = await getDownloadURL(ref(storage, imageMediumFilePath))
      newStory.imageMediumDownloadURL = downloadMediumURL


      await setDoc(doc(db, "users", currentUser.uid, "stories", newStoryId), newStory)

      const updatedStoryCollection = currentStoryCollection ? [...currentStoryCollection] : []
      updatedStoryCollection.push(newStory)

      setCurrentStoryCollection(updatedStoryCollection)// needed?
      setResponseAI(null)
      setKeeperIsSaving(() => false)

      // LATER: handle errors in credits
      let currentCredit = firestoreUserData?.credit

      //let currentCreditErrors = firestoreUserData?.creditErrors || []

      if (isNaN(currentCredit)) {
        currentCredit = 0
        // currentCreditErrors.push()
      }


      const updateObject = { credit: currentCredit - parseInt(process.env.REACT_APP_IMAGE_CREDIT) }



      await updateUserDoc(updateObject)


      setShowNavbar(true)
      setNewStoryId(uuidv4())

      setResponseAI(null)
      setStoryText1(null)
      setStoryTitle2(null)
      setStorySummary3(null)
      setStoryImagePrompt4(null)
      setStoryImageURL5(null)
      //setStoryImageURL5_full(null)
      setWordBankArray(null)

      navigate('/storydisplaymain/' + newStoryId);


    } catch (err) {
      setServerError(() => true)
      setShowNavbar(true)
      console.error("❌", err.message, "❌")
      console.error("❌❌", err, "❌❌")
    }
  }


  const clearError = () => {
    clearEntry()
    setResponseAI(null)
    setStoryText1(null)
    setStoryTitle2(null)
    setStorySummary3(null)
    setStoryImagePrompt4(null)
    setStoryImageURL5(null)

    setServerError(false)

    setWordBankArray(null)
  }

  const paidOptions = (<>

    <h2>Word bank</h2>
    <textarea
      type="text"
      placeholder="Enter up to 5 words to include in the story"
      rows="6"
      // cols="40"
      style={{
        fontSize: '1.5em',
        fontFamily: 'Courier, monospace',
        boxSizing: 'border-box'
      }}
      {...register('wordBank')} />
    <br />
    <h2>For children {ageLevel?.display}</h2>
    <h4
      className={`studentAge ${selectedHeading === 'k1' ? 'selected' : ''
        }`}
      onClick={() => {
        setAgeLevel({ display: '4-5 years old.', ageLevelPrefix: '4 to 5' });
        setSelectedHeading('k1');
      }}
    >
      K-1
    </h4>
    <h4
      className={`studentAge ${selectedHeading === '23' ? 'selected' : ''
        }`}
      onClick={() => {
        setAgeLevel({ display: '6-8 years old.', ageLevelPrefix: '6 to 8' });
        setSelectedHeading('23');
      }}
    >
      2nd-3rd
    </h4>
    <h4
      className={`studentAge ${selectedHeading === '45' ? 'selected' : ''
        }`}
      onClick={() => {
        setAgeLevel({ display: '9-11 years old.', ageLevelPrefix: '9 to 11' });
        setSelectedHeading('45');
      }}
    >
      4th-5th
    </h4>
    <h4
      className={`studentAge ${selectedHeading === '6+' ? 'selected' : ''
        }`}
      onClick={() => {
        setAgeLevel({ display: '12 years old and above.', ageLevelPrefix: '12 to 14' });
        setSelectedHeading('6+');
      }}
    >
      6th +
    </h4>
  </>)


  const chooseIllustrationType = () => {

    let advanceNumber = illustrationType[0] + 1;
    if (advanceNumber >= ILLUSTRATION_STYLES.length) {
      advanceNumber = 0;
    }
    setIllustrationType([
      advanceNumber,
      ILLUSTRATION_STYLES[advanceNumber],
      ILLUSTRATION_BUTTON_IMAGE_URLS[advanceNumber],
      ILLUSTRATION_AI_PROMPTS[advanceNumber],
    ]);
  };


  const basicStoryUI = () => {
    const isMobile = window.innerWidth <= 600;
    return <>
      <style>
        {`
    @media (min-width: 601px) {
      textarea {
        width: 45ch;
      }
      h2 {
        font-size: 2.5em;
      }
    }

    @media (max-width: 600px) {
      textarea {
        width: 90%;
        box-sizing: border-box;
      }

      textarea::placeholder {
        font-size: .8em;
      }

      h2 {
        font-size: 1.8em;
      }

      button {
        font-size: 1em;
      }
    }
  `}
      </style>
      <h2 style={{ color: "grey" }}>Write and illustrate a story {language !== "English" ? "in " + language + " " : null}about:</h2>


      <form onSubmit={e => e.preventDefault()}>
        <textarea
          type="text"
          placeholder="a character who... in a place that... when suddenly... with a theme of..."
          rows="6"
          {...register('userRequest', { required: true, maxLength: 1000 })}
          style={{
            fontSize: '1.5em',
            fontFamily: 'Courier, monospace',
            boxSizing: 'border-box'
          }}
        />

        <br />

        <h2
          data-border={true}
          data-tip={"Toggle the " + ILLUSTRATION_STYLES.length + " styles"}
          size="tiny"

          style={{ color: "grey", cursor: "pointer" }}
          onClick={chooseIllustrationType}
        >
          Choose art style: {illustrationType[1]}
        </h2>

        <br />

        {firestoreUserData?.credit > 0
          ? <Button
            onClick={handleSubmit(submitStoryPrompt)}
            size="massive"
            inverted
            type="submit"
            color="green"
            style={{
              fontSize: '1.5em',
              width: isMobile ? '75%' : '200px'
            }}
          >
            Create!
          </Button>
          : <Button
            onClick={() => navigate('/payment')}
            size="massive"
            type="submit"
            color="orange"
            style={{
              fontSize: '1.5em',
              width: isMobile ? '75%' : '200px'
            }}
          >
            Add Credits
          </Button>}


        <br />
      </form>

      <br />
      <br />
    </>
  }

  const advancedStoryUI = () => {
    return <>
      <style>
        {`
    @media (min-width: 601px) {
      textarea {
        width: 45ch;
      }
      h2 {
        font-size: 2.5em;
      }
    }

    @media (max-width: 600px) {
      textarea {
        width: 90%;
        box-sizing: border-box;
      }

      textarea::placeholder {
        font-size: .8em;
      }

      h2 {
        font-size: 1.8em;
      }

      button {
        font-size: 1em;
      }
    }
  `}
      </style>

      <form onSubmit={e => e.preventDefault()}>
        <textarea
          type="text"
          placeholder="a character who... in a place that... when suddenly... with a theme of..."
          rows="6"
          {...register('userRequest', { required: true, maxLength: 1000 })}
          style={{
            fontSize: '1.5em',
            fontFamily: 'Courier, monospace',
            boxSizing: 'border-box'
          }}
        />

        <br />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2
            data-border={true}
            onClick={chooseIllustrationType}
            data-tip={"Toggle the " + ILLUSTRATION_STYLES.length + " styles"}
            size="tiny"
            style={{ fontSize: "2em", margin: "5px", cursor: 'pointer' }}
          >
            Choose art style: {illustrationType[1]}
          </h2>

          <img
            onClick={chooseIllustrationType}
            src={illustrationImages[illustrationType[0]]}
            // src={image4}
            alt="illustration"
          />


        </div>

        <br />

        {paidOptions}

        <div>
          <Button
            onClick={handleSubmit(submitStoryPrompt)}
            size="massive"
            inverted
            type="submit"
            color="blue"
            style={{ fontSize: '1.5em' }}
          >
            Create!
          </Button>
        </div>
        <br />
        <br />
      </form>
    </>

  }

  if (!currentUser) {
    console.log("  ❌ CURRENT USER ❌  ", currentUser)
    return navigate("/")
  }

  if (serverError) {
    return <Container>
      <h1 className="center">Open AI servers are experiencing issues right now. Try again later.</h1>
      <Lottie animationData={errorCat} loop={true} style={{ height: 600, position: "relative" }} />
      <div className="center">
        <Button onClick={clearError} className="center" size="huge" color="black">Continue</Button>
      </div>
    </Container>
  }

  if (errorMessage) {
    console.error("errorMessage", errorMessage)
    return (<>

      <h2>Hmmmm, we have had an error:{errorMessage}</h2>
      <h2>However, you can save what you have and fix it later</h2>

      {responseAI && <CurrentStoryDisplay35
        size="100px"
        text={storyText1 ? storyText1 : "No story text"}
        title={storyTitle2 ? storyTitle2 : "No title"}
        summary={storySummary3 ? storySummary3 : "No summary"}
        imageURL={storyImageURL5 ? storyImageURL5 : "No URL"}

        responseAI={responseAI}
        keeper={keeper35}
        clearEntry={clearEntry}
        redoPicture={redoPicture}
        wordBank={wordBankArray}

      />
      }

    </>)
  }

  if (keeperIsSaving) {
    return <Container>
      <Lottie animationData={savingDots} loop={true} style={{ height: 600, position: "relative" }} />
    </Container>
  }


  if (isLoading)
    return (
      <>
        <Container style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >

            <h1>
              {userStoryPrompt}
            </h1>
            {/* <motion.h1
              style={{
                color: "green",
                fontSize: "3em",
                position: "absolute",
                zIndex: 2,
              }}
              animate={{
                y: [-50, 50, -50],
                x: [-50, 50, -50],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
                delay: Math.random() * 2,
              }}
            >
              {userStoryPrompt}
            </motion.h1> */}
          </div>
          <Lottie animationData={coloredCircleAnimation} loop={true} style={{ height: 600, position: "relative" }} />
        </Container>
      </>

    );



  return (
    <Container>
      <ToastContainer />
      <ReactTooltip delayShow={1000} />
      <div className="App">
        <body>
          {responseAI

            ? <CurrentStoryDisplay35
              size="100px"
              text={storyText1}
              title={storyTitle2}
              summary={storySummary3}
              imageURL={storyImageURL5}
              keeper={keeper35}
              responseAI={responseAI}
              clearEntry={clearEntry}
              redoPicture={redoPicture}
              wordBank={wordBankArray}
            />
            :
            <>
              <div>
                <Lottie
                  animationData={animatedRobot}
                  loop={true}
                  style={robotStyle} />
              </div>
              <h6 style={{ fontSize: ".6em", margin: "0px" }}> Model: {chatModelVersion} /  Dalle2</h6>


              {showAdvancedUI ? advancedStoryUI() : basicStoryUI()}

              <Checkbox
                toggle
                checked={showAdvancedUI}
                onChange={() => setShowAdvancedUI(!showAdvancedUI)} />
              {showAdvancedUI
                ?
                <h4 style={{ color: "blue", margin: "2px" }}>Advanced Interface</h4>

                : <h4 style={{ color: "gray", margin: "2px" }}>Simple Interface</h4>}
            </>
          }
        </body>
      </div >
    </Container>
  )
}

export default CreateDalle2
