import axios from 'axios';
import ClipboardJS from 'clipboard';

const generateNewStaticLink = async (
  { setLoadingStaticLink,
    title,
    text,

    // quizQuestions,
    quizArray,

    imageDownloadURL,
    imageMediumDownloadURL,
    currentUser,
    baseUrl,
    storyId,
    updateStory,
    db,
    setGeneratedStaticLink,
    setLinkCopied }
) => {

  console.log("----- GNSL quizArray==>>>>>", quizArray)
  setLoadingStaticLink(true);


  const storyData = {
    title,
    text,
    imageDownloadURL,
    imageMediumDownloadURL,
    // quizQuestions,
    quizArray,
    authorName: currentUser?.displayName,
    authorID: currentUser?.uid,
  };

  try {
    const response = await axios.post(
      baseUrl + '/storyexportsimple/' + storyId,
      storyData
    );
    const { url } = response.data;

    updateStory({
      userId: currentUser?.uid,
      db,
      storyId,
      updatedFields: { staticLink: url },
    });

    const btn = document.createElement('button');
    btn.style.display = 'none';
    document.body.appendChild(btn);

    const clipboard = new ClipboardJS(btn, {
      text: function () {
        return url;
      }
    });

    clipboard.on('success', function () {
      setLinkCopied(true);
      clipboard.destroy();
      document.body.removeChild(btn);
    });

    clipboard.on('error', function (err) {
      console.error('Could not copy URL: ', err);
      clipboard.destroy();
      document.body.removeChild(btn);
    });

    btn.click();

    setGeneratedStaticLink(url);
    setLoadingStaticLink(false);
  } catch (err) {
    console.error('❌', err.message, '❌');
    setLoadingStaticLink(false);
  }
};

export default generateNewStaticLink;
