import React, { useState, useEffect, useRef } from 'react';
import ClipboardJS from 'clipboard';

const CopyToClipboardDiv = ({ text, successMessage = "✓ copied", children }) => {
  const divRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation(); // stop propagation here
  };

  useEffect(() => {
    const clipboard = new ClipboardJS(divRef.current, {
      text: () => text
    });

    clipboard.on('success', function (e) {
      console.info('Action:', e.action);
      console.info('Text:', e.text);
      console.info('Trigger:', e.trigger);

      setCopySuccess(true);

      // Reset copySuccess state after 3 seconds
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);

      e.clearSelection();
    });

    // Clean up on unmount
    return () => {
      clipboard.destroy();
    };
  }, [text]);

  return (
    <div ref={divRef} className="copy-to-clipboard" onClick={handleClick}>
      {copySuccess
        ? <span style={{ color: 'blue', fontWeight: 'bold' }}>{successMessage}</span>
        : children}
    </div>
  );
};

export default CopyToClipboardDiv;
