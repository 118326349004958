// updateUser.js

import { doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { db } from '../../firebase'
// import { getStorage, ref, getDownloadURL } from "firebase/storage"

const updateUser = ({ userId, updatedFields }) => {
  const docRef = doc(db, 'users', userId)
  updateDoc(docRef, updatedFields);
}

export default updateUser



// LATER: update date
// const docRef = doc(db, 'mycollection', 'mydocument');
// const now = new Date();

// updateDoc(docRef, {
//   updatedAt: now.toISOString()
// });



