export const newUserSetupMap = {
  English: {
    keep: "🟢 Keep!",
    welcome: "Welcome",
    toss: "❌ Toss",
    begin: "Begin!",
    countryFlagEmoji: "🇺🇸",
    part_1: 'Above is your randomly generated avatar. Click on it to generate a new one, and stop when you find one you like! This is just for fun and you can change it anytime in your profile page.',
    part_2: 'English is the default language for PictureBooks, but you can pick anyone of 25 languages, and this can change anytime.',
    part_3: 'You have 5 complimentary free stories. Enjoy!'
  },
  Spanish: {
    keep: "🟢 ¡Mantener!",
    toss: "❌ Descartar",
    begin: "¡Empezar!",
    welcome: "Bienvenido",
    countryFlagEmoji: "🇪🇸",
    part_1: 'Arriba está tu avatar generado aleatoriamente. Haz clic en él para generar uno nuevo y detente cuando encuentres uno que te guste. Esto es solo por diversión y puedes cambiarlo en cualquier momento en la página de tu perfil.',
    part_2: 'El inglés es el idioma predeterminado para PictureBooks, pero puedes elegir cualquiera de los 25 idiomas, y esto puede cambiar en cualquier momento.',
    part_3: 'Tienes 5 historias gratuitas de cortesía. ¡Disfruta!'
  },
  French: {
    keep: "🟢 Garder!",
    toss: "❌ Jeter",
    begin: "Commencer!",
    welcome: "Bienvenue",
    countryFlagEmoji: "🇫🇷",
    part_1: "Ci-dessus se trouve votre avatar généré aléatoirement. Cliquez dessus pour en générer un nouveau, et arrêtez-vous lorsque vous en trouvez un qui vous plaît! C'est juste pour le plaisir et vous pouvez le changer à tout moment sur votre page de profil.",
    part_2: "L'anglais est la langue par défaut de PictureBooks, mais vous pouvez choisir l'une des 25 langues, et cela peut changer à tout moment.",
    part_3: "Vous avez 5 histoires gratuites en complément. Profitez-en!"
  },
  German: {
    keep: "🟢 Behalten!",
    toss: "❌ Wegwerfen",
    begin: "Anfangen!",
    welcome: "Willkommen",
    countryFlagEmoji: "🇩🇪",
    part_1: "Oben sehen Sie Ihren zufällig generierten Avatar. Klicken Sie darauf, um einen neuen zu generieren, und hören Sie auf, wenn Sie einen finden, den Sie mögen! Das ist nur zum Spaß und Sie können es jederzeit auf Ihrer Profilseite ändern.",
    part_2: "Englisch ist die Standardsprache für PictureBooks, aber Sie können eine von 25 Sprachen auswählen, und das kann jederzeit geändert werden.",
    part_3: "Sie haben 5 kostenlose Geschichten zur Verfügung. Viel Spaß!"
  },
  // part 2
  Italian: {
    keep: "🟢 Conserva!",
    toss: "❌ Scarta",
    begin: "Inizia!",
    welcome: "Benvenuto",
    countryFlagEmoji: "🇮🇹",
    part_1: "Sopra è presente il tuo avatar generato casualmente. Clicca sopra per generarne uno nuovo, e fermati quando trovi quello che ti piace! È solo per divertimento e puoi cambiarlo in qualsiasi momento nella tua pagina del profilo.",
    part_2: "L'inglese è la lingua predefinita per PictureBooks, ma puoi scegliere tra 25 lingue diverse, e puoi cambiarla in qualsiasi momento.",
    part_3: "Hai 5 storie gratuite in omaggio. Divertiti!"
  },
  Portuguese: {
    keep: "🟢 Manter!",
    toss: "❌ Descartar",
    begin: "Começar!",
    welcome: "Bem-vindo",
    countryFlagEmoji: "🇵🇹",
    part_1: "Acima está o seu avatar gerado aleatoriamente. Clique nele para gerar um novo, e pare quando encontrar um que goste! Isto é apenas por diversão e você pode mudá-lo a qualquer momento na página do seu perfil.",
    part_2: "O inglês é o idioma padrão para PictureBooks, mas você pode escolher qualquer um dos 25 idiomas, e isso pode ser alterado a qualquer momento.",
    part_3: "Você tem 5 histórias gratuitas como cortesia. Aproveite!"
  },
  Tagalog: {
    keep: "🟢 Panatilihin!",
    toss: "❌ Itapon",
    begin: "Simulan!",
    welcome: "Maligayang pagdating",
    countryFlagEmoji: "🇵🇭",
    part_1: "Sa itaas ay ang iyong random na nabuong avatar. I-click ito para gumawa ng bago, at itigil kapag natagpuan mo ang isa na gusto mo! Para lang ito sa kasiyahan at maaari mong baguhin ito anumang oras sa iyong pahina ng profile.",
    part_2: "Ang Ingles ay ang default na wika para sa PictureBooks, ngunit maaari kang pumili ng alinman sa 25 mga wika, at maaari itong baguhin anumang oras.",
    part_3: "Mayroon kang 5 libreng kwento bilang kagandahang-loob. Enjoy!"
  },
  Japanese: {
    keep: "🟢 保持!",
    toss: "❌ 捨てる",
    begin: "始める!",
    welcome: "ようこそ",
    countryFlagEmoji: "🇯🇵",
    part_1: "上にはランダムに生成されたあなたのアバターが表示されています。新しいものを生成するにはクリックし、気に入ったものが見つかるまでやめてください！これは単なる楽しみのためで、プロフィールページでいつでも変更できます。",
    part_2: "英語はPictureBooksのデフォルトの言語ですが、25の言語から選択でき、これはいつでも変更できます。",
    part_3: "お楽しみいただける5つの無料のストーリーがあります。"
  },
  Hindi: {
    keep: "🟢 रखें!",
    toss: "❌ फेंको",
    begin: "आरंभ करें!",
    welcome: "स्वागत है",
    countryFlagEmoji: "🇮🇳",
    part_1: "ऊपर आपका यादृच्छिक रूप से उत्पन्न अवतार है। इसे क्लिक करें ताकि एक नया उत्पन्न हो, और जब आपको एक पसंद हो, तब तक रुकें! यह सिर्फ मजे के लिए है और आप अपने प्रोफ़ाइल पृष्ठ पर कभी भी इसे बदल सकते हैं।",
    part_2: "अंग्रेजी PictureBooks की डिफ़ॉल्ट भाषा है, लेकिन आप 25 भाषाओं में से किसी एक को चुन सकते हैं, और यह कभी भी बदल सकता है।",
    part_3: "आपके पास 5 सौजन्य से मुफ्त कहानियां हैं। आनंद लें!"
  },
  Greek: {
    keep: "🟢 Διατηρήστε!",
    toss: "❌ Πετάξτε",
    begin: "Ξεκινήστε!",
    welcome: "Καλωσήρθατε",
    countryFlagEmoji: "🇬🇷",
    part_1: "Παραπάνω βλέπετε το τυχαία δημιουργημένο avatar σας. Κάντε κλικ πάνω του για να δημιουργήσετε ένα καινούργιο, και σταματήστε όταν βρείτε ένα που σας αρέσει! Αυτό είναι απλά για διασκέδαση και μπορείτε να το αλλάξετε οποιαδήποτε στιγμή στη σελίδα του προφίλ σας.",
    part_2: "Τα Αγγλικά είναι η προεπιλεγμένη γλώσσα για τα PictureBooks, αλλά μπορείτε να επιλέξετε οποιαδήποτε από τις 25 γλώσσες, και αυτό μπορεί να αλλάξει οποιαδήποτε στιγμή.",
    part_3: "Έχετε 5 δωρεάν ιστορίες ως ευγενική χορηγία. Καλή διασκέδαση!"
  }
};


export const navbarMap = {
  English: {
    create: "Create",
    bookshelf: "Bookshelf",
    adventure: "Adventures",
    credits: "Credits",

    profile: "Profile",
    about: "About",
    logout: "Logout",

    english: "English",
    spanish: "Spanish",
    french: "French",
    german: "German",
  },

  Spanish: {
    create: "Crear",
    bookshelf: "Biblioteca",
    adventure: "Aventuras",
    credits: "Créditos",

    profile: "Perfil",
    about: "Acerca de",
    logout: "Cerrar sesión",

    english: "Inglés",
    spanish: "Español",
    french: "Francés",
    german: "Alemán",
  },


  French: {
    create: "Créer",
    bookshelf: "Bibliothèque",
    adventure: "Aventures",
    credits: "Crédits",

    profile: "Profil",
    about: "À propos",
    logout: "Se déconnecter",

    english: "Anglais",
    spanish: "Espagnol",
    french: "Français",
    german: "Allemand",
  },


  German: {
    create: "Erstellen",
    bookshelf: "Bücherregal",
    adventure: "Abenteuer",
    credits: "Guthaben",

    profile: "Profil",
    about: "Über",
    logout: "Abmelden",


    english: "Englisch",
    spanish: "Spanisch",
    french: "Französisch",
    german: "Deutsch",
  },
  // part 2 

  Italian: {
    create: "Crea",
    bookshelf: "Libreria",
    adventure: "Avventure",
    credits: "Crediti",

    profile: "Profilo",
    about: "Informazioni",
    logout: "Esci",

    english: "Inglese",
    spanish: "Spagnolo",
    french: "Francese",
    german: "Tedesco"
  },

  Portuguese: {
    create: "Criar",
    bookshelf: "Estante",
    adventure: "Aventuras",
    credits: "Créditos",

    profile: "Perfil",
    about: "Sobre",
    logout: "Sair",

    english: "Inglês",
    spanish: "Espanhol",
    french: "Francês",
    german: "Alemão"
  },

  Tagalog: {
    create: "Lumikha",
    bookshelf: "Bookshelf",
    adventure: "Pakikipagsapantaha",
    credits: "Credits",

    profile: "Profile",
    about: "Tungkol",
    logout: "Mag-logout",

    english: "Ingles",
    spanish: "Espanyol",
    french: "Pranses",
    german: "Aleman"
  },

  Japanese: {
    create: "作成",
    bookshelf: "本棚",
    adventure: "冒険",
    credits: "クレジット",

    profile: "プロフィール",
    about: "約",
    logout: "ログアウト",

    english: "英語",
    spanish: "スペイン語",
    french: "フランス語",
    german: "ドイツ語"
  },

  Hindi: {
    create: "बनाएँ",
    bookshelf: "किताबों की अलमारी",
    adventure: "साहसिक",
    credits: "क्रेडिट",

    profile: "प्रोफ़ाइल",
    about: "के बारे में",
    logout: "लॉगआउट",

    english: "अंग्रेज़ी",
    spanish: "स्पेनिश",
    french: "फ़्रेंच",
    german: "जर्मन"
  },

  Greek: {
    create: "Δημιουργία",
    bookshelf: "Βιβλιοθήκη",
    adventure: "Περιπέτειες",
    credits: "Πιστώσεις",

    profile: "Προφίλ",
    about: "Σχετικά",
    logout: "Αποσύνδεση",

    english: "Αγγλικά",
    spanish: "Ισπανικά",
    french: "Γαλλικά",
    german: "Γερμανικά"
  }
};


export const createStoryMap = {
  English: {
    create: "Create",

    writingPromptDirections: "Write a story about",
    illustrationPrompt: "Illustration style",

    placeholder: "a character who... in a place that... when suddenly... with a theme of...",

    wordBank: "Word Bank",
    placeholderWordBank: "Enter up to 5 words",

    chooseIllustrationStyle: "Choose art style",

    serversBusyErrorMessage: "Open AI servers are experiencing issues right now. Try again later.",
    continue: "Continue",
    unknownError: "Hmmmm, we have had an error:",

    simpleInterface: "Simple Interface",

    advancedInterface: "Advanced Interface",
    age_4_5: "For children 4 to 5 years old.",
    age_6_8: "For children 6 to 8 years old.",
    age_9_11: "For children 9 to 11 years old.",
    age_12_above: "For children 12 and above.",

    modalCancelTitle: "Confirm Cancel",
    modalCancelMessage: "Are you sure you want to cancel this story?",

    yes: "Yes",
    no: "No"
  },

  Spanish: {
    create: "Crear",
    writingPromptDirections: "Escribe una historia sobre",
    illustrationPrompt: "Estilo de ilustración",
    placeholder: "un personaje que... en un lugar que... cuando de repente... con un tema de...",

    wordBank: "Banco de Palabras",
    placeholderWordBank: "Introduce hasta 5 palabras",

    chooseIllustrationStyle: "Elige estilo de arte",

    serversBusyErrorMessage: "Los servidores de Open AI están experimentando problemas en este momento. Intente nuevamente más tarde.",
    continue: "Continuar",
    unknownError: "Hmmmm, hemos tenido un error:",

    simpleInterface: "Interfaz Simple",

    advancedInterface: "Interfaz Avanzada",
    age_4_5: "Para niños de 4 a 5 años.",
    age_6_8: "Para niños de 6 a 8 años.",
    age_9_11: "Para niños de 9 a 11 años.",
    age_12_above: "Para niños de 12 años en adelante.",
    simpleInterface: "Interfaz Simple",


    modalCancelTitle: "Confirmar Cancelación",
    modalCancelMessage: "¿Estás seguro de que quieres cancelar esta historia?",

    yes: "Sí",
    no: "No"
  },

  French: {
    create: "Créer",
    writingPromptDirections: "Écrivez une histoire sur",
    illustrationPrompt: "Style d'illustration",
    placeholder: "un personnage qui... dans un lieu qui... quand soudain... avec un thème de...",

    wordBank: "Banque de Mots",
    placeholderWordBank: "Entrez jusqu'à 5 mots",

    chooseIllustrationStyle: "Choisissez le style d'art",

    serversBusyErrorMessage: "Les serveurs Open AI rencontrent des problèmes en ce moment. Réessayez plus tard.",
    continue: "Continuer",
    unknownError: "Hmmmm, nous avons eu une erreur:",

    simpleInterface: "Interface Simple",

    advancedInterface: "Interface Avancée",
    age_4_5: "Pour les enfants de 4 à 5 ans.",
    age_6_8: "Pour les enfants de 6 à 8 ans.",
    age_9_11: "Pour les enfants de 9 à 11 ans.",
    age_12_above: "Pour les enfants de 12 ans et plus.",


    modalCancelTitle: "Confirmer l'Annulation",
    modalCancelMessage: "Êtes-vous sûr de vouloir annuler cette histoire?",
    yes: "Oui",
    no: "Non"
  },

  German: {
    create: "Erstellen",
    writingPromptDirections: "Schreibe eine Geschichte über",
    illustrationPrompt: "Illustrationsstil",
    placeholder: "einem Charakter, der... an einem Ort, der... als plötzlich... mit einem Thema von...",

    wordBank: "Wortbank",
    placeholderWordBank: "Geben Sie bis zu 5 Wörter ein",

    chooseIllustrationStyle: "Wählen Sie den Kunststil",

    serversBusyErrorMessage: "Open AI-Server haben derzeit Probleme. Versuchen Sie es später erneut.",
    continue: "Fortfahren",
    unknownError: "Hmmmm, wir hatten einen Fehler:",

    simpleInterface: "Einfache Schnittstelle",

    advancedInterface: "Erweiterte Schnittstelle",
    age_4_5: "Für Kinder von 4 bis 5 Jahren.",
    age_6_8: "Für Kinder von 6 bis 8 Jahren.",
    age_9_11: "Für Kinder von 9 bis 11 Jahren.",
    age_12_above: "Für Kinder ab 12 Jahren.",

    modalCancelTitle: "Abbrechen Bestätigen",
    modalCancelMessage: "Sind Sie sicher, dass Sie diese Geschichte abbrechen möchten?",

    yes: "Ja",
    no: "Nein"
  },
  // second part

  Italian: {
    create: "Crea",
    writingPromptDirections: "Scrivi una storia su",
    illustrationPrompt: "Stile di illustrazione",
    placeholder: "un personaggio che... in un luogo che... quando improvvisamente... con un tema di...",

    wordBank: "Banca delle Parole",
    placeholderWordBank: "Inserisci fino a 5 parole",

    chooseIllustrationStyle: "Scegli lo stile artistico",

    serversBusyErrorMessage: "I server di Open AI stanno riscontrando problemi al momento. Riprova più tardi.",
    continue: "Continua",
    unknownError: "Hmmmm, abbiamo riscontrato un errore:",

    simpleInterface: "Interfaccia Semplice",

    advancedInterface: "Interfaccia Avanzata",
    age_4_5: "Per bambini dai 4 ai 5 anni.",
    age_6_8: "Per bambini dai 6 agli 8 anni.",
    age_9_11: "Per bambini dai 9 agli 11 anni.",
    age_12_above: "Per bambini dai 12 anni in su.",

    modalCancelTitle: "Conferma Annulla",
    modalCancelMessage: "Sei sicuro di voler annullare questa storia?",

    yes: "Sì",
    no: "No"
  },

  Portuguese: {
    create: "Criar",
    writingPromptDirections: "Escreva uma história sobre",
    illustrationPrompt: "Estilo de ilustração",
    placeholder: "um personagem que... em um lugar que... quando de repente... com um tema de...",

    wordBank: "Banco de Palavras",
    placeholderWordBank: "Digite até 5 palavras",

    chooseIllustrationStyle: "Escolha o estilo artístico",

    serversBusyErrorMessage: "Os servidores da Open AI estão enfrentando problemas agora. Tente novamente mais tarde.",
    continue: "Continuar",
    unknownError: "Hmmmm, tivemos um erro:",

    simpleInterface: "Interface Simples",

    advancedInterface: "Interface Avançada",
    age_4_5: "Para crianças de 4 a 5 anos.",
    age_6_8: "Para crianças de 6 a 8 anos.",
    age_9_11: "Para crianças de 9 a 11 anos.",
    age_12_above: "Para crianças de 12 anos ou mais.",

    modalCancelTitle: "Confirmar Cancelamento",
    modalCancelMessage: "Tem certeza de que deseja cancelar esta história?",

    yes: "Sim",
    no: "Não"
  },

  Tagalog: {
    create: "Lumikha",
    writingPromptDirections: "Sumulat ng kwento tungkol sa",
    illustrationPrompt: "Estilo ng ilustrasyon",
    placeholder: "isang karakter na... sa isang lugar na... nang bigla... na may temang...",

    wordBank: "Bangko ng Salita",
    placeholderWordBank: "Maglagay ng hanggang sa 5 salita",

    chooseIllustrationStyle: "Pumili ng estilo ng sining",

    serversBusyErrorMessage: "Ang mga server ng Open AI ay may mga isyu ngayon. Subukan ulit mamaya.",
    continue: "Magpatuloy",
    unknownError: "Hmmmm, mayroon kaming error:",

    simpleInterface: "Simpleng Interface",

    advancedInterface: "Advanced na Interface",
    age_4_5: "Para sa mga bata na 4 hanggang 5 taong gulang.",
    age_6_8: "Para sa mga bata na 6 hanggang 8 taong gulang.",
    age_9_11: "Para sa mga bata na 9 hanggang 11 taong gulang.",
    age_12_above: "Para sa mga bata na 12 taong gulang pataas.",

    modalCancelTitle: "Kumpirmahin ang Pagkansela",
    modalCancelMessage: "Sigurado ka bang gusto mong kanselahin ang kwentong ito?",

    yes: "Oo",
    no: "Hindi"
  },

  Japanese: {
    create: "作成",
    writingPromptDirections: "についての物語を書く",
    illustrationPrompt: "イラストのスタイル",
    placeholder: "キャラクターは...その場所は...突然...テーマは...",

    wordBank: "単語バンク",
    placeholderWordBank: "最大5つの単語を入力",

    chooseIllustrationStyle: "アートスタイルを選択",

    serversBusyErrorMessage: "Open AIのサーバーに現在問題が発生しています。後でもう一度お試しください。",
    continue: "続ける",
    unknownError: "うーん、エラーが発生しました:",

    simpleInterface: "シンプルなインターフェース",

    advancedInterface: "アドバンスドインターフェース",
    age_4_5: "4歳から5歳の子供向け。",
    age_6_8: "6歳から8歳の子供向け。",
    age_9_11: "9歳から11歳の子供向け。",
    age_12_above: "12歳以上の子供向け。",

    modalCancelTitle: "キャンセル確認",
    modalCancelMessage: "このストーリーをキャンセルしてもよろしいですか？",

    yes: "はい",
    no: "いいえ"
  },

  Hindi: {
    create: "बनाएँ",
    writingPromptDirections: "के बारे में एक कहानी लिखो",
    illustrationPrompt: "चित्रण शैली",
    placeholder: "एक पात्र जो... एक स्थान पर जो... अचानक... एक विषय पर...",

    wordBank: "शब्द बैंक",
    placeholderWordBank: "5 शब्द तक दर्ज करें",

    chooseIllustrationStyle: "चित्रकला शैली चुनें",

    serversBusyErrorMessage: "Open AI सर्वर वर्तमान में समस्याएँ अनुभव कर रहे हैं। बाद में पुनः प्रयास करें।",
    continue: "जारी रखें",
    unknownError: "हमें एक त्रुटि मिली है:",

    simpleInterface: "साधारण इंटरफ़ेस",

    advancedInterface: "उन्नत इंटरफ़ेस",
    age_4_5: "4 से 5 वर्षीय बच्चों के लिए।",
    age_6_8: "6 से 8 वर्षीय बच्चों के लिए।",
    age_9_11: "9 से 11 वर्षीय बच्चों के लिए।",
    age_12_above: "12 वर्ष और ऊपर के बच्चों के लिए।",

    modalCancelTitle: "रद्द की पुष्टि",
    modalCancelMessage: "क्या आप वाकई इस कहानी को रद्द करना चाहते हैं?",

    yes: "हाँ",
    no: "नहीं"
  },

  Greek: {
    create: "Δημιουργία",
    writingPromptDirections: "Γράψτε μια ιστορία για",
    illustrationPrompt: "Στυλ εικονογράφησης",
    placeholder: "ένας χαρακτήρας που... σε έναν τόπο που... όταν ξαφνικά... με ένα θέμα του...",

    wordBank: "Τράπεζα Λέξεων",
    placeholderWordBank: "Εισάγετε έως και 5 λέξεις",

    chooseIllustrationStyle: "Επιλέξτε στυλ καλλιτεχνικής",

    serversBusyErrorMessage: "Οι διακομιστές Open AI αντιμετωπίζουν προβλήματα αυτή τη στιγμή. Δοκιμάστε ξανά αργότερα.",
    continue: "Συνέχεια",
    unknownError: "Χμμμ, είχαμε ένα σφάλμα:",

    simpleInterface: "Απλή Διεπαφή",

    advancedInterface: "Προηγμένη Διεπαφή",
    age_4_5: "Για παιδιά 4 εώς 5 ετών.",
    age_6_8: "Για παιδιά 6 εώς 8 ετών.",
    age_9_11: "Για παιδιά 9 εώς 11 ετών.",
    age_12_above: "Για παιδιά 12 ετών και άνω.",

    modalCancelTitle: "Επιβεβαίωση Ακύρωσης",
    modalCancelMessage: "Είστε σίγουροι ότι θέλετε να ακυρώσετε αυτή την ιστορία;",

    yes: "Ναι",
    no: "Όχι"
  }
};





export const homeMap = {
  English: {
    stories: "Stories"
  },

  Spanish: {
    stories: "Historias",
  },

  French: {
    stories: "Histoires",
  },

  German: {
    stories: "Geschichten",
  },

  // part 2
  Italian: {
    stories: "Storie"
  },

  Portuguese: {
    stories: "Histórias"
  },

  Tagalog: {
    stories: "Kwento"
  },

  Japanese: {
    stories: "物語"
  },

  Hindi: {
    stories: "कहानियाँ"
  },

  Greek: {
    stories: "Ιστορίες"
  }

};

export const bookshelfMap = {
  English: {
    bookshelf: "Bookshelf",
    showAll: "Show All",
    show: "Show"
  },

  Spanish: {
    bookshelf: "Biblioteca",
    showAll: "Mostrar todo",
    show: "Mostrar",
  },

  French: {
    bookshelf: "Bibliothèque",
    showAll: "Montrer tout",
    show: "Montrer",
  },

  German: {
    bookshelf: "Bücherregal",
    showAll: "Alle anzeigen",
    show: "Alle",
  },

  // Part 2

  Italian: {
    bookshelf: "Libreria",
    showAll: "Mostra tutto",
    show: "Mostra"
  },

  Portuguese: {
    bookshelf: "Estante",
    showAll: "Mostrar tudo",
    show: "Mostrar"
  },

  Tagalog: {
    bookshelf: "Bookshelf",
    showAll: "Ipakita lahat",
    show: "Ipakita"
  },

  Japanese: {
    bookshelf: "本棚",
    showAll: "すべて表示",
    show: "表示"
  },

  Hindi: {
    bookshelf: "किताबों की अलमारी",
    showAll: "सभी दिखाएं",
    show: "दिखाएं"
  },

  Greek: {
    bookshelf: "Βιβλιοθήκη",
    showAll: "Δείξε τα όλα",
    show: "Δείξε"
  }
};

export const aboutMap = {


  English: {
    commonQuestions: "Common Questions",
    whatIsAI_heading: "What is AI?",
    whatIsAI_text: "Artificial Intelligence (AI) is the simulation of human intelligence in machines that are programmed to think and learn like humans. It encompasses a wide range of technologies and techniques, such as machine learning, natural language processing, and robotics, that enable computers to perceive, reason, and act autonomously.",
    isThisChatGpt_heading: "Is this ChatGPT?",
    isThisChatGpt_text: "No. The words and images generated by the PictureBooks app is powered by several different models, one of which is indeed OpenAI's GPT-4 model. This is not a chatbot, and all content is filtered to be safe for children. Although it shares some similarities with ChatGPT on a technical level, it is tailored specifically for use by children and educators, with a different set of priorities and goals in mind.",
    isItEthical_heading: "Is using AI to write stories ethical?",
    isItEthical_text: "The world is changing rapidly, and education will change with it. AI a tool that can be used to augment the teaching process, not than replace it. Some educators find AI as an inspiration and helpful tool to create interactive and personalized learning experiences, but ultimately the final decision on how to teach is always in the hands of the teacher. Additionally, as AI technology continues to advance, it is also possible for it to create new opportunities and markets for human educators, such as allowing them to more easily tailor their teaching to individual student needs and facilitate distance learning.",
    canISell_heading: "Can I sell these stories as my own?",
    canISell_text: "No. Please note that stories written by Picture Book AI are intended to serve as teaching tools to inspire reading and can be tailored to the interests of individual students. Nothing can compare to the read alouds written by great authors everywhere. We kindly ask that you respect purpose of these books and also support hard-working authors everywhere by buying their books! Feel free to share your favorite Picture Books, but they can not be packaged up and sold. Having said that, if the results inspire you to write your own book, well that's a... different story."
  },

  Spanish: {
    commonQuestions: "Preguntas Comunes",
    whatIsAI_heading: "¿Qué es la IA?",
    whatIsAI_text: "La inteligencia artificial (IA) es la simulación de la inteligencia humana en máquinas programadas para pensar y aprender como humanos. Engloba una amplia gama de tecnologías y técnicas, como el aprendizaje automático, el procesamiento del lenguaje natural y la robótica, que permiten a las computadoras percibir, razonar y actuar de forma autónoma.",
    isThisChatGpt_heading: "¿Es este ChatGPT?",
    isThisChatGpt_text: "No. Las palabras e imágenes generadas por la aplicación PictureBooks están alimentadas por varios modelos diferentes, uno de los cuales es efectivamente el modelo GPT-4 de OpenAI. Esto no es un chatbot y todo el contenido está filtrado para ser seguro para los niños. Aunque comparte algunas similitudes con ChatGPT a nivel técnico, está diseñado específicamente para ser utilizado por niños y educadores, con un conjunto diferente de prioridades y objetivos en mente.",
    isItEthical_heading: "¿Es ético usar IA para escribir historias?",
    isItEthical_text: "El mundo está cambiando rápidamente y la educación cambiará con él. La IA es una herramienta que se puede utilizar para mejorar el proceso de enseñanza, no para reemplazarlo. Algunos educadores encuentran la IA como una inspiración y una herramienta útil para crear experiencias de aprendizaje interactivas y personalizadas, pero en última instancia, la decisión final sobre cómo enseñar siempre está en manos del maestro. Además, a medida que la tecnología de la IA continúa avanzando, también es posible que cree nuevas oportunidades y mercados para los educadores humanos.",
    canISell_heading: "¿Puedo vender estas historias como propias?",
    canISell_text: "No. Tenga en cuenta que las historias escritas por Picture Book AI tienen la intención de servir como herramientas de enseñanza para inspirar la lectura y se pueden adaptar a los intereses de los estudiantes individuales. Nada puede compararse con los libros leídos en voz alta escritos por grandes autores en todas partes. Le pedimos amablemente que respete el propósito de estos libros y también apoye a los autores trabajadores en todas partes comprando sus libros."
  },

  French: {
    commonQuestions: "Questions Fréquentes",
    whatIsAI_heading: "Qu'est-ce que l'IA?",
    whatIsAI_text: "L'intelligence artificielle (IA) est la simulation de l'intelligence humaine dans des machines programmées pour penser et apprendre comme des humains. Elle englobe un large éventail de technologies et de techniques, telles que l'apprentissage automatique, le traitement du langage naturel et la robotique, qui permettent aux ordinateurs de percevoir, raisonner et agir de manière autonome.",
    isThisChatGpt_heading: "Est-ce ChatGPT?",
    isThisChatGpt_text: "Non. Les mots et les images générés par l'application PictureBooks sont alimentés par plusieurs modèles différents, dont l'un est effectivement le modèle GPT-4 d'OpenAI. Ce n'est pas un chatbot, et tout le contenu est filtré pour être sûr pour les enfants. Bien qu'il partage certaines similitudes avec ChatGPT sur le plan technique, il est spécifiquement conçu pour être utilisé par les enfants et les éducateurs, avec un ensemble différent de priorités et d'objectifs en tête.",
    isItEthical_heading: "Est-il éthique d'utiliser l'IA pour écrire des histoires?",
    isItEthical_text: "Le monde change rapidement et l'éducation changera avec lui. L'IA est un outil qui peut être utilisé pour augmenter le processus d'enseignement, plutôt que de le remplacer. Certains éducateurs trouvent l'IA comme une inspiration et un outil utile pour créer des expériences d'apprentissage interactives et personnalisées, mais en fin de compte, la décision finale sur la façon d'enseigner est toujours entre les mains de l'enseignant.",
    canISell_heading: "Puis-je vendre ces histoires comme les miennes?",
    canISell_text: "Non. Veuillez noter que les histoires écrites par Picture Book AI sont destinées à servir d'outils pédagogiques pour inspirer la lecture et peuvent être adaptées aux intérêts des élèves individuels. Rien ne peut se comparer aux livres lus à haute voix écrits par de grands auteurs partout. Nous vous demandons gentiment de respecter le but de ces livres et de soutenir également les auteurs qui travaillent dur en achetant leurs livres."
  },


  German: {
    commonQuestions: "Häufige Fragen",
    whatIsAI_heading: "Was ist KI?",
    whatIsAI_text: "Künstliche Intelligenz (KI) ist die Simulation menschlicher Intelligenz in Maschinen, die programmiert sind, um zu denken und zu lernen wie Menschen. Es umfasst eine breite Palette von Technologien und Techniken, wie maschinelles Lernen, natürliche Sprachverarbeitung und Robotik, die es Computern ermöglichen, wahrzunehmen, zu schlussfolgern und autonom zu handeln.",
    isThisChatGpt_heading: "Ist das ChatGPT?",
    isThisChatGpt_text: "Nein. Die Wörter und Bilder, die von der PictureBooks-App generiert werden, werden von mehreren verschiedenen Modellen angetrieben, von denen eines tatsächlich das GPT-4-Modell von OpenAI ist. Dies ist kein Chatbot und alle Inhalte sind gefiltert, um für Kinder sicher zu sein. Obwohl es auf technischer Ebene einige Ähnlichkeiten mit ChatGPT teilt, ist es speziell für die Verwendung durch Kinder und Pädagogen zugeschnitten, mit einem anderen Satz von Prioritäten und Zielen im Hinterkopf.",
    isItEthical_heading: "Ist es ethisch, KI zum Schreiben von Geschichten zu verwenden?",
    isItEthical_text: "Die Welt verändert sich rasch und die Bildung wird sich mit ihr verändern. KI ist ein Werkzeug, das dazu verwendet werden kann, den Unterrichtsprozess zu ergänzen, nicht ihn zu ersetzen. Einige Pädagogen finden die KI als eine Inspiration und hilfreiches Werkzeug, um interaktive und personalisierte Lernerfahrungen zu schaffen, aber letztendlich liegt die endgültige Entscheidung, wie unterrichtet wird, immer in den Händen des Lehrers.",
    canISell_heading: "Kann ich diese Geschichten als meine eigenen verkaufen?",
    canISell_text: "Nein. Bitte beachten Sie, dass Geschichten, die von Picture Book AI geschrieben wurden, als Unterrichtsmittel dienen sollen, um das Lesen zu fördern und auf die Interessen einzelner Schüler zugeschnitten werden können. Nichts kann sich mit den laut vorgelesenen Büchern von großen Autoren überall vergleichen. Wir bitten Sie höflich, den Zweck dieser Bücher zu respektieren und auch die hart arbeitenden Autoren überall zu unterstützen, indem Sie ihre Bücher kaufen."
  },

  // Part 2

  Italian: {
    commonQuestions: "Domande Comuni",
    whatIsAI_heading: "Cos'è l'IA?",
    whatIsAI_text: "L'Intelligenza Artificiale (IA) è la simulazione dell'intelligenza umana in macchine programmate per pensare e apprendere come gli umani. Comprende una vasta gamma di tecnologie e tecniche, come l'apprendimento automatico, l'elaborazione del linguaggio naturale e la robotica, che permettono ai computer di percepire, ragionare e agire autonomamente.",
    isThisChatGpt_heading: "È questo ChatGPT?",
    isThisChatGpt_text: "No. Le parole e le immagini generate dall'app PictureBooks sono alimentate da diversi modelli, uno dei quali è effettivamente il modello GPT-4 di OpenAI. Questo non è un chatbot, e tutti i contenuti sono filtrati per essere sicuri per i bambini. Sebbene condivida alcune somiglianze con ChatGPT a livello tecnico, è specificamente progettato per essere utilizzato da bambini ed educatori, con un diverso insieme di priorità e obiettivi in mente.",
    isItEthical_heading: "È etico usare l'IA per scrivere storie?",
    isItEthical_text: "Il mondo sta cambiando rapidamente, e l'educazione cambierà con esso. L'IA è uno strumento che può essere utilizzato per aumentare il processo di insegnamento, non per sostituirlo. Alcuni educatori trovano l'IA un'ispirazione e uno strumento utile per creare esperienze di apprendimento interattive e personalizzate, ma alla fine la decisione finale su come insegnare è sempre nelle mani dell'insegnante.",
    canISell_heading: "Posso vendere queste storie come mie?",
    canISell_text: "No. Si prega di notare che le storie scritte da Picture Book AI sono destinate a servire come strumenti didattici per ispirare la lettura e possono essere adattate agli interessi degli studenti individuali. Niente può essere paragonato ai libri letti ad alta voce scritti da grandi autori ovunque. Chiediamo gentilmente di rispettare lo scopo di questi libri e di sostenere anche gli autori che lavorano duramente ovunque acquistando i loro libri."
  },

  Portuguese: {
    commonQuestions: "Perguntas Comuns",
    whatIsAI_heading: "O que é IA?",
    whatIsAI_text: "Inteligência Artificial (IA) é a simulação de inteligência humana em máquinas programadas para pensar e aprender como humanos. Abrange uma ampla gama de tecnologias e técnicas, como aprendizado de máquina, processamento de linguagem natural e robótica, que permitem que os computadores percebam, raciocinem e ajam autonomamente.",
    isThisChatGpt_heading: "Isso é ChatGPT?",
    isThisChatGpt_text: "Não. As palavras e imagens geradas pelo aplicativo PictureBooks são alimentadas por vários modelos diferentes, um dos quais é de fato o modelo GPT-4 da OpenAI. Este não é um chatbot, e todo o conteúdo é filtrado para ser seguro para as crianças. Embora compartilhe algumas semelhanças com o ChatGPT em nível técnico, é especificamente projetado para ser usado por crianças e educadores, com um conjunto diferente de prioridades e metas em mente.",
    isItEthical_heading: "É ético usar IA para escrever histórias?",
    isItEthical_text: "O mundo está mudando rapidamente, e a educação mudará com ele. IA é uma ferramenta que pode ser usada para aumentar o processo de ensino, não para substituí-lo. Alguns educadores encontram IA como uma inspiração e uma ferramenta útil para criar experiências de aprendizado interativas e personalizadas, mas, no final, a decisão final sobre como ensinar está sempre nas mãos do professor.",
    canISell_heading: "Posso vender essas histórias como minhas?",
    canISell_text: "Não. Por favor, note que as histórias escritas pelo Picture Book AI são destinadas a servir como ferramentas de ensino para inspirar a leitura e podem ser adaptadas aos interesses dos alunos individuais. Nada pode se comparar aos livros lidos em voz alta escritos por grandes autores em todos os lugares. Pedimos gentilmente que respeite o propósito desses livros e também apoie os autores que trabalham duro em todos os lugares, comprando seus livros."
  },

  Tagalog: {
    commonQuestions: "Karaniwang mga Tanong",
    whatIsAI_heading: "Ano ang AI?",
    whatIsAI_text: "Ang Artificial Intelligence (AI) ay ang simulation ng human intelligence sa mga makina na isinaprograma upang mag-isip at matuto tulad ng mga tao. Ito ay sumasaklaw sa malawak na hanay ng mga teknolohiya at teknik, tulad ng machine learning, natural language processing, at robotics, na nagbibigay-daan sa mga computer na ma-perceive, mag-reason, at kumilos nang autonomous.",
    isThisChatGpt_heading: "Ito ba ay ChatGPT?",
    isThisChatGpt_text: "Hindi. Ang mga salita at mga imahe na binuo ng PictureBooks app ay pinapatakbo ng ilang iba't ibang mga modelo, isa sa mga ito ay tiyak na ang GPT-4 model ng OpenAI. Ito ay hindi isang chatbot, at ang lahat ng nilalaman ay sinasala para maging ligtas para sa mga bata. Bagamat mayroon itong ilang pagkakapareho sa ChatGPT sa teknikal na antas, ito ay tiyak na idinisenyo para sa paggamit ng mga bata at mga guro, na may iba't ibang hanay ng mga prayoridad at mga layunin sa isip.",
    isItEthical_heading: "Etiko ba ang paggamit ng AI para magsulat ng mga kwento?",
    isItEthical_text: "Ang mundo ay mabilis na nagbabago, at ang edukasyon ay magbabago kasama nito. Ang AI ay isang tool na maaaring gamitin para madagdagan ang proseso ng pagtuturo, hindi palitan ito. Ang ilang mga guro ay nakakakita sa AI bilang isang inspirasyon at kapaki-pakinabang na tool upang lumikha ng interactive at personalized na mga karanasan sa pag-aaral, ngunit sa huli, ang huling desisyon sa kung paano magturo ay laging nasa kamay ng guro.",
    canISell_heading: "Maaari ko bang ibenta ang mga kwentong ito bilang akin?",
    canISell_text: "Hindi. Mangyaring tandaan na ang mga kwento na isinulat ng Picture Book AI ay inilaan bilang mga tool sa pagtuturo upang mahikayat ang pagbasa at maaaring i-customize ayon sa mga interes ng bawat mag-aaral. Wala na kayang ihahambing sa mga libro na binabasa ng mga dakilang may-akda sa lahat ng dako. Kami ay magalang na humihiling na igalang mo ang layunin ng mga librong ito at suportahan din ang mga masisipag na may-akda sa lahat ng dako sa pamamagitan ng pagbili ng kanilang mga libro."
  },

  Japanese: {
    commonQuestions: "よくある質問",
    whatIsAI_heading: "AIとは何ですか？",
    whatIsAI_text: "人工知能（AI）は、人間のように考え、学ぶようにプログラムされた機械における人間の知能のシミュレーションです。これには、機械学習、自然言語処理、ロボティクスなど、コンピュータが自律的に知覚、推論、および行動することを可能にする多くの技術と技術が含まれています。",
    isThisChatGpt_heading: "これはChatGPTですか？",
    isThisChatGpt_text: "いいえ。PictureBooksアプリによって生成される言葉と画像は、いくつかの異なるモデルによって動かされており、そのうちの1つは実際にOpenAIのGPT-4モデルです。これはチャットボットではなく、すべてのコンテンツは子供に安全であるようにフィルタリングされています。技術的なレベルではChatGPTといくつかの類似点を共有していますが、子供や教育者のために特別に設計されており、異なる優先順位と目標を念頭に置いています。",
    isItEthical_heading: "AIを使って物語を書くことは倫理的ですか？",
    isItEthical_text: "世界は急速に変わりつつあり、教育もそれに伴って変わるでしょう。 AIは教育プロセスを補完するために使用できるツールであり、それを置き換えるものではありません。一部の教育者はAIをインスピレーションとしており、インタラクティブで個人化された学習体験を作成するための有用なツールと見なしていますが、最終的には教える方法に関する最終決定は常に教師の手にあります。",
    canISell_heading: "これらの話を自分のものとして売ることはできますか？",
    canISell_text: "いいえ。 Picture Book AIによって書かれた物語は、読書を促進し、個々の学生の興味に合わせてカスタマイズできる教育ツールとして提供されることを目的としています。偉大な作家によって書かれた高音で読む本と比べて何もありません。私たちはこれらの本の目的を尊重し、そしてどこでも一生懸命働く作家をサポートすることをお勧めします！"
  },

  Hindi: {
    commonQuestions: "सामान्य प्रश्न",
    whatIsAI_heading: "AI क्या है?",
    whatIsAI_text: "कृत्रिम बुद्धिमत्ता (AI) मानव बुद्धिमत्ता का अनुकरण है जो मशीनों में होता है, जो मानवों की तरह सोचने और सीखने के लिए प्रोग्राम की गई होती हैं। इसमें मशीन लर्निंग, प्राकृतिक भाषा प्रोसेसिंग, और रोबोटिक्स जैसी विभिन्न प्रौद्योगिकियाँ और तकनीकें शामिल होती हैं, जो कंप्यूटरों को स्वतंत्र रूप से सोचने, समझने और कार्य करने की क्षमता प्रदान करती हैं।",
    isThisChatGpt_heading: "क्या यह ChatGPT है?",
    isThisChatGpt_text: "नहीं। PictureBooks ऐप द्वारा उत्पन्न शब्दों और छवियों को कई विभिन्न मॉडलों द्वारा संचालित किया जाता है, में से एक वास्तव में OpenAI का GPT-4 मॉडल है। यह एक चैटबोट नहीं है, और सभी सामग्री बच्चों के लिए सुरक्षित होने के लिए फ़िल्टर की गई है। यद्यपि यह तकनीकी स्तर पर ChatGPT के कुछ समानताएँ शेयर करता है, यह विशेष रूप से बच्चों और शिक्षकों के उपयोग के लिए तैयार किया गया है, जिसमें विभिन्न प्राथमिकताएँ और लक्ष्य होते हैं।",
    isItEthical_heading: "कहानियों को लिखने के लिए AI का उपयोग करना साहित्यिक है?",
    isItEthical_text: "दुनिया तेजी से बदल रही है, और शिक्षा भी इसके साथ बदलेगी। AI एक ऐसा उपकरण है जिसे शिक्षा प्रक्रिया को बढ़ावा देने के लिए उपयोग किया जा सकता है, न कि इसे प्रतिस्थापित करने के लिए। कुछ शिक्षक AI को एक प्रेरणा और इंटरएक्टिव और व्यक्तिगत शिक्षा अनुभव बनाने के लिए उपयोगी उपकरण मानते हैं, लेकिन आखिरकार, कैसे सिखाने के बारे में अंतिम निर्णय हमेशा शिक्षक के हाथों में होता है।",
    canISell_heading: "क्या मैं इन कहानियों को अपने रूप में बेच सकता हूँ?",
    canISell_text: "नहीं। कृपया ध्यान दें कि Picture Book AI द्वारा लिखी गई कहानियां पठन को प्रेरित करने के लिए शिक्षा उपकरणों के रूप में सेवा करने के लिए होती हैं और व्यक्तिगत छात्रों की रुचियों के अनुसार अनुकूलित की जा सकती हैं। बड़े लेखकों द्वारा लिखी गई पढ़ी गई किताबों की तुलना में कुछ भी नहीं है। हम विनम्रता से यह अनुरोध करते हैं कि आप इन किताबों के उद्देश्य का सम्मान करें और वे लेखक भी समर्थन करें जो कहीं भी कड़ी मेहनत करते हैं, उनकी किताबें खरीदकर।"
  },
  Greek: {
    commonQuestions: "Συχνές Ερωτήσεις",
    whatIsAI_heading: "Τι είναι τεχνητή νοημοσύνη;",
    whatIsAI_text: "Η τεχνητή νοημοσύνη (AI) είναι η προσομοίωση της ανθρώπινης νοημοσύνης σε μηχανές που έχουν προγραμματιστεί να σκέφτονται και να μαθαίνουν όπως οι άνθρωποι. Περιλαμβάνει ένα ευρύ φάσμα τεχνολογιών και τεχνικών, όπως τη μηχανική μάθηση, την επεξεργασία φυσικής γλώσσας και τη ρομποτική, που επιτρέπουν στους υπολογιστές να αντιλαμβάνονται, να σκέφτονται και να δρουν αυτόνομα.",
    isThisChatGpt_heading: "Είναι αυτό το ChatGPT;",
    isThisChatGpt_text: "Όχι. Οι λέξεις και οι εικόνες που δημιουργούνται από την εφαρμογή PictureBooks τροφοδοτούνται από διάφορα διαφορετικά μοντέλα, ένα εκ των οποίων είναι πράγματι το μοντέλο GPT-4 της OpenAI. Αυτό δεν είναι ένα chatbot και όλο το περιεχόμενο φιλτράρεται ώστε να είναι ασφαλές για τα παιδιά. Αν και έχει κάποιες ομοιότητες με το ChatGPT σε τεχνικό επίπεδο, είναι προσαρμοσμένο ειδικά για χρήση από παιδιά και εκπαιδευτικούς, με ένα διαφορετικό σύνολο προτεραιοτήτων και στόχων.",
    isItEthical_heading: "Είναι ηθικό το να χρησιμοποιούμε AI για τη συγγραφή ιστοριών;",
    isItEthical_text: "Ο κόσμος αλλάζει γρήγορα και η εκπαίδευση θα αλλάξει μαζί του. Η AI είναι ένα εργαλείο που μπορεί να χρησιμοποιηθεί για να ενισχύσει τη διδασκαλία, όχι για να την αντικαταστήσει. Κάποιοι εκπαιδευτικοί βρίσκουν την AI ως μια έμπνευση και ως ένα χρήσιμο εργαλείο για τη δημιουργία διαδραστικών και προσωποποιημένων εμπειριών μάθησης, αλλά τελικά η τελική απόφαση για το πώς να διδάξουν είναι πάντα στα χέρια του δασκάλου.",
    canISell_heading: "Μπορώ να πουλήσω αυτές τις ιστορίες ως δικές μου;",
    canISell_text: "Όχι. Παρακαλούμε να σημειώσετε ότι οι ιστορίες που γράφονται από το Picture Book AI προορίζονται να λειτουργούν ως εργαλεία διδασκαλίας για την εμπνευση της ανάγνωσης και μπορούν να προσαρμοστούν στα ενδιαφέροντα των μεμονωμένων μαθητών. Τίποτα δεν μπορεί να συγκριθεί με τα αναγνωστικά που έχουν γραφτεί από μεγάλους συγγραφείς παντού. Σας ζητούμε ευγενικά να σεβαστείτε τον σκοπό αυτών των βιβλίων και να υποστηρίξετε επίσης τους σκληρά εργαζόμενους συγγραφείς παντού αγοράζοντας τα βιβλία τους."
  }




};


export const loginMainMap = {
  English: {
    bringingImagination: "Bringing imagination to life with delightfully curated stories for children."
  },

  Spanish: {
    bringingImagination: "Dando vida a la imaginación con historias deliciosamente seleccionadas para niños."
  },

  French: {
    bringingImagination: "Donner vie à l'imagination avec des histoires soigneusement sélectionnées pour les enfants."
  },

  German: {
    bringingImagination: "Die Fantasie mit sorgfältig ausgewählten Geschichten für Kinder zum Leben erwecken."
  },

  // Part 

  Italian: {
    bringingImagination: "Dando vita all'immaginazione con storie deliziosamente selezionate per i bambini."
  },

  Portuguese: {
    bringingImagination: "Trazendo a imaginação à vida com histórias deliciosamente selecionadas para crianças."
  },

  Tagalog: {
    bringingImagination: "Pinamumuhay ang imahinasyon sa pamamagitan ng masarap na piniling kwento para sa mga bata."
  },

  Japanese: {
    bringingImagination: "子供のための厳選されたストーリーで想像力を生き生きとさせています。"
  },

  Hindi: {
    bringingImagination: "बच्चों के लिए ध्यान से चयनित कहानियों के साथ कल्पना को जीवंत करना।"
  },

  Greek: {
    bringingImagination: "Φέρνοντας τη φαντασία στη ζωή με ευχάριστα επιλεγμένες ιστορίες για παιδιά."
  }
};


export const currentStoryDisplayStableDiffMap = {
  English: {
    confirmCancel: "Confirm Cancel",
    confirmCancelMessage: "Are you sure you want to CANCEL this story now? We're just finishing up with the illustration.",
    yes: "Yes",
    no: "No",
    keeper: "Keeper!",
    tryAgain: "Try Again"
  },

  Spanish: {
    confirmCancel: "Confirmar Cancelación",
    confirmCancelMessage: "¿Estás seguro de que quieres CANCELAR esta historia ahora? Estamos terminando con la ilustración.",
    yes: "Sí",
    no: "No",
    keeper: "¡Para guardar!",
    tryAgain: "Intentar de nuevo"
  },

  French: {
    confirmCancel: "Confirmer Annulation",
    confirmCancelMessage: "Êtes-vous sûr de vouloir ANNULER cette histoire maintenant ? Nous terminons juste l'illustration.",
    yes: "Oui",
    no: "Non",
    keeper: "À garder !",
    tryAgain: "Réessayer"
  },

  German: {
    confirmCancel: "Abbrechen Bestätigen",
    confirmCancelMessage: "Sind Sie sicher, dass Sie diese Geschichte jetzt ABBRECHEN möchten? Wir sind gerade dabei, die Illustration abzuschließen.",
    yes: "Ja",
    no: "Nein",
    keeper: "Behalten!",
    tryAgain: "Erneut versuchen"
  },

  // part2
  Italian: {
    confirmCancel: "Conferma Annulla",
    confirmCancelMessage: "Sei sicuro di voler ANNULLARE questa storia ora? Stiamo solo finendo con l'illustrazione.",
    yes: "Sì",
    no: "No",
    keeper: "Da conservare!",
    tryAgain: "Riprova"
  },

  Portuguese: {
    confirmCancel: "Confirmar Cancelamento",
    confirmCancelMessage: "Tem certeza de que deseja CANCELAR esta história agora? Estamos apenas terminando com a ilustração.",
    yes: "Sim",
    no: "Não",
    keeper: "Guardar!",
    tryAgain: "Tente Novamente"
  },

  Tagalog: {
    confirmCancel: "Kumpirmahin ang Pagkansela",
    confirmCancelMessage: "Sigurado ka bang gusto mong KANSELIN ang kwentong ito ngayon? Papatapos na kami sa pagguhit.",
    yes: "Oo",
    no: "Hindi",
    keeper: "Pangalagaan!",
    tryAgain: "Subukan Muli"
  },

  Japanese: {
    confirmCancel: "キャンセルを確認",
    confirmCancelMessage: "このストーリーを今キャンセルしてもよろしいですか？ イラストの仕上げをしています。",
    yes: "はい",
    no: "いいえ",
    keeper: "キーパー！",
    tryAgain: "再試行"
  },

  Hindi: {
    confirmCancel: "रद्द की पुष्टि करें",
    confirmCancelMessage: "क्या आप वाकई इस कहानी को अब रद्द करना चाहते हैं? हम सिर्फ चित्रकला के साथ समाप्त हो रहे हैं।",
    yes: "हाँ",
    no: "नहीं",
    keeper: "रखने लायक!",
    tryAgain: "पुनः प्रयास करें"
  },

  Greek: {
    confirmCancel: "Επιβεβαίωση Ακύρωσης",
    confirmCancelMessage: "Είστε σίγουροι ότι θέλετε να ΑΚΥΡΩΣΕΤΕ αυτή την ιστορία τώρα; Τελειώνουμε μόλις με την εικονογράφηση.",
    yes: "Ναι",
    no: "Όχι",
    keeper: "Κρατητέο!",
    tryAgain: "Δοκιμάστε Ξανά"
  }
};

export const story2Map = {
  English: {
    summary: "Summary",
    selectVocabulary: "Select Vocabulary",
    vocabulary: "Vocabulary",
    conflict: "Conflict",
    resolution: "Resolution",
    quiz: "Quiz",
    theme: "Theme",
    questions: "Questions",
    loading: "Loading",
    generateQuestion: "Generate Question",
    addQuestion: " + Question",
    ageLevel: "Age Level",
    textLevel: "Text Level",
    prompt: "Prompt",
    illustrationStyle: "Illustration Style",
    created: "Created",
    modified: "Modified",
    editText: "Edit Text",
    save: "Save",
    delete: "Delete",
    cancel: "Cancel",
    continue: "Continue",
    language: "Language",
    quizErrorEncountered: "We have encountered an error updating the quiz questions. Servers might be overloaded. Please try again later.",

    metaData: "Meta Data",
    resources: "Resources",
    editQuiz: "Edit Quiz",
    editVocabulary: "Edit Vocabulary",
    edit: "Edit",
    question: "Question",
    answer: "Answer",
    word: "Word",
    definition: "Definition",
    share: "Share",
    hide: "Hide",
    show: "Show",
    listen: "Listen"
  },
  Spanish: {
    summary: "Resumen",
    selectVocabulary: "Seleccionar Vocabulario",
    vocabulary: "Vocabulario",
    conflict: "Conflicto",
    resolution: "Resolución",
    quiz: "Cuestionario",
    theme: "Tema",
    questions: "Preguntas",
    loading: "Cargando",
    generateQuestion: "Generar Pregunta",
    addQuestion: " + Pregunta",
    ageLevel: "Nivel de Edad",
    textLevel: "Nivel de Texto",
    prompt: "Indicación",
    illustrationStyle: "Estilo de Ilustración",
    created: "Creado",
    modified: "Modificado",
    editText: "Editar Texto",
    save: "Guardar",
    delete: "Eliminar",
    cancel: "Cancelar",
    continue: "Continuar",
    language: "Idioma",
    quizErrorEncountered: "Hemos encontrado un error actualizando las preguntas del cuestionario. Los servidores podrían estar sobrecargados. Intente de nuevo más tarde.",
    metaData: "Metadatos",
    resources: "Recursos",
    editQuiz: "Editar Cuestionario",
    editVocabulary: "Editar Vocabulario",
    edit: "Editar",
    question: "Pregunta",
    answer: "Respuesta",
    word: "Palabra",
    definition: "Definición",
    share: "Compartir",
    hide: "Ocultar",
    show: "Mostrar",
    listen: "Escuchar"
  },
  French: {
    summary: "Résumé",
    selectVocabulary: "Sélectionner le Vocabulaire",
    vocabulary: "Vocabulaire",
    conflict: "Conflit",
    resolution: "Résolution",
    quiz: "Quiz",
    theme: "Thème",
    questions: "Questions",
    loading: "Chargement",
    generateQuestion: "Générer Question",
    addQuestion: " + Question",
    ageLevel: "Niveau d'Âge",
    textLevel: "Niveau de Texte",
    prompt: "Invite",
    illustrationStyle: "Style d'Illustration",
    created: "Créé",
    modified: "Modifié",
    editText: "Modifier le Texte",
    save: "Enregistrer",
    delete: "Supprimer",
    cancel: "Annuler",
    continue: "Continuer",
    language: "Langue",
    quizErrorEncountered: "Nous avons rencontré une erreur en mettant à jour les questions du quiz. Les serveurs pourraient être surchargés. Veuillez réessayer plus tard.",
    metaData: "Méta-données",
    resources: "Ressources",
    editQuiz: "Modifier le Quiz",
    editVocabulary: "Modifier le Vocabulaire",
    edit: "Modifier",
    question: "Question",
    answer: "Réponse",
    word: "Mot",
    definition: "Définition",
    share: "Partager",
    hide: "Cacher",
    show: "Montrer",
    listen: "Écouter"
  },
  German: {
    summary: "Zusammenfassung",
    selectVocabulary: "Vokabular auswählen",
    vocabulary: "Vokabular",
    conflict: "Konflikt",
    resolution: "Lösung",
    quiz: "Quiz",
    theme: "Thema",
    questions: "Fragen",
    loading: "Wird geladen",
    generateQuestion: "Frage generieren",
    addQuestion: " + Frage",
    ageLevel: "Altersstufe",
    textLevel: "Textstufe",
    prompt: "Aufforderung",
    illustrationStyle: "Illustrationsstil",
    created: "Erstellt",
    modified: "Geändert",
    editText: "Text bearbeiten",
    save: "Speichern",
    delete: "Löschen",
    cancel: "Abbrechen",
    continue: "Weiter",
    language: "Sprache",
    quizErrorEncountered: "Beim Aktualisieren der Quizfragen ist ein Fehler aufgetreten. Die Server könnten überlastet sein. Bitte versuchen Sie es später erneut.",
    metaData: "Metadaten",
    resources: "Ressourcen",
    editQuiz: "Quiz bearbeiten",
    editVocabulary: "Wortschatz bearbeiten",
    edit: "Bearbeiten",
    question: "Frage",
    answer: "Antwort",
    word: "Wort",
    definition: "Definition",
    share: "Teilen",
    hide: "Verbergen",
    show: "Zeigen",
    listen: "Hören"
  },
  Italian: {
    summary: "Sommario",
    selectVocabulary: "Seleziona Vocabolario",
    vocabulary: "Vocabolario",
    conflict: "Conflitto",
    resolution: "Risoluzione",
    quiz: "Quiz",
    theme: "Tema",
    questions: "Domande",
    loading: "Caricamento",
    generateQuestion: "Genera Domanda",
    addQuestion: " + Domanda",
    ageLevel: "Fascia d'Età",
    textLevel: "Livello di Testo",
    prompt: "Prompt",
    illustrationStyle: "Stile di Illustrazione",
    created: "Creato",
    modified: "Modificato",
    editText: "Modifica Testo",
    save: "Salva",
    delete: "Elimina",
    cancel: "Annulla",
    continue: "Continua",
    language: "Lingua",
    quizErrorEncountered: "Abbiamo riscontrato un errore nell'aggiornamento delle domande del quiz. I server potrebbero essere sovraccarichi. Per favore, riprova più tardi.",
    metaData: "Metadati",
    resources: "Risorse",
    editQuiz: "Modifica Quiz",
    editVocabulary: "Modifica Vocabolario",
    edit: "Modifica",
    question: "Domanda",
    answer: "Risposta",
    word: "Parola",
    definition: "Definizione",
    share: "Condividere",
    hide: "Nascondere",
    show: "Mostrare",
    listen: "Ascoltare"
  },
  Portuguese: {
    summary: "Resumo",
    selectVocabulary: "Selecionar Vocabulário",
    vocabulary: "Vocabulário",
    conflict: "Conflito",
    resolution: "Resolução",
    quiz: "Quiz",
    theme: "Tema",
    questions: "Perguntas",
    loading: "Carregando",
    generateQuestion: "Gerar Pergunta",
    addQuestion: " + Pergunta",
    ageLevel: "Faixa Etária",
    textLevel: "Nível de Texto",
    prompt: "Prompt",
    illustrationStyle: "Estilo de Ilustração",
    created: "Criado",
    modified: "Modificado",
    editText: "Editar Texto",
    save: "Salvar",
    delete: "Excluir",
    cancel: "Cancelar",
    continue: "Continuar",
    language: "Idioma",
    quizErrorEncountered: "Encontramos um erro ao atualizar as perguntas do quiz. Os servidores podem estar sobrecarregados. Por favor, tente novamente mais tarde.",
    metaData: "Metadados",
    resources: "Recursos",
    editQuiz: "Editar Quiz",
    editVocabulary: "Editar Vocabulário",
    edit: "Editar",
    question: "Pergunta",
    answer: "Resposta",
    word: "Palavra",
    definition: "Definição",
    share: "Compartilhar",
    hide: "Esconder",
    show: "Mostrar",
    listen: "Ouvir"
  },
  Tagalog: {
    summary: "Buod",
    selectVocabulary: "Piliin ang Bokabularyo",
    vocabulary: "Bokabularyo",
    conflict: "Salungatan",
    resolution: "Resolusyon",
    quiz: "Pagsusulit",
    theme: "Tema",
    questions: "Mga Tanong",
    loading: "Naglo-load",
    generateQuestion: "Bumuo ng Tanong",
    addQuestion: " + Tanong",
    ageLevel: "Antas ng Edad",
    textLevel: "Antas ng Teksto",
    prompt: "Prompt",
    illustrationStyle: "Istilo ng Ilustrasyon",
    created: "Nilikha",
    modified: "Binago",
    editText: "I-edit ang Teksto",
    save: "I-save",
    delete: "Tanggalin",
    cancel: "Kanselahin",
    continue: "Magpatuloy",
    language: "Wika",
    quizErrorEncountered: "Nakaranas kami ng error sa pag-update ng mga tanong sa quiz. Ang mga server ay maaaring maging overloaded. Paki-subukang muli mamaya.",
    metaData: "Meta Data",
    resources: "Mga Mapagkukunan",
    editQuiz: "I-edit ang Quiz",
    editVocabulary: "I-edit ang Talasalitaan",
    edit: "I-edit",
    question: "Tanong",
    answer: "Sagot",
    word: "Salita",
    definition: "Kahulugan",
    share: "Ibahagi",
    hide: "Itago",
    show: "Ipakita",
    listen: "Makinig"
  },
  Japanese: {
    summary: "要約",
    selectVocabulary: "語彙を選択",
    vocabulary: "語彙",
    conflict: "対立",
    resolution: "解決",
    quiz: "クイズ",
    theme: "テーマ",
    questions: "質問",
    loading: "読み込み中",
    generateQuestion: "質問を生成",
    addQuestion: " + 質問",
    ageLevel: "年齢レベル",
    textLevel: "テキストレベル",
    prompt: "プロンプト",
    illustrationStyle: "イラストレーションスタイル",
    created: "作成された",
    modified: "修正された",
    editText: "テキストを編集",
    save: "保存",
    delete: "削除",
    cancel: "キャンセル",
    continue: "続ける",
    language: "言語",
    quizErrorEncountered: "クイズの質問を更新する際にエラーが発生しました。サーバーが過負荷になっている可能性があります。後でもう一度お試しください。",
    metaData: "メタデータ",
    resources: "リソース",
    editQuiz: "クイズを編集",
    editVocabulary: "語彙を編集",
    edit: "編集",
    question: "質問",
    answer: "回答",
    word: "単語",
    definition: "定義",
    share: "共有する",
    hide: "隠す",
    show: "表示する",
    listen: "聞く"
  },
  Hindi: {
    summary: "सारांश",
    selectVocabulary: "शब्दावली चुनें",
    vocabulary: "शब्दावली",
    conflict: "संघर्ष",
    resolution: "समाधान",
    quiz: "क्विज",
    theme: "विषय",
    questions: "प्रश्न",
    loading: "लोड हो रहा है",
    generateQuestion: "प्रश्न उत्पन्न करें",
    addQuestion: " + प्रश्न",
    ageLevel: "आयु स्तर",
    textLevel: "पाठ स्तर",
    prompt: "प्रॉम्प्ट",
    illustrationStyle: "चित्रण शैली",
    created: "बनाया गया",
    modified: "संशोधित",
    editText: "पाठ संपादित करें",
    save: "सहेजें",
    delete: "हटाएं",
    cancel: "रद्द करें",
    continue: "जारी रखें",
    language: "भाषा",
    quizErrorEncountered: "क्विज प्रश्नों को अपडेट करते समय हमें एक त्रुटि मिली है। सर्वर अधिभारित हो सकते हैं। कृपया बाद में पुनः प्रयास करें।",
    metaData: "मेटा डेटा",
    resources: "संसाधन",
    editQuiz: "क्विज़ संपादित करें",
    editVocabulary: "शब्दावली संपादित करें",
    edit: "संपादित करें",
    question: "प्रश्न",
    answer: "उत्तर",
    word: "शब्द",
    definition: "परिभाषा",
    share: "साझा करें",
    hide: "छुपाएँ",
    show: "दिखाएँ",
    listen: "सुनें"
  },
  Greek: {
    summary: "Περίληψη",
    selectVocabulary: "Επιλογή Λεξιλογίου",
    vocabulary: "Λεξιλόγιο",
    conflict: "Σύγκρουση",
    resolution: "Επίλυση",
    quiz: "Κουίζ",
    theme: "Θέμα",
    questions: "Ερωτήσεις",
    loading: "Φόρτωση",
    generateQuestion: "Δημιουργία Ερώτησης",
    addQuestion: " + Ερώτηση",
    ageLevel: "Επίπεδο Ηλικίας",
    textLevel: "Επίπεδο Κειμένου",
    prompt: "Προτροπή",
    illustrationStyle: "Στυλ Εικονογράφησης",
    created: "Δημιουργήθηκε",
    modified: "Τροποποιήθηκε",
    editText: "Επεξεργασία Κειμένου",
    save: "Αποθήκευση",
    delete: "Διαγραφή",
    cancel: "Ακύρωση",
    continue: "Συνέχεια",
    language: "Γλώσσα",
    quizErrorEncountered: "Αντιμετωπίσαμε ένα σφάλμα κατά την ενημέρωση των ερωτήσεων του quiz. Οι διακομιστές ίσως να είναι υπερφορτωμένοι. Δοκιμάστε ξανά αργότερα.",
    metaData: "Μεταδεδομένα",
    resources: "Πόροι",
    editQuiz: "Επεξεργασία Quiz",
    editVocabulary: "Επεξεργασία Λεξιλογίου",
    edit: "Επεξεργασία",
    question: "Ερώτηση",
    answer: "Απάντηση",
    word: "Λέξη",
    definition: "Ορισμός",
    share: "Κοινοποίηση",
    hide: "Απόκρυψη",
    show: "Εμφάνιση",
    listen: "Ακούστε"
  }
};


export const illustrationStylesMap = {
  English: {
    oil: "Oil Painting",
    cartoon: "Cartoon",
    realistic: "Realistic",
    comicBook: "Comic Book",
    lineDrawing: "Line Drawing",
    abstract: "Abstract",
    dreamlike: "Dreamlike and Surreal",
    vintage: "Vintage",
    cyberpunk: "Cyberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Subdued",
    manga: "Manga",
    paperCollage: "Paper Collage",
    waterColor: "Water Color",
    emoji: "Emoji"
  },
  Spanish: {
    oil: "Pintura al óleo",
    cartoon: "Dibujos animados",
    realistic: "Realista",
    comicBook: "Cómic",
    lineDrawing: "Dibujo de líneas",
    abstract: "Abstracto",
    dreamlike: "Onírico y Surrealista",
    vintage: "Vintage",
    cyberpunk: "Ciberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Apagado",
    manga: "Manga",
    paperCollage: "Collage de papel",
    waterColor: "Acuarela",
    emoji: "Emoji"
  },
  French: {
    oil: "Peinture à l'huile",
    cartoon: "Dessin animé",
    realistic: "Réaliste",
    comicBook: "Bande dessinée",
    lineDrawing: "Dessin en ligne",
    abstract: "Abstrait",
    dreamlike: "Onirique et Surréaliste",
    vintage: "Vintage",
    cyberpunk: "Cyberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Subdued",
    manga: "Manga",
    paperCollage: "Collage de papier",
    waterColor: "Aquarelle",
    emoji: "Emoji"
  },
  German: {
    oil: "Ölgemälde",
    cartoon: "Zeichentrick",
    realistic: "Realistisch",
    comicBook: "Comic",
    lineDrawing: "Linienzeichnung",
    abstract: "Abstrakt",
    dreamlike: "Traumhaft und Surreal",
    vintage: "Vintage",
    cyberpunk: "Cyberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Gedämpft",
    manga: "Manga",
    paperCollage: "Papiercollage",
    waterColor: "Wasserfarbe",
    emoji: "Emoji"
  },
  Italian: {
    oil: "Pittura ad olio",
    cartoon: "Cartone animato",
    realistic: "Realistico",
    comicBook: "Fumetto",
    lineDrawing: "Disegno a tratto",
    abstract: "Astratto",
    dreamlike: "Sogno e Surreale",
    vintage: "Vintage",
    cyberpunk: "Cyberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Sottotono",
    manga: "Manga",
    paperCollage: "Collage di carta",
    waterColor: "Acquerello",
    emoji: "Emoji"
  },
  Portuguese: {
    oil: "Pintura a óleo",
    cartoon: "Desenho animado",
    realistic: "Realista",
    comicBook: "Quadrinho",
    lineDrawing: "Desenho de linha",
    abstract: "Abstrato",
    dreamlike: "Onírico e Surreal",
    vintage: "Vintage",
    cyberpunk: "Ciberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Suave",
    manga: "Manga",
    paperCollage: "Colagem de papel",
    waterColor: "Aquarela",
    emoji: "Emoji"
  },
  Tagalog: {
    oil: "Pinturang Langis",
    cartoon: "Kartun",
    realistic: "Makatotohanan",
    comicBook: "Komiks",
    lineDrawing: "Guhit Linis",
    abstract: "Abstrakto",
    dreamlike: "Pangarap at Surreal",
    vintage: "Bintidhi",
    cyberpunk: "Cyberpunk",
    picasso: "Picasso",
    vanGogh: "Van Gogh",
    subdued: "Mahinahon",
    manga: "Manga",
    paperCollage: "Collage ng Papel",
    waterColor: "Tubig Kulay",
    emoji: "Emoji"
  },
  Japanese: {
    oil: "油絵",
    cartoon: "カートゥーン",
    realistic: "リアルな",
    comicBook: "コミック",
    lineDrawing: "ラインドローイング",
    abstract: "抽象的",
    dreamlike: "夢のようなサレル",
    vintage: "ビンテージ",
    cyberpunk: "サイバーパンク",
    picasso: "ピカソ",
    vanGogh: "バンゴッホ",
    subdued: "抑制された",
    manga: "漫画",
    paperCollage: "ペーパーコラージュ",
    waterColor: "水彩",
    emoji: "絵文字"
  },
  Hindi: {
    oil: "तेल चित्रकला",
    cartoon: "कार्टून",
    realistic: "वास्तविक",
    comicBook: "कॉमिक",
    lineDrawing: "रेखा चित्र",
    abstract: "सांवेदानिक",
    dreamlike: "सपने जैसा और अतियथार्थवादी",
    vintage: "विंटेज",
    cyberpunk: "साइबरपंक",
    picasso: "पिकासो",
    vanGogh: "वैन गोघ",
    subdued: "नम",
    manga: "मंगा",
    paperCollage: "कागज की कोलाज",
    waterColor: "पानी के रंग",
    emoji: "इमोजी"
  },
  Greek: {
    oil: "Λάδι",
    cartoon: "Καρτούν",
    realistic: "Ρεαλιστικό",
    comicBook: "Κόμικ",
    lineDrawing: "Σχέδιο γραμμής",
    abstract: "Αφηρημένο",
    dreamlike: "Ονειρικό και Σουρεαλιστικό",
    vintage: "Ρετρό",
    cyberpunk: "Κυβερνοπάνκ",
    picasso: "Πικάσο",
    vanGogh: "Βαν Γκογκ",
    subdued: "Υποκλίσιμο",
    manga: "Μάνγκα",
    paperCollage: "Κολάζ χαρτιού",
    waterColor: "Ακουαρέλα",
    emoji: "Εμότζι"
  }
};




export const languageSelectionMap = {

  English: {
    languageSelection: "Language Selection",
    english: "English",
    spanish: "Spanish",
    french: "French",
    german: "German",
    italian: "Italian",
    portuguese: "Portuguese",
    tagalog: "Tagalog",
    dutch: "Dutch",
    polish: "Polish",
    norwegian: "Norwegian",
    swedish: "Swedish",
    japanese: "Japanese",
    korean: "Korean",
    russian: "Russian",
    mandarin: "Mandarin",
    persian: "Persian",
    icelandic: "Icelandic",
    thai: "Thai",
    turkish: "Turkish",
    greek: "Greek",
    hebrew: "Hebrew",
    indonesian: "Indonesian",
    arabic: "Arabic",
    dari: "Dari",
    hindi: "Hindi"
  },

  Spanish: {
    languageSelection: "Selección de idioma",
    english: "Inglés",
    spanish: "Español",
    french: "Francés",
    german: "Alemán",
    italian: "Italiano",
    portuguese: "Portugués",
    tagalog: "Tagalo",
    dutch: "Holandés",
    polish: "Polaco",
    norwegian: "Noruego",
    swedish: "Sueco",
    japanese: "Japonés",
    korean: "Coreano",
    russian: "Ruso",
    mandarin: "Mandarín",
    persian: "Persa",
    icelandic: "Islandés",
    thai: "Tailandés",
    turkish: "Turco",
    greek: "Griego",
    hebrew: "Hebreo",
    indonesian: "Indonesio",
    arabic: "Árabe",
    dari: "Dari",
    hindi: "Hindi"
  },

  French: {
    languageSelection: "Sélection de la langue",
    english: "Anglais",
    spanish: "Espagnol",
    french: "Français",
    german: "Allemand",
    italian: "Italien",
    portuguese: "Portugais",
    tagalog: "Tagalog",
    dutch: "Néerlandais",
    polish: "Polonais",
    norwegian: "Norvégien",
    swedish: "Suédois",
    japanese: "Japonais",
    korean: "Coréen",
    russian: "Russe",
    mandarin: "Mandarin",
    persian: "Persan",
    icelandic: "Islandais",
    thai: "Thaï",
    turkish: "Turc",
    greek: "Grec",
    hebrew: "Hébreu",
    indonesian: "Indonésien",
    arabic: "Arabe",
    dari: "Dari",
    hindi: "Hindi"
  },

  German: {
    languageSelection: "Sprachauswahl",
    english: "Englisch",
    spanish: "Spanisch",
    french: "Französisch",
    german: "Deutsch",
    italian: "Italienisch",
    portuguese: "Portugiesisch",
    tagalog: "Tagalog",
    dutch: "Niederländisch",
    polish: "Polnisch",
    norwegian: "Norwegisch",
    swedish: "Schwedisch",
    japanese: "Japanisch",
    korean: "Koreanisch",
    russian: "Russisch",
    mandarin: "Mandarin",
    persian: "Persisch",
    icelandic: "Isländisch",
    thai: "Thailändisch",
    turkish: "Türkisch",
    greek: "Griechisch",
    hebrew: "Hebräisch",
    indonesian: "Indonesisch",
    arabic: "Arabisch",
    dari: "Dari",
    hindi: "Hindi"
  },

  // second part

  Italian: {
    languageSelection: "Selezione della lingua",
    english: "Inglese",
    spanish: "Spagnolo",
    french: "Francese",
    german: "Tedesco",
    italian: "Italiano",
    portuguese: "Portoghese",
    tagalog: "Tagalog",
    dutch: "Olandese",
    polish: "Polacco",
    norwegian: "Norvegese",
    swedish: "Svedese",
    japanese: "Giapponese",
    korean: "Coreano",
    russian: "Russo",
    mandarin: "Mandarino",
    persian: "Persiano",
    icelandic: "Islandese",
    thai: "Thai",
    turkish: "Turco",
    greek: "Greco",
    hebrew: "Ebraico",
    indonesian: "Indonesiano",
    arabic: "Arabo",
    dari: "Dari",
    hindi: "Hindi"
  },

  Portuguese: {
    languageSelection: "Seleção de idioma",
    english: "Inglês",
    spanish: "Espanhol",
    french: "Francês",
    german: "Alemão",
    italian: "Italiano",
    portuguese: "Português",
    tagalog: "Tagalog",
    dutch: "Holandês",
    polish: "Polonês",
    norwegian: "Norueguês",
    swedish: "Sueco",
    japanese: "Japonês",
    korean: "Coreano",
    russian: "Russo",
    mandarin: "Mandarim",
    persian: "Persa",
    icelandic: "Islandês",
    thai: "Tailandês",
    turkish: "Turco",
    greek: "Grego",
    hebrew: "Hebraico",
    indonesian: "Indonésio",
    arabic: "Árabe",
    dari: "Dari",
    hindi: "Hindi"
  },

  Tagalog: {
    languageSelection: "Pagpili ng Wika",
    english: "Ingles",
    spanish: "Espanyol",
    french: "Pranses",
    german: "Aleman",
    italian: "Italyano",
    portuguese: "Portuges",
    tagalog: "Tagalog",
    dutch: "Olandes",
    polish: "Polski",
    norwegian: "Norwegian",
    swedish: "Swedish",
    japanese: "Hapon",
    korean: "Koreano",
    russian: "Ruso",
    mandarin: "Mandarin",
    persian: "Persiano",
    icelandic: "Icelandic",
    thai: "Thai",
    turkish: "Turko",
    greek: "Griyego",
    hebrew: "Hebreo",
    indonesian: "Indonesian",
    arabic: "Arabic",
    dari: "Dari",
    hindi: "Hindi"
  },

  Japanese: {
    languageSelection: "言語選択",
    english: "英語",
    spanish: "スペイン語",
    french: "フランス語",
    german: "ドイツ語",
    italian: "イタリア語",
    portuguese: "ポルトガル語",
    tagalog: "タガログ語",
    dutch: "オランダ語",
    polish: "ポーランド語",
    norwegian: "ノルウェー語",
    swedish: "スウェーデン語",
    japanese: "日本語",
    korean: "韓国語",
    russian: "ロシア語",
    mandarin: "マンダリン",
    persian: "ペルシャ語",
    icelandic: "アイスランド語",
    thai: "タイ語",
    turkish: "トルコ語",
    greek: "ギリシャ語",
    hebrew: "ヘブライ語",
    indonesian: "インドネシア語",
    arabic: "アラビア語",
    dari: "ダリ",
    hindi: "ヒンディー語"
  },

  Hindi: {
    languageSelection: "भाषा चयन",
    english: "अंग्रेजी",
    spanish: "स्पेनिश",
    french: "फ्रेंच",
    german: "जर्मन",
    italian: "इटालियन",
    portuguese: "पुर्तगाली",
    tagalog: "टैगलोग",
    dutch: "डच",
    polish: "पोलिश",
    norwegian: "नॉर्वेजियन",
    swedish: "स्वीडिश",
    japanese: "जापानी",
    korean: "कोरियाई",
    russian: "रूसी",
    mandarin: "मंडारिन",
    persian: "फारसी",
    icelandic: "आइसलैंडिक",
    thai: "थाई",
    turkish: "तुर्की",
    greek: "ग्रीक",
    hebrew: "हिब्रू",
    indonesian: "इंडोनेशियाई",
    arabic: "अरबी",
    dari: "दारी",
    hindi: "हिंदी"
  },

  Greek: {
    languageSelection: "Επιλογή γλώσσας",
    english: "Αγγλικά",
    spanish: "Ισπανικά",
    french: "Γαλλικά",
    german: "Γερμανικά",
    italian: "Ιταλικά",
    portuguese: "Πορτογαλικά",
    tagalog: "Ταγκαλόγκ",
    dutch: "Ολλανδικά",
    polish: "Πολωνικά",
    norwegian: "Νορβηγικά",
    swedish: "Σουηδικά",
    japanese: "Ιαπωνικά",
    korean: "Κορεάτικα",
    russian: "Ρωσικά",
    mandarin: "Μανδαρινικά",
    persian: "Περσικά",
    icelandic: "Ισλανδικά",
    thai: "Ταϊλανδέζικα",
    turkish: "Τουρκικά",
    greek: "Ελληνικά",
    hebrew: "Εβραϊκά",
    indonesian: "Ινδονησιακά",
    arabic: "Αραβικά",
    dari: "Ντάρι",
    hindi: "Χίντι"
  }
};





export const template = {
  English: {},

  Spanish: {},

  French: {},

  German: {},

  Italian: {},

  Portuguese: {},

  Tagalog: {},

  Japanese: {},

  Hindi: {},

  Greek: {}

};
