// deleteStory.js

import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, ref, getStorage } from "firebase/storage";
import { db } from '../../firebase'


const extractBucketFullPathFromDownloadURLs = (imageDownloadURLs) => {
  return imageDownloadURLs.map(imageDownloadURL => {
    try {
      // Check if imageDownloadURL is defined
      if (!imageDownloadURL) {
        console.error('Error: imageDownloadURL is undefined');
        return null;
      }

      // Extract the bucketFullPath from the imageDownloadURL
      const url = new URL(imageDownloadURL);
      const bucketFullPath = url.pathname.split('/o/')[1].split('?alt=')[0];
      // The bucketFullPath is URL-encoded, so decode it
      const decodedBucketFullPath = decodeURIComponent(bucketFullPath);

      return decodedBucketFullPath;
    } catch (error) {
      console.error('Error occurred while extracting bucketFullPath:', error);
      return null;
    }
  });
};

const deleteItem = async ({ userId, item }) => {
  const docRef = doc(db, 'users', userId, 'stories', item.storyId);
  const storage = getStorage();

  const bucketFullPaths = extractBucketFullPathFromDownloadURLs([
    item.imageDownloadURL,
    item.imageMediumDownloadURL,
    item.imageThumbDownloadURL
  ]);

  // Delete associated files from Firebase Storage
  for (const bucketFullPath of bucketFullPaths) {
    if (bucketFullPath) {
      console.log("bucketFullPath", bucketFullPath);
      const bucketRef = ref(storage, bucketFullPath);

      try {
        await deleteObject(bucketRef);
        console.log("🍄 DELETED:===>>>", bucketFullPath);
      } catch (err) {
        if (err.code === 'storage/object-not-found') {
          console.log("File does not exist, skipping: ", bucketFullPath);
        } else {
          console.error("Error during file deletion: ", err.message);
          throw err;
        }
      }
    } else {
      console.log('Skipping deletion due to extraction error');
    }
  }

  // Delete Firestore document
  try {
    await deleteDoc(docRef);
    console.log("🍄 Firestore document DELETED:===>>>", item.title)
  } catch (err) {
    console.error("Error deleting Firestore document: ", err.message)
  }
};

export default deleteItem;


