// DisplayStories.jsx

import React, { useEffect, useState } from 'react'
import { collection, onSnapshot } from "firebase/firestore"
import DisplayCard from './DisplayCard'
import updateStory from '../api/updateStory';
import { Grid, Button, List, Icon } from 'semantic-ui-react'


export default function DisplayStories(props) {
  const {
    db,
    userId,
    storyHandler,
    isListView,
    savedStories,
    setSavedStories,
  } = props;

  const storiesCollectionRef = collection(db, "users", userId, "stories");


  const keepFirstXWords = (text, numberOfWords) => {
    return text.split(" ").slice(0, numberOfWords)
  }

  const toggleFavorite = async (item) => {
    await updateStory({
      userId,
      storyId: item.storyId,
      db,
      updatedFields: { favorited: !item.favorited },
    });
  };


  const storyDisplay = (item, textDisplay) => {
    const shouldShowGenerateImage = !item.imageDownloadURL && !item.imageMediumDownloadURL;

    const tileView = (
      <>


        <DisplayCard
          handleClick={() => storyHandler(item.storyId)}
          title={item?.title}
          dateCreated={item?.dateCreated}
          dateModified={item?.dateModified}
          imageURL={getImageSource(item)}
          text={textDisplay}
          languageEmoji={item?.languageEmoji}
          center={!isListView}
          favorited={item.favorited}
          onHeartClick={() => toggleFavorite(item)}
          showGenerateImage={shouldShowGenerateImage} // Pass this new prop
        />

        {/* <div
          onClick={() => archiveItem({ userId, item, db })}
          style={{ cursor: 'pointer', fontSize: '2em' }}
        >
          📁
        </div> */}
      </>
    );

    const listViewContent = (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div onClick={() => storyHandler(item.storyId)} style={{ cursor: 'pointer' }}>
          <List.Header>{item?.title}</List.Header>
          <List.Description>{textDisplay}</List.Description>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation(); // Prevents the click event from triggering handleClick
            toggleFavorite(item);
          }}
          style={{
            cursor: 'pointer',
            backgroundColor: 'white',
            borderRadius: '50%', // This makes the div circular
            width: '30px', // Width of the circle
            height: '30px', // Height of the circle
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px', // Add some margin to the right of the heart
          }}
        >
          <Icon
            name={item.favorited ? 'heart' : 'heart outline'}
            color={item.favorited ? 'red' : 'grey'}
            style={{
              fontSize: '20px', // Adjust the size as needed
              marginRight: '2px' // Move the heart a bit to the right
            }}
          />
        </div>
      </div>
    );


    return isListView ? <List.Content>{listViewContent}</List.Content> : tileView;
  };



  const archiveItem = ({ userId, item, db }) => {
    console.log("ARCHIVE");
    updateStory({ userId, storyId: item.storyId, db, updatedFields: { archived: true } });
  };


  const getImageSource = ({ imageDownloadURL, imageMediumDownloadURL }) => {
    if (imageMediumDownloadURL) return imageMediumDownloadURL;
    return imageDownloadURL;
  };


  const savedStoryCollectionDisplay = savedStories && (
    isListView ? (
      <List divided relaxed>
        {savedStories.map((item) => {
          if (item?.archived) return null;
          const textDisplay = item?.blurb
            ? item.blurb
            : item.text && keepFirstXWords(item.text, 20).join(' ') + '...';

          return (
            <List.Item key={item.storyId}>{storyDisplay(item, textDisplay)}</List.Item>
          );
        })}
      </List>
    ) : (
      <Grid container columns={3} doubling stackable className="center-content">
        {savedStories.map((item) => {
          if (item?.archived) return null;
          const textDisplay = item?.blurb
            ? item.blurb
            : item.text && keepFirstXWords(item.text, 20).join(' ') + '...';

          return (
            <Grid.Column key={item.storyId}>{storyDisplay(item, textDisplay)}</Grid.Column>
          );
        })}
      </Grid>
    )
  );

  return (
    <>
      {savedStories && savedStoryCollectionDisplay}
    </>
  );


}
