// NewStoryDisplay.jsx 

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button } from 'semantic-ui-react';
import buildVisualPromptGetImage from '../CreateApi/buildVisualPromptGetImage.js';
import saveNewStory from '../CreateApi/saveNewStory.js'
import saveUnfinishedStory from '../CreateApi/saveUnfinishedStory.js'
import StoryTextDisplayWrapper from './StoryTextDisplayWrapper.jsx';

import Lottie from "lottie-react";
import savingDots from '../../../images/savingDots.json';
import AiOrbLoader from '../../../images/136808-ai-orb.json';
import sadRobot from '../../../images/sad_robot.json';
import RevealImage from '../../general/RevealImage.jsx';
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import adjustUserCredit from '../CreateApi/adjustUserCredit.js';

import { story2Map } from '../../../constants/siteTextMap.js';
import StoryResources from '../StoryResources.jsx';


function NewStoryDisplay(props) {
  const { currentUser, setShowNavbar, firestoreUserData, db } = props;

  let navigate = useNavigate();
  let location = useLocation();
  const storage = getStorage();

  const [newStory] = useState(location.state?.newStory);
  const [illustrationObject] = useState(location.state?.illustrationObject);
  const [illustrationType] = useState(location.state?.illustrationType);
  const [generatedStoryId] = useState(location.state?.generatedStoryId);
  const [userPrompt] = useState(location.state?.userPrompt);
  const [chatModelVersion] = useState(location.state?.chatModelVersion);
  const [imageGeneration] = useState(location.state?.imageGeneration);

  const [entireResponse] = useState(location.state?.entireResponse);  // Where is this used?

  const cancelTokenSourceRef = useRef(null);
  const intervalIdRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [imagesObject, setImagesObject] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [imageApiResponseTime, setImageApiResponseTime] = useState(null);
  const [entireImageResponse, setEntireImageResponse] = useState(null);
  const [visibleAnswer, setVisibleAnswer] = useState(null);
  // const [generatedImagePrompt, setGeneratedImagePrompt] = useState(null);


  const [showSavingGraphic, setShowSavingGraphic] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [showQuiz, setShowQuiz] = useState(false);
  const [savingStory, setSavingStory] = useState(false);
  const [successfulSave, setSuccessfulSave] = useState(false);
  const [storyObject, setStoryObject] = useState({
    ageLevel: null,
    archived: false,
    chatModelVersion,
    DRA_Level: null,
    fullChatRecord: entireResponse,

    illustrationObject: {
      ...illustrationObject,
      illustrationType,
      // generatedImagePrompt
    },
    imagePromptGenerated: null,
    imageGenerationModel: imageGeneration,
    imagesObject,
    imageDownloadURL: imagesObject?.imageDownloadURL,
    imageThumbMediumURL: imagesObject?.imageThumbMediumURL,
    imageThumbDownloadURL: imagesObject?.imageThumbDownloadURL,
    language: firestoreUserData?.currentLanguage,
    languageEmoji: firestoreUserData?.currentFlag,
    prompt: userPrompt,
    publicAccess: true,
    storyId: generatedStoryId,
    wordBank: null
  });


  useEffect(() => {
    if (successfulSave) {
      navigate('/storydisplaymain/' + generatedStoryId);
    }
  }, [successfulSave, generatedStoryId, navigate]);


  useEffect(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source(); // Initialize the cancel token
    callBuildVisualPromptGetImage();
    return () => {
      cancelTokenSourceRef.current?.cancel("Component unmounted, request cancelled");
      clearInterval(intervalIdRef.current); // Clear interval on component unmount
    };
  }, []);

  const { title, text, blurb, summary, conflict, resolution, theme, vocabulary, setting, characters, quizArray } = newStory || {};


  const charactersDescriptionText = characters ? JSON.stringify(characters) : '';


  // TO DO: extract getDownloadURLWithRetry
  function getDownloadURLWithRetry(ref, path, retries = 5, delay = 1000) {
    return new Promise((resolve, reject) => {
      const attemptDownload = (retriesLeft) => {
        getDownloadURL(ref, path)
          .then(resolve)
          .catch((error) => {
            if (retriesLeft === 0) {
              reject(error);
            } else {
              setTimeout(() => attemptDownload(retriesLeft - 1), delay);
            }
          });
      };
      attemptDownload(retries);
    });
  }

  const callBuildVisualPromptGetImage = async () => {

    try {

      const response = await buildVisualPromptGetImage({
        userId: currentUser?.uid,

        theme,
        title,
        summary,
        setting,
        charactersDescription: charactersDescriptionText,

        illustrationType,
        generatedStoryId

      }, cancelTokenSourceRef.current.token, intervalIdRef);


      const {
        entireImagePromptResponse,
        images,
        imageApiResponseTime,
        imagePrompt } = response

      setImagesObject(images)
      setEntireImageResponse(entireImagePromptResponse);
      setImageApiResponseTime(imageApiResponseTime);


      const downloadMediumURL = await getDownloadURLWithRetry(ref(storage, images?.imageThumbMediumURL));
      setCurrentImageUrl(downloadMediumURL);


      setStoryObject(prev => ({
        ...prev,
        ...newStory,
        imagesObject: images,
        imagePromptGenerated: imagePrompt,
        imageDownloadURL: images?.imageDownloadURL,
        imageThumbMediumURL: images?.imageThumbMediumURL,
        imageThumbDownloadURL: images?.imageThumbDownloadURL,
      }));

    } catch (error) {
      console.error('Error calling buildVisualPromptGetImage:', error);
      setServerError(true);
    };

  }

  const handleSaveNewStory = async () => {
    console.log("SAVING STORY");

    // Assuming saveNewStory has been modified to return a promise
    // that resolves when the story is successfully saved.
    try {
      await saveNewStory({
        currentUser,
        db,
        storyObject,
        setShowSavingGraphic,
        setShowNavbar,
        setServerError,
        setSavingStory,
        setSuccessfulSave
      });

      // If saveNewStory succeeds, then call adjustUserCredit.
      await adjustUserCredit(firestoreUserData);
      console.log("Credit adjusted successfully.");
    } catch (error) {
      console.error("Error in saving story or adjusting credit:", error);
      // Handle any errors here
    }
  };


  const handleCancel = () => {
    setImagesObject(null);
    setCurrentImageUrl(null);
    setImageApiResponseTime(null);
    setEntireImageResponse(null);
    // setGeneratedImagePrompt(null);
    setShowSavingGraphic(false);
    setServerError(false);
    setShowQuiz(false);
    setSavingStory(false);
    setSuccessfulSave(false);

    navigate("/createthemedstory");
  };


  const handleSaveUnfinishedStory = () => {
    const updatedStoryObject = { ...storyObject, ...newStory, retryImageGeneration: true };

    setStoryObject(updatedStoryObject);

    saveUnfinishedStory({
      currentUser,
      currentLanguage: firestoreUserData?.currentLanguage,
      db,
      setShowSavingGraphic,
      setShowNavbar,
      setServerError,
      updatedStoryObject,
      setSavingStory,
      setSuccessfulSave
    });

    navigate("/createthemedstory");
  };


  const handleTryAgain = () => {
    navigate('/createthemedstory', {
      replace: true,
      state: { userPrompt }
    });
  };



  // Cancel button JSX
  const CancelButton = () => (
    <div className="center">
      <Button
        color="black"
        onClick={() => {
          // Cancel the API request if ongoing
          if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel("API request cancelled by the user.");
          }

          handleCancel();
        }}
      >❌ Cancel</Button>
    </div>
  );


  // Error message UI
  const ErrorMessageUI = () => (
    <div style={{ border: '5px solid red', margin: "10px auto", width: "75%", padding: '10px' }}>
      <h1 style={{ textAlign: 'center' }}>Oops, There has been an error on the server and we couldn't generate the image for your story.</h1>
      <Lottie animationData={sadRobot} loop={false} style={{ height: 200, margin: 'auto' }} />
      <h1 style={{ textAlign: 'center' }}>Would you like to save the story and add an image later?</h1>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <Button color="green" onClick={handleSaveUnfinishedStory}>Save Story and Retry Later</Button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button color="red" onClick={handleCancel}>Cancel</Button>
      </div>
    </div>
  );


  if (savingStory) {
    return <Lottie animationData={savingDots} loop={true} style={{ height: 600, position: "relative" }} />
  }


  if (!newStory) return <h1>Loading story...</h1>;

  return (
    <Container>

      <h2 className="center">{title}</h2>
      <h4 className="center">{blurb}</h4>

      {serverError
        ?
        <ErrorMessageUI />
        : currentImageUrl
          ?
          <>
            <div className="center">
              <RevealImage src={currentImageUrl} alt="ai_image" />
            </div>
            <br />
          </>
          :
          <Lottie animationData={AiOrbLoader} loop={true} style={{ height: 200, position: "relative" }} />
      }

      {text && <StoryTextDisplayWrapper
        storyText={text}
        wordBank={vocabulary} />}

      {!currentImageUrl && !serverError && <CancelButton />}


      {/* {currentImageUrl && !serverError && (
        <div className="center">
          <Button
            size="huge"
            color="green"
            onClick={handleSaveNewStory}>
            Save Story!
          </Button>
        </div>
      )} */}

      {currentImageUrl && !serverError && (
        <>
          <div className="center">
            <Button
              size="huge"
              color="green"
              onClick={handleSaveNewStory}>
              Save Story!
            </Button>
          </div>
          <div className="center" style={{ marginTop: '10px' }}>
            <Button
              size="huge"
              color="orange"
              onClick={handleTryAgain}>
              Try Again
            </Button>
          </div>
        </>
      )}




    </Container>
  );
}

export default NewStoryDisplay;
