// CreateDalle3.jsx

// newStoryId
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Container, Icon, Modal, Checkbox } from 'semantic-ui-react'
import axios from 'axios'
import { setDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid'
import Lottie from "lottie-react";
import 'react-toastify/dist/ReactToastify.css';
import coloredCircleAnimation from '../../images/94137-1hour-loading-2.json';
import errorCat from '../../images/9195-error.json';
import savingDots from '../../images/savingDots.json';
import animatedRobot from '../../images/99973-little-power-robot.json';
import CurrentStoryDisplayDalle3 from '../general/CurrentStoryDisplayDalle3';
import ReactTooltip from 'react-tooltip'
import SliderDRALevel from '../general/SliderDRALevel'


import {
  ILLUSTRATION_AI_PROMPTS
} from '../../constants/illustrationsConsts'
import { ILLUSTRATION_BUTTON_IMAGE_URLS } from '../../constants/illustrationsConsts'
import { ILLUSTRATION_STYLES } from '../../constants/illustrationsConsts'

// import image0 from '../../images/button-images/image0_suprise.jpg';
import image1 from '../../images/button-images/image1_oil.jpg';
import image2 from '../../images/button-images/image2_cartoon.jpg';
import image3 from '../../images/button-images/image3_realistic.jpg';
import image4 from '../../images/button-images/image4_comic.jpg';
import image5 from '../../images/button-images/image5_line.jpg';
import image6 from '../../images/button-images/image6_abstract.jpg';

import image7 from '../../images/button-images/image7_dreamlike.jpg';

import image8 from '../../images/button-images/image8_vintage.jpg';

import image9 from '../../images/button-images/image9_cyberpunk.jpg';


import image10 from '../../images/button-images/image10_picasso.jpg';
import image11 from '../../images/button-images/image11_vangogh.jpg';
import image12 from '../../images/button-images/image12_subdued.jpg';

import { createStoryMap, languageSelectionMap } from '../../constants/siteTextMap'

import DesignChoicesDalle3 from '../general/DesignChoicesDalle3'

import DesignChoicesSpecial from '../general/DesignChoicesSpecial'



// {createStoryMap[language]?.illustrationStyleSelction || createStoryMap['English'].illustrationStyleSelction}

const illustrationImages = [

  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12];

const CreateDalle3 = (props) => {
  const { db, currentUser, avatar, firestoreUserData, showNavbar, setShowNavbar, chatModelVersion } = props

  let navigate = useNavigate()

  // update to progressively add chats ?
  const [fullChatRecord, setFullChatRecord] = useState(null)
  const [responseAI, setResponseAI] = useState(null)
  const [storyText1, setStoryText1] = useState(null)
  const [storyTitle2, setStoryTitle2] = useState(null)
  const [storySummary3, setStorySummary3] = useState(null)
  const [storyBlurb3b, setStoryBlurb3b] = useState(null)
  const [storyImagePrompt4, setStoryImagePrompt4] = useState(null)
  const [storyImageURL5, setStoryImageURL5] = useState(null)

  const [fullResponse, setFullResponse] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [language, setStoryLanguage] = useState(firestoreUserData?.currentLanguage ? firestoreUserData.currentLanguage : "English")

  const [languageEmoji, setLanguageEmoji] = useState(firestoreUserData?.currentFlag ? firestoreUserData.currentFlag : "🇺🇸")

  const [ageLevel, setAgeLevel] = useState({ display: createStoryMap[language]?.age_6_8 || createStoryMap['English'].age_6_8, ageLevelPrefix: "6 to 8" })

  const [DRA_Level, set_DRA_Level] = useState(null);

  const [wordBankArray, setWordBankArray] = useState([])

  const [newStoryId, setNewStoryId] = useState(null)
  const [currentStoryCollection, setCurrentStoryCollection] = useState(null)
  const [userStoryPrompt, setUserStoryPrompt] = useState(null)
  const [isLoadingPart1, setIsLoadingPart1] = useState(false)
  const [isLoadingPart2, setIsLoadingPart2] = useState(false)

  const [keeperIsSaving, setKeeperIsSaving] = useState(false)
  const [showAdvancedUI, setShowAdvancedUI] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [disableBackButton, setDisableBackButton] = useState(true);

  // CANCEL2
  const [showAbortPart1Button, setShowAbortPart1Button] = useState(false)

  const [openAbort1Modal, setOpenAbort1Modal] = useState(false);
  const [openAbort2Modal, setOpenAbort2Modal] = useState(false);
  const [theme, setTheme] = useState("");



  const cancelTokenSourceRef1 = useRef(null);
  const cancelTokenSourceRef2 = useRef(null);


  const intervalIdRef1 = useRef(null);
  const intervalIdRef2 = useRef(null); // TUES


  const [illustrationType, setIllustrationType] = useState([0, ILLUSTRATION_STYLES[0], ILLUSTRATION_BUTTON_IMAGE_URLS[0], ILLUSTRATION_AI_PROMPTS[0]])

  const [illustrationObject, setIllustrationObject] = useState({})

  const [updatedFBData, setFBData] = useState(null)
  const [updateError, setUpdateError] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(null)
  const [selectedHeading, setSelectedHeading] = useState(null);

  const [textTokensUsed, setTextTokensUsed] = useState([])


  const { register, handleSubmit, reset, errors } = useForm({}); // errors
  const notify = (message) => toast(message);

  const robotStyle = { height: 200 };
  const baseUrl = process.env.REACT_APP_API_URL
  const storage = getStorage();


  useEffect(() => {
    // Disable back navigation by pushing the same state again
    window.history.pushState(null, document.title, window.location.href);

    setDisableBackButton(true)
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/createdalle3');  // Navigate to home or some other page
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);



  useEffect(() => { setNewStoryId(uuidv4()) }, [])
  useEffect(() => { console.log(`🦋${chatModelVersion}🦋`) }, [])

  useEffect(() => {
    if (responseAI === "part2") {
      submitTitlePromptImage({ fullChatRecord, chatModelVersion, language, userId: currentUser?.uid, illustrationType, newStoryId })
    }
  }, [responseAI])

  const handleThemeToggle = () => {
    setTheme(theme === "Christmas" ? "" : "Christmas");
  };


  // BUG TO FIX: Also check for puncuation
  const removeQuotesFromEnds = (string) => {
    return string.replace(/^['"]|['"]$/g, "")
  }

  const updateUserDoc = async (itemsToUpdate) => {
    const docRef = doc(db, "users", currentUser?.uid);
    try {
      await updateDoc(docRef, itemsToUpdate);
      setUpdateLoading(true);
      // after updating, get the updated data
      const docSnap = await docRef.get();
      const response = docSnap.data()
      setFBData(response)
      setUpdateError(null)

    } catch (error) {
      setUpdateError(error)
      setFBData(null)

    } finally {
      setUpdateLoading(false);
    }
  }

  function wordBankSplit(wordBank) {
    if (wordBank === "" || wordBank.trim() === "") { return [] }
    const pattern = /,|\n/;
    let wordBankDivided = []
    if (wordBank) { wordBankDivided = wordBank.split(pattern) }

    // Filter out any empty strings and trim the words
    const finalwordBankArray = wordBankDivided.length > 0 ? wordBankDivided.map(word => word.trim()).filter(word => word !== "") : [...wordBankDivided]

    return finalwordBankArray;
  }

  // ----------------------------------------- //

  const confirmStoryCancel = () => {
    console.log("🤬 CANCEL CONFIRMED! 🤬");

    if (cancelTokenSourceRef1.current) {
      cancelTokenSourceRef1.current.cancel("Request aborted by the user.");
      console.log("Axios request cancelled");
    } else {
      console.log("Cancel token source not initialized");
    }

    if (intervalIdRef1.current) {
      clearInterval(intervalIdRef1.current);
      console.log("Interval cleared");
    } else {
      console.log("IntervalId not initialized");
    }

    setShowNavbar(true);
    clearAllPageState();
  };



  const ModalCancelPart1 = () => (
    <Modal
      open={openAbort1Modal}
      onClose={() => setOpenAbort1Modal(false)}>
      <Modal.Header>{createStoryMap[language]?.modalCancelTitle || createStoryMap['English'].modalCancelTitle}</Modal.Header>

      <Modal.Content>
        <p>{createStoryMap[language]?.modalCancelMessage || createStoryMap['English'].modalCancelMessage}</p>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={(e) => {
          e.stopPropagation();
          setOpenAbort1Modal(false);
          confirmStoryCancel();
        }}>Yes
        </Button>
        <Button onClick={(e) => {
          e.stopPropagation();
          setOpenAbort1Modal(false);
        }}>No
        </Button>
      </Modal.Actions>

    </Modal>
  );


  const submitStoryPrompt = formInput => {

    // CANCEL2
    cancelTokenSourceRef1.current = axios.CancelToken.source();

    const { userRequest, wordBank } = formInput;
    let wordBankArrayTemp = [];
    if (typeof wordBank === 'string' && wordBank.length > 0 && wordBank.replace(',', '').length > 0) {
      wordBankArrayTemp = wordBankSplit(wordBank);
    }

    setWordBankArray(() => wordBankArrayTemp);

    let storyRequest = {
      userRequest,
      userId: currentUser?.uid,
      ageLevel: ageLevel?.ageLevelPrefix,
      DRA_Level,
      wordBank: wordBankArrayTemp,
      chatModelVersion,
      language,
      theme
    };

    setResponseAI("part1");
    setShowNavbar(false);
    setShowAbortPart1Button(true);
    setOpenAbort1Modal(false);
    setIsLoadingPart1(true);
    setUserStoryPrompt(userRequest);

    axios.post(baseUrl + "/gpt4-dalle3/storyonly/", storyRequest, {
      cancelToken: cancelTokenSourceRef1.current.token
    })
      .then((response) => {
        const jobId = response.data.jobId;
        console.log("Job ID retrieved:", jobId);

        intervalIdRef1.current = setInterval(() => {
          console.log("Polling job status for ID:", jobId);

          axios.get(baseUrl + `/gpt4-dalle3/storyonly/status/${jobId}`, {
            cancelToken: cancelTokenSourceRef1.current.token
          })
            .then((response) => {
              const job = response.data;
              console.log("Job status:", job.status);

              if (job.status === 'completed' || job.status === 'failed') {
                clearInterval(intervalIdRef1.current);
              }

              if (job.status === 'completed') {
                setShowAbortPart1Button(false);

                if (job.returnvalue && job.returnvalue.length > 1) {
                  const [entireChatRecord, newStoryText, tokensUsed] = job.returnvalue;

                  console.log("entireChatRecord", entireChatRecord)
                  console.log("newStoryText", newStoryText)
                  console.log("tokensUsed", tokensUsed)

                  const tokensUsedWithTimestamp = { tokens: tokensUsed, timeStamp: new Date().toISOString() };


                  setFullChatRecord(entireChatRecord);
                  setStoryText1(newStoryText);
                  setTextTokensUsed([tokensUsedWithTimestamp])

                  setShowNavbar(true);
                  setIsLoadingPart1(false);
                  setResponseAI("part2");
                } else {
                  console.error("Job completed but return value is missing or invalid:", jobId);
                  setIsLoadingPart1(false);
                  setShowNavbar(true);
                  notify("There was an issue processing your request. Please try again.");
                }
              } else if (job.status === 'failed') {
                console.error("Job failed with ID:", jobId);
                setIsLoadingPart1(false);
                setShowNavbar(true);
                setServerError(() => true);
                notify("Job failed. Please try again.");
              }
            })
            .catch(err => {
              console.log("Error in polling job status:", err.message);
            });
        }, 5000);
      })
      .catch(err => {
        console.log("Error in submitting story prompt:", err.message);
      });
  };


  function getDownloadURLWithRetry(ref, path, retries = 5, delay = 1000) {

    return new Promise((resolve, reject) => {
      const attemptDownload = (retriesLeft) => {
        getDownloadURL(ref, path)
          .then(resolve)
          .catch((error) => {
            if (retriesLeft === 0) {
              reject(error);
            } else {
              setTimeout(() => attemptDownload(retriesLeft - 1), delay);
            }
          });
      };

      attemptDownload(retries);
    });
  }

  const submitTitlePromptImage = async ({ fullChatRecord, language, chatModelVersion, userId, illustrationType, newStoryId }) => {
    const titlePromptImageRequest = {
      fullChatRecord,
      language,
      chatModelVersion,
      userId,
      illustrationType: illustrationType[3],
      storyIdTitle: newStoryId
    };

    setIsLoadingPart2(true);
    setShowNavbar(false);

    // CANCEL2
    // setShowAbortPart1Button(true)


    try {
      const response = await axios.post(baseUrl + "/gpt4-dalle3/titlepromptimage/", titlePromptImageRequest);
      const jobId = response.data.jobId;

      const intervalId = setInterval(async () => {
        try {
          const jobStatusResponse = await axios.get(baseUrl + `/gpt4-dalle3/titlepromptimage/status/${jobId}`);
          const job = jobStatusResponse.data;


          if (job.status === 'completed') {
            clearInterval(intervalId);
            setIsLoadingPart2(false);

            // CANCEL2
            // setShowAbortPart1Button(false)

            const result = job.returnvalue;
            setFullResponse(result);
            setFullChatRecord(result[0].fullChatRecord || "Error: " + result[0].error);
            setStoryTitle2(result[2].result ? removeQuotesFromEnds(result[2].result) : "Error: " + result[2].error);
            setStorySummary3(result[3].result || "Error: " + result[3].error);
            setStoryBlurb3b(result[4].result || "Error: " + result[4].error);
            setStoryImagePrompt4(result[5].result || "Error: " + result[5].error);

            const suffixId = "_picturebook_DEC2022.png";

            const imageMediumFilePath = "images/USERSET_A_" + currentUser.uid + "/medium_" + newStoryId + suffixId;

            const downloadMediumURL = await getDownloadURLWithRetry(ref(storage, imageMediumFilePath));

            setStoryImageURL5(downloadMediumURL || "Error: " + result[7].error);

            setResponseAI("part3");

          } else if (job.status === 'failed') {
            clearInterval(intervalId);
            setIsLoadingPart2(false);
            const errorMessage = job.error;
            setErrorMessage(errorMessage);
          }

        } catch (err) {
          console.error(err);
          setIsLoadingPart2(false);
          setErrorMessage("An error occurred while checking job status.");
        }
      }, 5000);
    } catch (err) {
      console.error(err);
      setIsLoadingPart2(false);
      setErrorMessage("An error occurred while submitting the title prompt image request.");
    }
  };


  const clearEntry = () => {
    setResponseAI(null)
    setShowNavbar(true)
    reset()
  }


  const successfullySave = (message) => {
    const toastOptions = {
      position: "top-right",
      autoClose: 400,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    }
    toast.success(message, toastOptions)
  }

  const handleSliderChange = (newValue) => {
    set_DRA_Level(newValue);
  };

  const redoPicture = () => { console.log("NEW PICTURE REQUEST") }

  const redoStory = () => { console.log("NEW STORY REQUEST") }



  const keeperDalle = async () => {
    if (!currentUser) return null


    setKeeperIsSaving(() => true)
    setShowNavbar(false)

    let newStory = {
      userId: currentUser.uid,
      userDisplayName: currentUser?.displayName,
      userEmail: currentUser?.email,
      title: storyTitle2,
      text: storyText1,
      summary: storySummary3,
      blurb: storyBlurb3b,
      imagePrompt: storyImagePrompt4,
      illustrationType: illustrationType[3], // remove?
      illustrationObject,

      // FIX
      ageLevel: ageLevel?.ageLevelPrefix,

      DRA_Level,
      wordBank: wordBankArray,

      language,
      languageEmoji,

      fullChatRecord,
      chatModelVersion,
      imageGeneration: "Dalle-3",
      storyId: newStoryId,
      publicAccess: true,
      archived: false,
      prompt: userStoryPrompt,
      dateCreated: serverTimestamp(),
      dateModified: serverTimestamp(),
      textTokensUsed
    }

    const suffixId = "_picturebook_DEC2022.png"

    const imageFilePath = "images/USERSET_A_" + currentUser.uid + "/" + newStoryId + suffixId

    const imageThumbFilePath = "images/USERSET_A_" + currentUser.uid + "/thumb_" + newStoryId + suffixId

    const imageMediumFilePath = "images/USERSET_A_" + currentUser.uid + "/medium_" + newStoryId + suffixId


    try {
      // ORIGINAL
      const imageDownloadURL = await getDownloadURL(ref(storage, imageFilePath))
      newStory.imageDownloadURL = imageDownloadURL

      // THUMBNAIL
      const downloadThumbURL = await getDownloadURL(ref(storage, imageThumbFilePath))
      newStory.imageThumbDownloadURL = downloadThumbURL

      // MEDIUM
      const downloadMediumURL = await getDownloadURL(ref(storage, imageMediumFilePath))
      newStory.imageMediumDownloadURL = downloadMediumURL


      await setDoc(doc(db, "users", currentUser.uid, "stories", newStoryId), newStory)

      const updatedStoryCollection = currentStoryCollection ? [...currentStoryCollection] : []
      updatedStoryCollection.push(newStory)

      setCurrentStoryCollection(updatedStoryCollection)// needed?
      setResponseAI(null)
      setKeeperIsSaving(() => false)

      // TOKENS
      let currentCredit = firestoreUserData?.credit
      if (isNaN(currentCredit)) { currentCredit = 0 }

      // let textTokens = firestoreUserData?.textTokens ?? [];
      let textTokens = Array.isArray(firestoreUserData?.textTokens) ? firestoreUserData.textTokens : [];


      // push textTokensUsed onto prior array. if none exists, create [textTokens]

      console.log("🔵 🔵 🔵 NEW: textTokensUsed", textTokensUsed)
      console.log("🟢 🟢 🟢 CURRENT: textTokens", textTokens)

      const updateObject = {
        credit: currentCredit - parseInt(process.env.REACT_APP_IMAGE_CREDIT),
        textTokens: textTokens.push(textTokensUsed)
      }


      await updateUserDoc(updateObject)

      setShowNavbar(true)
      setNewStoryId(uuidv4())
      setResponseAI(null)
      setStoryText1(null)
      setStoryTitle2(null)
      setStorySummary3(null)
      setStoryImagePrompt4(null)
      setStoryImageURL5(null)
      setWordBankArray(null)

      navigate('/storydisplaymain/' + newStoryId);


    } catch (err) {
      setServerError(true)
      setShowNavbar(true)
      console.error("❌", err.message, "❌")
      console.error("❌❌", err, "❌❌")
    }
  }


  const clearAllPageState = () => {
    clearEntry()

    setShowNavbar(true)

    setResponseAI(null)
    setStoryText1(null)
    setStoryTitle2(null)
    setStorySummary3(null)
    setStoryImagePrompt4(null)
    setStoryImageURL5(null)

    setServerError(false)
    setWordBankArray(null)

    setIsLoadingPart1(false)
    setIsLoadingPart2(false)
    setOpenAbort1Modal(false);
    setOpenAbort2Modal(false);

    setTextTokensUsed([])
  }

  const nonEnglishGradeLevelUI = () => {
    return <>
      <h2>{ageLevel?.display}</h2>
      <h4
        className={`studentAge ${selectedHeading === 'k1' ? 'selected' : ''}`}
        onClick={() => {
          setAgeLevel({
            display:
              createStoryMap[language]?.age_4_5 || createStoryMap['English'].age_4_5,
            ageLevelPrefix: '4 to 5'
          });
          setSelectedHeading('k1');
        }}>
        K-1
      </h4>


      <h4
        className={`studentAge ${selectedHeading === '23' ? 'selected' : ''
          }`}
        onClick={() => {
          setAgeLevel({
            display: createStoryMap[language]?.age_6_8 || createStoryMap['English'].age_6_8,
            ageLevelPrefix: '6 to 8'
          });
          setSelectedHeading('23');
        }}>
        2nd-3rd
      </h4>


      <h4
        className={`studentAge ${selectedHeading === '45' ? 'selected' : ''
          }`}
        onClick={() => {
          setAgeLevel({
            display: createStoryMap[language]?.age_9_11 || createStoryMap['English'].age_9_11,
            ageLevelPrefix: '9 to 11'
          });
          setSelectedHeading('45');
        }}>
        4th-5th
      </h4>


      <h4
        className={`studentAge ${selectedHeading === '6+' ? 'selected' : ''
          }`}
        onClick={() => {
          setAgeLevel({
            display: createStoryMap[language]?.age_12_above || createStoryMap['English'].age_12_above,
            ageLevelPrefix: '12 to 14'
          });
          setSelectedHeading('6+');
        }}>

        6th +
      </h4>
    </>
  }


  const paidOptions = (<>

    <h2>{createStoryMap[language]?.wordBank || createStoryMap['English'].wordBank}</h2>

    <textarea
      type="text"
      placeholder={createStoryMap[language]?.placeholderWordBank || createStoryMap['English'].placeholderWordBank}
      rows="6"
      {...register('userRequest', { required: true, maxLength: 1000 })}
      style={{
        fontSize: '1.5em',
        fontFamily: 'Courier, monospace',
        boxSizing: 'border-box'
      }}
    />
    <br />

    {language === "English"
      ? <>
        <h1>DRA Level: {DRA_Level}</h1>
        <SliderDRALevel onSliderChange={handleSliderChange} DRA_Level={DRA_Level} />
        <br />
        <br />
      </>
      : nonEnglishGradeLevelUI()
    }

  </>)



  const chooseIllustrationTypeAdvanced_TEMP = () => {

    let advanceNumber = illustrationType[0] + 1;
    if (advanceNumber >= ILLUSTRATION_STYLES.length) {
      advanceNumber = 0;
    }
    setIllustrationType([
      advanceNumber,
      ILLUSTRATION_STYLES[advanceNumber],
      ILLUSTRATION_BUTTON_IMAGE_URLS[advanceNumber],
      ILLUSTRATION_AI_PROMPTS[advanceNumber],
    ]);
  };



  const isMobile = window.innerWidth <= 600;


  const basicStoryUI = () => {
    //const isMobile = window.innerWidth <= 600;
    return <>
      <style>
        {`
    @media (min-width: 601px) {
      textarea {
        width: 45ch;
      }
      h2 {
        font-size: 2.5em;
      }
    }

    @media (max-width: 600px) {
      textarea {
        width: 90%;
        box-sizing: border-box;
      }

      textarea::placeholder {
        font-size: .8em;
      }

      h2 {
        font-size: 1.8em;
      }

      button {
        font-size: 1em;
      }
    }
  `}


      </style>

      <h2 style={{ color: "grey" }}>
        {languageSelectionMap[language]
          ? null
          : "In " + languageSelectionMap['English'][language.toLowerCase()] + " "}

        {createStoryMap[language]?.writingPromptDirections || createStoryMap['English'].writingPromptDirections.toLowerCase()}

      </h2>


      <form onSubmit={e => e.preventDefault()}>

        {/* <textarea
          type="text"
          placeholder={createStoryMap[language]?.placeholder || createStoryMap['English'].placeholder}
          rows="6"
          {...register('userRequest', { required: true, maxLength: 1000 })}
          style={{
            fontSize: '1.5em',
            fontFamily: 'Courier, monospace',
            boxSizing: 'border-box'
          }}
        /> */}

        <textarea
          type="text"
          placeholder={createStoryMap[language]?.placeholder || createStoryMap['English'].placeholder}
          rows="6"
          {...register('userRequest', { required: true, maxLength: 1000 })}
          style={{
            fontSize: '1.5em',
            fontFamily: 'Courier, monospace',
            boxSizing: 'border-box',
            backgroundColor: theme === "Christmas" ? "#ccffcc" : "white"  // Lighter green color
          }}
        />


        <br />

        <h2 style={{ color: "grey" }}>
          {createStoryMap[language]?.illustrationPrompt || createStoryMap['English'].illustrationPrompt}
        </h2>

        {/* <DesignChoicesDalle3
          language={language}
          illustrationType={illustrationType}
          setIllustrationType={setIllustrationType}
          illustrationObject={illustrationObject}   //**  1
          setIllustrationObject={setIllustrationObject}   //**  3
          color="orange"
          theme
        /> */}

        {theme === "Christmas" ?
          <DesignChoicesSpecial
            language={language}
            illustrationType={illustrationType}
            setIllustrationType={setIllustrationType}
            illustrationObject={illustrationObject}
            setIllustrationObject={setIllustrationObject}
            color="red"
            theme
          />
          :
          <DesignChoicesDalle3
            language={language}
            illustrationType={illustrationType}
            setIllustrationType={setIllustrationType}
            illustrationObject={illustrationObject}
            setIllustrationObject={setIllustrationObject}
            color="orange"
            theme
          />
        }

        <br />

        {firestoreUserData?.credit > 0
          ?

          // <div className="center">
          //   <SparkleButtonComponent text={createStoryMap[language]?.create || createStoryMap['English'].create} color="blue" />
          // </div>

          <Button
            onClick={handleSubmit(submitStoryPrompt)}
            size="massive"
            inverted
            type="submit"
            color="green"
            style={{
              fontSize: '1.5em',
              width: isMobile ? '75%' : '200px'
            }}
          >
            {createStoryMap[language]?.create || createStoryMap['English'].create}
          </Button>


          : <Button
            onClick={() => navigate('/payment')}
            size="massive"
            type="submit"
            color="orange"
            style={{
              fontSize: '1.5em',
              width: isMobile ? '75%' : '200px'
            }}
          >
            Add Credits
          </Button>}

        <br />
      </form>

      <br />
      <br />
      <Checkbox
        toggle
        label={theme === "Christmas" ? "🎄Christmas Mode" : "Regular Mode"}
        checked={theme === "Christmas"}
        onChange={handleThemeToggle}
        style={{
          fontSize: '1.5em',
          font: "green"
        }}

      />


    </>
  }

  const advancedStoryUI = () => {
    return <>
      <style>
        {`
    @media (min-width: 601px) {
      textarea {
        width: 45ch;
      }
      h2 {
        font-size: 2.5em;
      }
    }

    @media (max-width: 600px) {
      textarea {
        width: 90%;
        box-sizing: border-box;
      }

      textarea::placeholder {
        font-size: .8em;
      }

      h2 {
        font-size: 1.8em;
      }

      button {
        font-size: 1em;
      }
    }
  `}
      </style>

      <form onSubmit={e => e.preventDefault()}>
        <textarea
          type="text"
          placeholder={createStoryMap[language]?.placeholder || createStoryMap['English'].placeholder}
          rows="6"
          {...register('userRequest', { required: true, maxLength: 1000 })}
          style={{
            fontSize: '1.5em',
            fontFamily: 'Courier, monospace',
            boxSizing: 'border-box'
          }}
        />

        <br />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2
            data-border={true}
            onClick={chooseIllustrationTypeAdvanced_TEMP}
            // data-tip={"Toggle the " + ILLUSTRATION_STYLES.length + " styles"}
            size="tiny"
            style={{ fontSize: "2em", margin: "5px", cursor: 'pointer' }}
          >
            {createStoryMap[language]?.illustrationStyle || createStoryMap['English'].illustrationStyle} {illustrationType[1]}
          </h2>

          <img
            onClick={chooseIllustrationTypeAdvanced_TEMP}
            src={illustrationImages[illustrationType[0]]}
            // src={image4}
            alt="illustration"
          />
        </div>

        <br />

        {paidOptions}

        <div>
          <Button
            onClick={handleSubmit(submitStoryPrompt)}
            size="massive"
            inverted
            type="submit"
            color="blue"
            style={{ fontSize: '1.5em' }}
          >
            {createStoryMap[language]?.create || createStoryMap['English'].create}
          </Button>
        </div>
        <br />
        <br />
      </form>
    </>

  }


  if (!currentUser) {
    console.log("  ❌ CURRENT USER ❌  ", currentUser)
    return navigate("/")
  }

  // {createStoryMap[language]?.zzz || createStoryMap['English'].zzz}

  if (serverError) {
    return <Container>
      <h1 className="center">{createStoryMap[language]?.serversBusyErrorMessage || createStoryMap['English'].serversBusyErrorMessage}</h1>
      <Lottie animationData={errorCat} loop={true} style={{ height: 600, position: "relative" }} />
      <div className="center">
        <Button onClick={clearAllPageState} className="center" size="huge" color="black">{createStoryMap[language]?.continue || createStoryMap['English'].continue}</Button>
      </div>
    </Container>
  }

  if (errorMessage) {
    console.error("errorMessage", errorMessage)
    return (<>

      <h2>Hmmmm, we have had an error:{errorMessage}</h2>
      <h2>However, you can save what you have and fix it later</h2>


      {responseAI && <CurrentStoryDisplayDalle3

        size="100px"
        text={storyText1 ? storyText1 : "No story text"}
        title={storyTitle2 ? storyTitle2 : "No title"}
        summary={storySummary3 ? storySummary3 : "No summary"}
        imageURL={storyImageURL5 ? storyImageURL5 : "No URL"}

        responseAI={responseAI}
        keeper={keeperDalle}
        clearAllPageState={clearAllPageState}
        redoPicture={redoPicture}
        wordBank={wordBankArray}
        language={language}

      />}
    </>)
  }

  if (keeperIsSaving) {
    return <Container>
      <Lottie animationData={savingDots} loop={true} style={{ height: 600, position: "relative" }} />
    </Container>
  }

  if (isLoadingPart1)
    return <Container>
      <div
        onClick={() => setOpenAbort1Modal(true)}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }} >
        <h1>
          {userStoryPrompt}
        </h1>
      </div>
      <Lottie animationData={coloredCircleAnimation} loop={true} style={{ height: 600, position: "relative" }} />

      {showAbortPart1Button &&
        <div className="center" onClick={() => setOpenAbort1Modal(true)} >
          <ModalCancelPart1 />
          <Icon color="black" inverted circular name='cancel' size="large" />
        </div>}

    </Container>



  return (
    <Container>

      <ToastContainer />
      <ReactTooltip delayShow={1000} />
      <div className="App">
        <body>

          {responseAI
            ? <CurrentStoryDisplayDalle3
              setOpenAbort2Modal={setOpenAbort2Modal}
              confirmStoryCancel={confirmStoryCancel}
              openAbort2Modal={openAbort2Modal}
              size="100px"
              text={storyText1}
              title={storyTitle2}
              summary={storySummary3}
              imageURL={storyImageURL5}
              keeper={keeperDalle}
              responseAI={responseAI}
              clearAllPageState={clearAllPageState}
              redoPicture={redoPicture}
              wordBank={wordBankArray}
              language={language}
            />
            :
            <>
              <div>
                <Lottie
                  animationData={animatedRobot}
                  loop={true}
                  style={robotStyle} />
              </div>

              <h6 style={{ fontSize: ".6em", margin: "0px" }}> Model: {chatModelVersion} / Dalle-3</h6>

              {showAdvancedUI ? advancedStoryUI() : basicStoryUI()}


              {/* {showAdvancedUI
                ?
                <h4 style={{ color: "blue", margin: "2px" }}>  {createStoryMap[language]?.advancedInterface || createStoryMap['English'].advancedInterface}</h4>

                :
                <h4>{" "}</h4>

              } */}
              {showAdvancedUI
                ?
                <h4 style={{ color: "blue", margin: "2px" }}>  {createStoryMap[language]?.advancedInterface || createStoryMap['English'].advancedInterface}</h4>

                :
                <h4>{" "}</h4>

              }



            </>
          }
        </body>
      </div >
    </Container>
  )
}

export default CreateDalle3

