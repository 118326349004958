import React, { useEffect, useState } from 'react'
import { collection, onSnapshot } from "firebase/firestore"
// deleteDoc, doc

export default function DisplayFirestoreImageUser(props) {
  const { db, userId, size, showStory } = props;
  const [storyImages, setStoryImages] = useState(null)

  useEffect(() => {
    const storiesCollectionRef = collection(db, "users", userId, "stories")
    onSnapshot(storiesCollectionRef, (snapshot) => {
      let dbStories = []

      snapshot.docs.forEach((doc) => {
        const docData = doc.data()

        // console.log("🌷🌷🌷🌷 =======>>> docData.archived", docData.title, docData.archived)

        const slimmedDownData = {
          title: docData.title,
          storyId: docData.storyId,
          fullsizeImage: docData.imageDownloadURL,
          thumbnailImage: docData.imageThumbDownloadURL,
          mediumImage: docData.imageMediumDownloadURL,
          bucketFullPath: docData.bucketFullPath,
          dateCreated: docData.dateCreated
        }

        if (!docData.archived) {
          dbStories.push(slimmedDownData)
        }


      })

      // oldest on top
      dbStories.sort((a, b) => {
        return new Date(a.dateCreated?.seconds * 1000) - new Date(b.dateCreated?.seconds * 1000);
      });

      // newest on top
      // dbStories.sort((a, b) => {
      //   return new Date(b.dateCreated.seconds * 1000) - new Date(a.dateCreated.seconds * 1000);
      // });

      setStoryImages(dbStories)
    })

  }, [])


  const getImageSource = ({ thumbnailImage, mediumImage, fullsizeImage }) => {
    if (thumbnailImage) return thumbnailImage;
    if (mediumImage) return mediumImage;
    return fullsizeImage;
  };

  return (
    <>
      <div className="center-images">
        {storyImages?.map(({ fullsizeImage, mediumImage, thumbnailImage, storyId, title, archived }) => {
          return (
            <img
              key={storyId}
              onClick={() => showStory(storyId)}
              src={getImageSource({ thumbnailImage, mediumImage, fullsizeImage })}
              height={size}
              width={size}
              alt=""
            />
          );
        })}
      </div>
    </>
  );

}


