import React from 'react';
import { Container } from 'semantic-ui-react';

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="privacy-policy" >
        <h1 style={{ fontSize: "3em", marginBottom: '6px' }} className="center" >Picture Books Privacy Policy</h1>
        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to PictureBooks.ai (“PictureBooks") operated by Beechtree LLC and governs data collection and usage. The PictureBooks website and other services (the “Services”) provided by Beechtree LLC are platforms that allow users to create, customize and order picture books. We need to process your personal information to run our business and provide you with the Services. By accepting our Terms of Use, you are confirming that you have read and understand this policy including how and why we use your information. If you don’t want us to collect or process your personal information in the ways described in this policy, you shouldn’t use the Services. By using the Services, you consent to the data practices described in this statement.
        </p>
        <p>
          PictureBooks does not knowingly collect or solicit personal information from children under 13 without parental consent. If you are under 13, you may use PictureBooks only with the involvement of a parent or guardian.
        </p>


        <p>
          By using the Services, you acknowledge that BeechTree LLC will use your information in the United States and any other country where BeechTree LLC operates. Please be aware that the privacy laws and standards in certain countries, including the rights of authorities to access your personal information, may differ from those that apply in the country in which you reside. We will transfer personal information only to these countries to which we are permitted by law to transfer personal information, and we will take steps to ensure that your personal information continues to enjoy appropriate protections.
        </p>
        <p>
          PictureBooks does not knowingly collect or solicit personal information from children under 13 without parental consent. If you are under 13, you may use PictureBooks only with the involvement of a parent or guardian.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Collection of your Personal Information</strong></h1>
        <p>
          PictureBooks may collect and store personal information, such as your name and email address. If you purchase PictureBooks’ products and services, billing and credit card information may be necessary in order for us to provide a particular service. We may gather additional personal or non-personal information that you may provide to us in the future.
        </p>
        <p>
          Information about your computer hardware and software may be automatically collected by PictureBooks. This information can include: your IP address, browser type, domain names, access times and referring website addresses. We may combine this information from your browser or your mobile device with other information that we or our partners collect about you, including across devices. This information is used to prevent fraud and to keep the Services secure, operate the services, to maintain quality of the service, and to provide general statistics regarding use of the PictureBooks website.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Use of your Personal Information</strong></h1>
        <p>
          PictureBooks collects, stores and uses your personal information to operate the Service and deliver the services you have requested.
        </p>
        <p>
          PictureBooks does not sell, rent or lease its customer lists to third parties.
        </p>
        <p>
          PictureBooks may share data with trusted partners to help perform statistical analysis, to facilitate email communications or to provide customer support. All such third parties are prohibited from using your personal information except to provide these services to PictureBooks, and they are required to maintain the confidentiality of your information.
        </p>
        <p>
          PictureBooks will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on PictureBooks or the site; (b) protect and defend the rights or property of PictureBooks; and, (c) act under exigent circumstances to protect the personal safety of users of PictureBooks, or the public.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Choice and Control</strong></h1>
        <p>
          Depending on your location, you may also have certain additional rights with respect to your information, such as: (i) data access and portability (including the right to obtain a copy of your personal data you provided to PictureBooks, via your settings); (ii) data correction (including the ability to update your personal data); (iii) data deletion (including the right to have PictureBooks delete your personal information, except information we are required to retain, by contacting us); and (iv) withdrawal of consent or objection to processing (including, in limited circumstances, the right to ask PictureBooks to stop processing your personal data, with some exceptions, by contacting us).
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Compliance with Children’s Online Privacy Protection Act (COPPA)</strong></h1>
        <p>
          As part of our compliance with the Children's Online Privacy Protection Act (COPPA), we adhere to the guidelines and restrictions regarding the collection, use, and disclosure of personal information from children under 13. Our services are not intended for children under 13 and we do not knowingly collect personal information from children under 13 without obtaining verifiable parental consent.
        </p>
        <p>
          If we become aware that a user is under 13 and has provided us with personal information without parental consent, we will take steps to remove such information from our databases. Parents or guardians who are aware that their child has provided us with personal information without their consent are encouraged to contact us. Upon verification of the information, we will promptly delete the child’s information from our records.
        </p>
        <p>
          By using the Services, you acknowledge that Beechtree LLC will use your information in the United States and any other country where Beechtree LLC operates. Please be aware that the privacy laws and standards in certain countries, including the rights of authorities to access your personal information, may differ from those that apply in the country in which you reside. We will transfer personal information only to these countries to which we are permitted by law to transfer personal information, and we will take steps to ensure that your personal information continues to enjoy appropriate protections.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Use of Cookies</strong></h1>
        <p>
          The PictureBooks website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Opt-Out & Unsubscribe</strong></h1>
        <p>
          PictureBooks may send you messages about the Services or your activity. Some of these messages are required, service-related messages for members (such as transactional messages or legal notices). Other messages are not required, such as newsletters. We respect your privacy and give you an opportunity to opt-out of receiving marketing communications. Users may opt-out of receiving marketing communications from PictureBooks by clicking the “unsubscribe” link embedded within the communication you receive.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Changes to this Statement</strong></h1>
        <p>
          PictureBooks will occasionally update this Statement of Privacy to reflect company and customer feedback. PictureBooks encourages you to periodically review this Statement to be informed of how PictureBooks is protecting your information.
        </p>
        <h1 style={{ marginBottom: '6px' }}><strong>Contact Information</strong></h1>
        <p>
          PictureBooks welcomes your questions or comments regarding this Statement of Privacy. For inquiries or concerns regarding PictureBooks’ privacy practices, please contact PictureBooks at <a href="mailto:support@beechtree.ai">support@beechtree.ai</a>.

        </p>
        <p>
          Last updated: October 16, 2023
        </p>
      </div>
    </Container>);
};

export default PrivacyPolicy;
