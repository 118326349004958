// StripeCheckoutForm.jsx

import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { setDoc, arrayUnion, getDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { increment } from "firebase/firestore"; // Add this import
import Lottie from "lottie-react";
import styles from "./StripeCheckoutForm.module.css";
import loadingSimple from '../../images/loading-simple.json';


function StripeCheckoutForm({ db, firestoreUserData, currentUser, onSuccess, onFailure, setShowNavbar, loading, setLoading, setProcessingPayment }) {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPackage, setSelectedPackage] = useState("20");
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal visibility
  const [cardLastFour, setCardLastFour] = useState(''); // State to store last four digits of the card



  const updateUserDoc = async (creditsToAdd, transactionRecord) => {
    const docRef = doc(db, "users", currentUser?.uid);

    try {
      // Use a regular timestamp first
      const newTransactionRecord = {
        ...transactionRecord,
        timestamp: new Date() // JavaScript Date object as a placeholder
      };

      // Update the document with the new transaction record and incremented credits
      await updateDoc(docRef, {
        credit: increment(creditsToAdd),
        transactions: arrayUnion(newTransactionRecord)
      });

      console.log("User document updated with new credit and transaction.");
    } catch (error) {
      console.error("Error updating user document:", error);
    }
  };



  const creditPackages = [
    { credits: 6, price: 2 },
    { credits: 20, price: 5 },
    { credits: 50, price: 10 },
    { credits: 150, price: 25 },
    { credits: 400, price: 50 },
  ];

  // Modified handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);

    const cardElement = elements.getElement(CardElement);
    // Retrieve card details (if available) for the modal
    if (cardElement) {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      setCardLastFour(paymentMethod?.card?.last4 || 'xxxx');
    }
  };


  const processPayment = async () => {

    const baseUrl = process.env.REACT_APP_API_URL;

    if (!stripe || !elements) {
      return;
    }

    setShowNavbar(false);
    setLoading(true);
    setProcessingPayment(true)
    console.log("loading2", loading)
    setError(null);

    const cardElement = elements.getElement(CardElement);

    const customerInfo = {
      name: firestoreUserData.userDisplayName,
      email: firestoreUserData.email
    };

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (stripeError) {
      onFailure(stripeError.message);
      return;
    }

    const packagePrice = findPackagePrice();

    const response = await fetch(`${baseUrl}/stripe/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        amount: packagePrice * 100, // convert to cents
        currency: "usd",
        description: `Purchase of ${selectedPackage} credits`,
        customerInfo,
      }),
    }).catch(error => {
      setError("Network error: " + error.message);

      onFailure("Network error: " + error.message)

      setLoading(false);
      setProcessingPayment(false);
      return;
    });

    if (!response) return;

    const responseData = await response.json();

    if (responseData.error) {
      setError(responseData.error.message);
      onFailure(responseData.error.message)

      setLoading(false);
      setProcessingPayment(false);
      return;
    }



    const { clientSecret } = responseData; // Correctly extracting the clientSecret

    if (!clientSecret) {
      setError("Failed to retrieve payment details.");
      setLoading(false);
      setProcessingPayment(false);
      return;
    }


    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.id,
    });

    if (result.error) {
      onFailure(result.error.message);
      return;
    }

    const paymentIntent = result.paymentIntent;


    // Update the user's credit upon successful payment
    const creditsToAdd = parseInt(selectedPackage);

    const transactionRecord = {
      amount: packagePrice,
      creditsAdded: parseInt(selectedPackage),
      paymentMethodType: paymentMethod.card.brand,
      lastFourDigits: paymentMethod.card.last4,
      paymentIntentId: paymentIntent.id,
      status: paymentIntent.status
      // ...any other transaction details you want to record
    };

    await updateUserDoc(creditsToAdd, transactionRecord);


    setLoading(false);
    onSuccess(creditsToAdd);
    setProcessingPayment(false)

  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  const findPackagePrice = () => {
    const selected = creditPackages.find((pkg) => pkg.credits === parseInt(selectedPackage));
    return selected ? selected.price : 0;
  };


  return (
    <>
      <form onSubmit={handleSubmit} className={styles["credit-card-form"]}>
        {loading && <Lottie animationData={loadingSimple} loop={true} style={{ height: 300, position: "relative" }} />}

        {/* 
         //  why is loading true?
        {loading 
          ? <Lottie animationData={loadingSimple} loop={true} style={{ height: 300, position: "relative" }} />
          : <h2>NO</h2>} */}

        <label htmlFor="creditPackage" className={styles["text-18px"]}>
          Choose a credit package:
        </label>
        <select
          name="creditPackage"
          id="creditPackage"
          value={selectedPackage}
          onChange={handlePackageChange}
          className={styles["text-18px"]}
        >
          {creditPackages.map((pkg, index) => (
            <option key={index} value={pkg.credits}>
              {pkg.credits} credits for ${pkg.price}
            </option>
          ))}
        </select>
        <div className={`${styles["total-price"]} ${styles["text-18px"]}`}>
          Total: ${findPackagePrice()}
        </div>
        <div className={`${styles["card-element"]} ${styles["text-18px"]}`}>
          <CardElement options={{ style: { base: { fontSize: "18px" } } }} />

        </div>
        <button className={styles["submit-button"]} type="submit" disabled={!stripe}>
          {loading ? "Processing..." : "Pay"}
        </button>
        {error && <div className={styles["error-message"]}>{error}</div>}

      </form>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>Confirm Payment</Modal.Header>
        <Modal.Content>
          <p>Confirm the purchase of {selectedPackage} credits for ${findPackagePrice()} on the credit card ending {cardLastFour}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button color='green' onClick={() => {
            setIsModalOpen(false);
            processPayment();
          }}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default StripeCheckoutForm;