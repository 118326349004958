import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

const checkDeletedUser = async (email) => {
  const q = query(collection(db, 'users-deleted'), where('email', '==', email));
  const querySnapshot = await getDocs(q);
  // console.log("querySnapshot==>", querySnapshot)
  return !querySnapshot.empty;
};

export default checkDeletedUser;