import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import Lottie from 'lottie-react'
import faq from '../../images/faq.json'
import { aboutMap } from '../../constants/siteTextMap';

// {aboutMap[language]?.create || aboutMap['English'].create}

function About(props) {
  const { currentUser, firestoreUserData } = props
  let navigate = useNavigate()
  const [language, setStoryLanguage] = useState(firestoreUserData?.currentLanguage ? firestoreUserData.currentLanguage : "English")


  if (!currentUser) { return navigate("/") }

  return (
    <Container>
      <h2 className="center" style={{ fontSize: "3.5em" }}>
        {aboutMap[language]?.commonQuestions || aboutMap['English'].commonQuestions}</h2>
      <Lottie
        style={{ height: 200 }}
        animationData={faq}
        loop={false} />

      <h2>{aboutMap[language]?.whatIsAI_heading || aboutMap['English'].whatIsAI_heading}</h2>
      <h3 >{aboutMap[language]?.whatIsAI_text || aboutMap['English'].whatIsAI_text}</h3>
      <br />


      <h2 >{aboutMap[language]?.isThisChatGpt_heading || aboutMap['English'].isThisChatGpt_heading}</h2>
      <h3>{aboutMap[language]?.isThisChatGpt_text || aboutMap['English'].isThisChatGpt_text}
      </h3>
      <br />


      <h2 >{aboutMap[language]?.isItEthical_heading || aboutMap['English'].isItEthical_heading}</h2>
      <h3>{aboutMap[language]?.isItEthical_text || aboutMap['English'].isItEthical_text}</h3>
      <br />


      <h2 >{aboutMap[language]?.canISell_heading || aboutMap['English'].canISell_heading}</h2>
      <h3>
        {aboutMap[language]?.canISell_text || aboutMap['English'].canISell_text}
      </h3>
      <br />



    </Container>
  )
}

export default About
