import { Container } from 'semantic-ui-react';


const StoryTextDisplayWrapper = (props) => {
  const { storyText, wordBank } = props;
  const storyTextArray = storyText?.split(/(\s+|\n+)/);
  const wordBankWord = { color: 'green', cursor: 'pointer', fontSize: '1.5em', fontWeight: 900 };
  const wordStyle = { color: 'black', fontSize: '1.5em', cursor: 'pointer' };

  // Validates wordBank format
  const isValidWordBank = Array.isArray(wordBank) && wordBank.every(wb => wb.hasOwnProperty('word'));

  // Update checkDictionary to search in wordBank objects
  function checkDictionary(word) {
    if (!isValidWordBank) return false; // Early return if wordBank is not valid

    let cleanWord = word.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ').toLowerCase();
    // Search for cleanWord in wordBank objects
    const wordExists = wordBank.some(wb => wb.word.toLowerCase() === cleanWord);
    if (wordExists) {
      return true;
    }
    // Check for singular form if word ends in 's'
    if (cleanWord.endsWith('s')) {
      let singularWord = cleanWord.slice(0, -1);
      return wordBank.some(wb => wb.word.toLowerCase() === singularWord);
    }
    return false;
  }




  return (


    <h6 className="story-text-wrapper">
      {storyTextArray.map((text, index) => {
        if (text === '\n') {
          return <br key={index} />;
        } else if (text.match(/^\s+$/)) { // Matches whitespace only
          // Apply a style that preserves whitespace but allows wrapping
          return (
            <span
              key={index}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {text}
            </span>
          );
        } else { // For actual text content
          // Apply word bank styles or default text styles as before
          return (
            <span key={index} style={isValidWordBank && checkDictionary(text) ? wordBankWord : wordStyle}>
              {text}
            </span>
          );
        }
      })}
    </h6>

  );

}

export default StoryTextDisplayWrapper;
