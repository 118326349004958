import React, { useState, useEffect } from 'react';
import { Button, Container, Icon } from 'semantic-ui-react';

import Section1 from './NewUserIntro/Section1'
import Section2 from './NewUserIntro/Section2'
import Section3 from './NewUserIntro/Section3'
import Section4 from './NewUserIntro/Section4'
import Section5 from './NewUserIntro/Section5'
import Section6 from './NewUserIntro/Section6'
import Section7 from './NewUserIntro/Section7'

const Introduction = () => {
  const initialStep = Number(localStorage.getItem('currentStep')) || 1;
  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    localStorage.setItem('currentStep', step);
    return () => {
      localStorage.removeItem('currentStep');
    };
  }, [step]);

  const proceed = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goBack = () => { setStep((prevStep) => prevStep - 1) };

  return (
    <Container>
      {step === 1 && <Section1 />}
      {step === 2 && <Section2 />}
      {step === 3 && <Section3 />}
      {step === 4 && <Section4 />}
      {step === 5 && <Section5 />}
      {step === 6 && <Section6 />}
      {step === 7 && <Section7 />}

      <div>
        {step > 1 && step < 7 && (
          <Button basic color="black" icon onClick={goBack}>
            <Icon name='arrow left' />
          </Button>
        )}
        {step === 1 && <Button basic color="black" onClick={proceed}>Next</Button>}
        {step > 1 && step < 7 && (
          <Button basic color="black" icon onClick={proceed}>
            <Icon name='arrow right' />
          </Button>
        )}
        {step === 7 && <Button color="green" onClick={proceed}>Start Creating Stories</Button>}
      </div>
    </Container>
  );
};

export default Introduction;
