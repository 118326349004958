// PaymentSuccess.jsx

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "semantic-ui-react"
import Confetti from 'react-dom-confetti';
import Lottie from "lottie-react";
import succesGraphic from '../../images/success-graphic.json';

function PaymentSuccess({ currentUser, imageApi, setShowNavbar }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showConfetti, setShowConfetti] = useState(false)
  const newCredits = location.state?.newCredits || 0;
  const paymentCompleted = location.state?.paymentCompleted || false;


  const handleButtonClick = () => {
    setShowNavbar(true)
    // navigate(imageApi === "Dalle3" ? "/createdalle3" : "/createstablediff")
    navigate("/createthemedstory")
  };


  useEffect(() => {
    setShowNavbar(false)
  }, [])



  useEffect(() => {
    if (paymentCompleted) {
      setShowConfetti(true);

      // Disable back navigation by pushing the same state again
      window.history.pushState(null, document.title, window.location.href);

      const onBackButtonEvent = (e) => {
        e.preventDefault();
        navigate('/home');  // Navigate to home or some other page
      };

      window.addEventListener('popstate', onBackButtonEvent);

      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    } else {
      navigate('/home');  // Navigate away if paymentCompleted flag is not set or is false
    }
  }, [paymentCompleted]);





  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };



  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Confetti active={showConfetti} config={config} />
      <Container>
        <br />
        <h2
          className="center"
          style={{ fontSize: "2.5em" }}
        >{`Thank you for your purchase of ${newCredits} credits!`}</h2>

        <div className="center">
          <Lottie animationData={succesGraphic} loop={false} style={{ height: 200, position: "relative" }} />
        </div>

        <div className="center">
          <Button size="huge"
            color="green"
            onClick={handleButtonClick}
          >Create Stories!
          </Button>
        </div>
      </Container>

    </>
  );
}

export default PaymentSuccess;
