// useFetchPrompts.js
import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, doc, getDoc } from 'firebase/firestore';

export default function useFetchPrompts() {
  const [illustrationPromptsFirebase, setIllustrationPromptsFirebase] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(collection(db, 'picturebooks-settings'), 'prompts2');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIllustrationPromptsFirebase(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return illustrationPromptsFirebase;
}
