import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

const useGetStory = (userId, db, storyId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId && storyId) {
      let isMounted = true;

      const docRef = doc(db, 'users', userId, 'stories', storyId);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (isMounted) {
          const response = docSnap.data();
          setData(response);
          setError(null);
          setLoading(false);
        }
      }, (error) => {
        if (isMounted) {
          setError(error);
          setData(null);
          setLoading(false);
        }
      });

      return () => {
        isMounted = false;
        unsubscribe(); // Unsubscribe from updates when component unmounts
      };
    }
  }, [userId, db, storyId]);

  return { loading, error, data };
};

export default useGetStory;
