import React from 'react'
import { Button } from 'semantic-ui-react'
import { signOut } from "firebase/auth";

export default function Logout(props) {
  const { auth, color = "black", setImageUrls, setFirestoreUserData } = props

  const handleClick = () => {
    setFirestoreUserData(null)

    signOut(auth)
      .then(() => {
        setImageUrls(() => [])
        setFirestoreUserData(null)
      })

      .catch((err) => console.error(err.message))
  }

  return (
    <Button
      size="medium"
      color={color}
      onClick={handleClick}>
      Signout
    </Button>
  )
}
