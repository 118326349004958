import React, { createContext, useState, useContext } from 'react';

const StoryContext = createContext();

export const useStory = () => useContext(StoryContext);

export const StoryProvider = ({ children }) => {
  const [storyData, setStoryData] = useState({});

  const setStory = (data) => {
    setStoryData(data);
  };

  return (
    <StoryContext.Provider value={{ storyData, setStory }}>
      {children}
    </StoryContext.Provider>
  );
};
