// updateStoryImage.js

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";

const storage = getStorage();

const updateStoryImage = async ({
  currentUser,
  storyId,
  db,
  updatedFields,

  setShowSavingGraphic,
  setShowNavbar,
  setSavingStory,
  setServerError,
  setSuccessfulSave
}) => {

  if (!currentUser) return null

  setSavingStory(true)
  setShowSavingGraphic(true)
  setShowNavbar(false)

  try {
    const suffixId = "_picturebook_DEC2022.png";
    const imageFilePath = `images/USERSET_A_${currentUser.uid}/${storyId}${suffixId}`;
    const imageThumbFilePath = `images/USERSET_A_${currentUser.uid}/thumb_${storyId}${suffixId}`;
    const imageMediumFilePath = `images/USERSET_A_${currentUser.uid}/medium_${storyId}${suffixId}`;

    const imageDownloadURL = await getDownloadURL(ref(storage, imageFilePath));
    const downloadThumbURL = await getDownloadURL(ref(storage, imageThumbFilePath));
    const downloadMediumURL = await getDownloadURL(ref(storage, imageMediumFilePath));

    const storyRef = doc(db, "users", currentUser.uid, "stories", storyId);

    const updatedFieldsWithTimestamp = {
      ...updatedFields,
      retryImageGeneration: false,
      imageDownloadURL, // Update the original image URL
      imageThumbDownloadURL: downloadThumbURL, // Update the thumbnail URL
      imageMediumDownloadURL: downloadMediumURL, // Update the medium image URL
      dateModified: serverTimestamp()
    };

    await updateDoc(storyRef, updatedFieldsWithTimestamp);

    console.log("Story updated successfully.");

    setSavingStory(false)
    setSuccessfulSave(true);
    setShowNavbar(true);

  } catch (error) {
    console.error("Error updating story:", error);

    setShowSavingGraphic(false);
    setShowNavbar(true);
    setServerError(true);
    throw error;
  }
};

export default updateStoryImage;
