// readingAdventureTeacherClassroomsService.js 

import { getFirestore, doc, setDoc, getDocs, collection, addDoc } from 'firebase/firestore';

const db = getFirestore();

export async function getTeacherClassrooms(userId) {
  const classroomsSnapshot = await getDocs(collection(doc(db, 'users', userId), 'classrooms'));
  return classroomsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function updateCurrentClassroom(userId, classroomId) {
  await setDoc(doc(db, 'users', userId), { readingAdventureTeacherClassroomCurrentId: classroomId }, { merge: true });
}

// export async function createNewClassroom(userId, newClassroomData) {
//   await addDoc(collection(doc(db, 'users', userId), 'classrooms'), newClassroomData);
// }



export async function createNewClassroom(userId, newClassroomData) {
  const classroomId = newClassroomData.classroomId;
  await setDoc(doc(db, 'users', userId, 'classrooms', classroomId), newClassroomData);
}
