// BasicStoryEnhance.jsx

import React from 'react';
import { Button } from 'semantic-ui-react';
import { createStoryMap } from '../../../constants/siteTextMap';
import { useForm } from 'react-hook-form';
import DesignChoicesSpecial from '../../general/DesignChoicesSpecial';
import Lottie from "lottie-react";
import animatedRobot from '../../../images/99973-little-power-robot.json';
import ThemeToggleCheckbox from './ThemeToggleCheckbox';

const BasicStoryEnhance = ({
  firestoreUserData,
  language,
  submitStoryPrompt,
  isMobile,
  illustrationType,
  setIllustrationType,
  illustrationObject,
  setIllustrationObject,
  theme,
  paymentRequired,
  selectedThemeKey,
  toggleTheme,
  themes
}) => {
  const { register, handleSubmit } = useForm({});
  const robotStyle = { height: 200 };

  console.log("language", language)

  console.log("theme", theme)

  const themeTitle = theme?.multilingualTitle && language !== 'english'
    ? theme?.multilingualTitle[language.toLowerCase()]
    : theme?.title;

  console.log("themeTitle", themeTitle)
  // const themeTitle = theme?.multilingualTitle && language !== 'english'
  //   ? theme?.multilingualTitle[language] || theme?.title
  //   : theme?.title;

  return (
    <>
      <style>
        {`
          @media (min-width: 601px) {
            textarea {
              width: 45ch;
            }
            h2 {
              font-size: 3.5em;
            }
          }

          @media (max-width: 600px) {
            textarea {
              width: 90%;
              box-sizing: border-box;
            }

            textarea::placeholder {
              font-size: .8em;
            }

            h2 {
              font-size: 1.8em;
            }

            button {
              font-size: 1em;
            }
          }
        `}
      </style>
      <div>

        <h2 className="center">
          {/* {createStoryMap[language]?.writingPromptDirections || createStoryMap['English'].writingPromptDirections} */}
          Enhance my story!
        </h2>
        <Lottie
          animationData={animatedRobot}
          loop={true}
          style={robotStyle}
        />

        <ThemeToggleCheckbox
          selectedThemeKey={selectedThemeKey}
          toggleTheme={toggleTheme}
          themes={themes}
        />

        <div className="center">
          <form onSubmit={handleSubmit(submitStoryPrompt)}>
            <textarea
              type="text"
              placeholder={createStoryMap[language]?.placeholder || createStoryMap['English'].placeholder}
              rows="6"
              {...register('userRequest', { required: true, maxLength: 1000 })}
              style={{
                fontSize: '1.5em',
                fontFamily: 'Courier, monospace',
                boxSizing: 'border-box'
              }}
            />
            <br />

            <DesignChoicesSpecial
              language={language}
              illustrationType={illustrationType}
              setIllustrationType={setIllustrationType}
              illustrationObject={illustrationObject}
              setIllustrationObject={setIllustrationObject}
              color={theme?.style?.color}
              theme={theme}
            />

            <br />

            {firestoreUserData?.credit > 0
              ?

              // <div className="center">
              //   <SparkleButtonComponent text={createStoryMap[language]?.create || createStoryMap['English'].create} color="blue" />
              // </div>

              <Button
                onClick={handleSubmit(submitStoryPrompt)}
                size="massive"
                inverted
                type="submit"
                color="green"
                style={{
                  fontSize: '1.5em',
                  width: isMobile ? '75%' : '200px'
                }}
              >
                {createStoryMap[language]?.create || createStoryMap['English'].create}
              </Button>


              : <Button
                onClick={paymentRequired}
                size="massive"
                type="submit"
                color="orange"
                style={{
                  fontSize: '1.5em',
                  width: isMobile ? '75%' : '200px'
                }}
              >
                Add Credits
              </Button>}



          </form>
        </div>
      </div>
    </>
  );
};

export default BasicStoryEnhance;
