import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Billing(props) {
  const { currentUser } = props

  let navigate = useNavigate()

  if (!currentUser) { return navigate("/") }


  return (<>
    <h1>Billing</h1>

  </>
  )
}

export default Billing