import React from 'react'
import { Button, Container } from 'semantic-ui-react'


function NewUserDeclined() {
  return (<Container>
    <h1>Sorry, but this email has been used in the past to set up a PictureBook account. Email support@picturebooks.ai if you believe this is an error.</h1>
    <Button onClick>REturn to Login</Button>
  </Container>
  )
}

export default NewUserDeclined