// adventureStoryService.js

import { db } from "../../firebase";
import { doc, getDoc, updateDoc, deleteDoc, serverTimestamp, collection, query, getDocs } from "firebase/firestore";


export async function fetchAdventureStory(adventureId) {

  const docRef = doc(db, "adventures-stories", adventureId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let adventureData = docSnap.data();

    // Fetch 'readersData' subcollection
    const readersDataCollectionRef = collection(docRef, 'readersData');
    const readersDataQuerySnap = await getDocs(readersDataCollectionRef);
    adventureData.readersData = readersDataQuerySnap.docs.reduce((acc, doc) => {
      if (doc.id === 'readerDisplayName') {
        // Skip this document
        return acc;
      }

      const dataArray = doc.data();
      // Here, we set the key as the doc ID and the value as the dataArray itself
      acc[doc.id] = dataArray;

      return acc;
    }, {});

    return adventureData;
  } else {
    console.log("No such document!");
    return null;
  }

}



export async function updateAdventureStory(adventureId, updatedFields) {
  const adventureStoryRef = doc(db, "adventures-stories", adventureId);

  console.log('Updating adventure:', adventureId, 'with data:', updatedFields);

  try {
    await updateDoc(adventureStoryRef, {
      ...updatedFields,
      dateEdited: serverTimestamp()
    });

    console.log("Adventure Story updated successfully");
  } catch (error) {
    console.error("Error updating adventure:", error);
    console.error("Error Code: ", error.code);
    console.error("Error Message: ", error.message);
  }
}




export async function deleteAdventureStory(adventureStoryId) {
  const adventureStoryRef = doc(db, "adventures-stories", adventureStoryId);

  try {
    await deleteDoc(adventureStoryRef);
    console.log("Adventure Story deleted successfully");
  } catch (error) {
    console.error("Error deleting adventure:", error);
  }
}


