// index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { db, auth, onAuthStateChanged } from './firebase'
//analytics, storage,  app, 

console.log("👛👛👛👛 RUNNING", process.env.REACT_APP_FIREBASE_PROJECT_ID)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App
    auth={auth}
    db={db}
    onAuthStateChanged={onAuthStateChanged}
  />
);

// <React.StrictMode>
// </React.StrictMode>
