// StoryDisplayMain.jsx

import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase.js';
import { useParams, useNavigate } from 'react-router-dom';
import useGetStory from '../hooks/useGetStory.js';
import { Container, Button, Icon } from 'semantic-ui-react';
import updateStory from '../api/updateStory.js';
import graphicQR from '../../images/ui/qr-code.png'
import testImage from '../../images/ui/test.png'
import graphicExport from '../../images/ui/export.png'
import document from '../../images/ui/document.png'

// import deleteItem from '../api/deleteStory.js'

import Lottie from 'lottie-react';
import simpleLoader from '../../images/simpleLoader.json';
import trashBin from '../../images/111436-move-to-trash.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../styles/StoryDisplay.css"

import generateNewStaticLink from "../general/generateNewStaticLink.js"

import DisplayLinkQrEdit from '../general/DisplayLinkQrQuizEdit.jsx';
import StoryTextDisplayWrapper from './CreateComponents/StoryTextDisplayWrapper.jsx';
import generatePDF from '../helpers/generatePDF.js'
import { story2Map } from '../../constants/siteTextMap.js'
import StoryResources from './StoryResources.jsx';

// import { readingLevel } from '../helpers/readingLevel';

const readingLevel = null
const baseUrl = process.env.REACT_APP_API_URL;

const GoPremium = () => {
  const navigate = useNavigate();

  return (

    <div className='center'>
      <Button
        color="yellow"
        size="tiny"
        onClick={() => navigate('/upgradeplan')}>
        Go Premium
      </Button>
    </div>
  );
};

function splitWords(text) {
  if (!text) return [null, null];
  return [text.split(/(\s+)/), text.split(/(\s+)/).length];
}

const StoryDisplayMain = (props) => {
  let navigate = useNavigate();
  const [storyWordDictionary, setStoryWordDictionary] = useState({});
  const [wordBankDictionary, setWordBankDictionary] = useState({});
  //const [wordCount, setWordCount] = useState(null);

  const [linkCopied, setLinkCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeSize, setQrCodeSize] = useState(128);

  // const [deleteStorySuccessful, setDeleteStorySuccessful] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  const [generatedStaticLink, setGeneratedStaticLink] = useState('');
  const [loadingStaticLink, setLoadingStaticLink] = useState(false)
  const [showStoryDetails_A, setShowStoryDetails_A] = useState(false);
  const [showStoryDetails_B, setShowStoryDetails_B] = useState(false);

  const [showTestMaker, setShowTestMaker] = useState(true);
  const [currentlyGeneratingQuestion, setCurrentlyGeneratingQuestion] = useState(false);
  const [shareStory, setShareStory] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visibleAnswer, setVisibleAnswer] = useState(null);
  const [premiumFeatures, setPremiumFeatures] = useState(null);

  let { storyId } = useParams();

  const { currentUser,
    //chatModelVersion, 
    firestoreUserData } = props;

  const currentLanguage = firestoreUserData?.currentLanguage


  const { loading, error, data } = useGetStory(currentUser?.uid, db, storyId);

  const chatModelVersion = "gpt-4-1106-preview"
  const imageGeneration = "Dalle-3"


  const { title, prompt, text, imageDownloadURL, imageMediumDownloadURL, wordBank, ageLevel, summary, characters, language, languageEmoji, dateCreated, dateModified, quizArray = [], illustrationObject = null, retryImageGeneration, vocabulary, conflict, resolution, setting, theme } = data || {};



  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateCreatedDisplay = dateCreated && new Date(dateCreated.seconds * 1000).toLocaleDateString(undefined, options);
  const dateModifiedDisplay = dateModified && new Date(dateModified.seconds * 1000).toLocaleDateString(undefined, options);

  const now = new Date();
  const dateCreatedObj = dateCreated && new Date(dateCreated.seconds * 1000);
  const daysSinceCreated = dateCreatedObj && Math.ceil((now - dateCreatedObj) / (1000 * 60 * 60 * 24));


  const exportImageRef = useRef(null); // New ref for the export image


  const handleGenerateImage = () => {
    navigate('/newstoryaddpicture', {
      state: {
        fullStoryObject: data
      }
    })
  };


  const toggleQuestionAnswer = (index) => {
    if (visibleAnswer === index) {
      setVisibleAnswer(null); // If the clicked question's answer is already visible, hide it
    } else {
      setVisibleAnswer(index); // Otherwise, show the clicked question's answer
    }
  };


  const toggleItem = (itemKey) => {
    if (selectedItem === itemKey) {
      setSelectedItem(null); // Hide the currently shown answer
    } else {
      setSelectedItem(itemKey); // Show the answer for the clicked question
    }
  };


  const editStory = () => navigate('/storyeditmode/' + storyId);


  const handleQRCodeClick = () => {
    if (!window.matchMedia('(max-width: 767px)').matches) {
      setQrCodeSize((prevQrCodeSize) => (prevQrCodeSize === 128 ? 128 * 3 : 128));
    }
  };

  const generateQRCode = () => {
    if (!generatedStaticLink) {
      generateNewStaticLink(
        {
          setLoadingStaticLink,
          title,
          text,
          quizArray,
          imageDownloadURL,
          imageMediumDownloadURL,
          currentUser,
          baseUrl,
          storyId,
          updateStory,
          db,
          setGeneratedStaticLink,
          setLinkCopied
        }
      );
    }


    const isMobileDevice = window.matchMedia('(max-width: 767px)').matches;
    if (isMobileDevice) {
      setShowQRCode(true);
      setQrCodeSize(window.innerWidth * 0.9);
    } else {
      if (!showQRCode) {
        setShowQRCode(true);
        setQrCodeSize(128);
      } else {
        setQrCodeSize((prevQrCodeSize) => (prevQrCodeSize === 128 ? 128 * 3 : 128));
      }
    }
  };

  const generateQuestionAI = async () => {
    console.log("---OLD API---")

  };

  const getImageSource = ({ imageDownloadURL, imageMediumDownloadURL }) => {
    if (imageMediumDownloadURL) return imageMediumDownloadURL;
    return imageDownloadURL;
  };

  const handleShareStory = () => {
    generateQRCode()
    setShareStory(true)
  }



  useEffect(() => {
    let textDictionary = {};

    const [storyTextArray, _] = splitWords(text);

    storyTextArray &&
      storyTextArray.forEach((word) => {
        if (/^[a-zA-Z ]+$/.test(word)) {
          textDictionary[word.toLowerCase()] = true;
        }
      });
    setStoryWordDictionary(textDictionary);


    let wbd = {};
    let wordBankArray;

    if (Array.isArray(wordBank)) {
      wordBankArray = [...wordBank];
    } else if (typeof wordBank === 'string') {
      wordBankArray = wordBank.split(/,\s*/);
    }


    wordBankArray?.forEach((word) => {
      if (/^[a-zA-Z ]+$/.test(word.trim())) {
        wbd[word.trim().toLowerCase()] = true;
      }
    });
    setWordBankDictionary(wbd);
  }, [text, wordBank]);


  useEffect(() => {
    localStorage.setItem('imageLocalABC', imageDownloadURL);
  }, [imageDownloadURL]);

  useEffect(() => {
    setShowTestMaker(quizArray.length === 0);
  }, [quizArray]);

  useEffect(() => {
    setPremiumFeatures(true);
    // setPremiumFeatures(firestoreUserData?.features);
  }, [firestoreUserData]);

  if (!currentUser) {
    navigate('/');
    return null; // why?
  }


  if (loading || !data) {
    return (
      <Lottie animationData={simpleLoader} loop={true} style={{ height: 600, position: 'relative' }} />
    );
  }



  return (
    <>
      <ToastContainer />

      <Container>

        {deleteInProgress
          ? <Lottie animationData={trashBin} loop={true} style={{ height: 600, position: "relative" }} />
          : null}

        <div className="center">
          <h2 style={{ color: "black", fontSize: "3em" }}>{title}</h2>
        </div>



        <div className="center">
          {retryImageGeneration ? (

            <div
              style={{
                width: '200px',
                height: '200px',
                backgroundColor: '#7fddfa',
                border: '5px solid black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '10px'
              }}
              onClick={handleGenerateImage}
            >
              <span style={{ color: 'black', fontSize: '1em', fontWeight: 'bold' }}>Click to Generate Image</span>

            </div>
          ) : (
            <img
              id="storyImage"
              src={getImageSource(data)}
              height="500px"
              width="500px" alt="" />
          )}
        </div>

        <br />
        {/* FORMATTING ISSUE HERE? */}
        <Container>

          {text &&
            <StoryTextDisplayWrapper
              storyText={text}
              wordBank={vocabulary}
            />}

        </Container>
        <br />

        {shareStory
          ? null
          : <div className="center">
            <Button
              style={{ fontSize: "1.5em" }}
              size="huge"
              color="green"
              onClick={handleShareStory}>
              <span>
                <Icon
                  //color="white" 
                  name='share square' />
              </span>
              Share!
            </Button>
          </div>
        }


        {loadingStaticLink ?
          <div className="center">
            <Lottie animationData={simpleLoader} loop={true} style={{ height: 100, position: 'relative' }} />
          </div>
          : DisplayLinkQrEdit({
            showQRCode,
            generatedStaticLink,
            qrCodeSize,
            handleQRCodeClick,
            linkCopied,
            graphicExport,
            generateNewStaticLink,
            graphicQR,
            generateQRCode,
            showTestMaker,
            testImage,
            generateQuestionAI,
            currentlyGeneratingQuestion,
            editStory,

            setLoadingStaticLink,
            title,
            text,
            quizArray,

            imageDownloadURL,
            imageMediumDownloadURL,

            currentUser,
            baseUrl,
            storyId,
            updateStory,
            db,
            setGeneratedStaticLink,
            setLinkCopied,

            story2Map,
            currentLanguage
          })}
        <br />

        {/* {firestoreUserData && firestoreUserData.features && firestoreUserData.features.premium */}
        {premiumFeatures

          ?

          <StoryResources
            userId={currentUser.uid}
            storyId={storyId}
            showResources={showStoryDetails_A}
            toggleResources={setShowStoryDetails_A}
            storyMap={story2Map}
            chatModelVersion={chatModelVersion}
            storyLanguage={language}
            currentLanguage={currentLanguage}

            title={title}
            text={text}
            summary={summary}
            vocabulary={vocabulary}
            quizArray={quizArray}
            conflict={conflict}
            resolution={resolution}
            theme={theme}
            setting={setting}
            characters={characters}
            prompt={prompt}

            toggleQuestionAnswer={toggleQuestionAnswer}
            visibleAnswer={visibleAnswer}
            selectedItem={selectedItem}
            toggleItem={toggleItem}
          />


          : <GoPremium />}




        {/* ----- button: show teacher materials ------ */}
        <br />

        {!showStoryDetails_B && !showStoryDetails_A && (
          <div className="center"
            onClick={() => setShowStoryDetails_B(!showStoryDetails_B)}>
            <span style={{ color: "green", cursor: "pointer" }}>
              <img src={document} height="50px" alt="doc icon" />
            </span>
          </div>
        )}


        {/* ----- teacher materials  ------ */}

        {showStoryDetails_B && !showStoryDetails_A &&
          <div style={{ backgroundColor: '#f5f5f5', padding: '5px' }}>

            <h1 className="center"> TEACHER MATERIALS</h1>

            <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>
              {ageLevel
                ?
                <>
                  <span> {story2Map[currentLanguage]?.ageLevel || story2Map['English'].ageLevel}{": "}{ageLevel} </span>
                  {readingLevel && <span> TEXT LEVEL: n/a</span>}
                </>
                : null}
            </h4>

            <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>
              {prompt ? <span> {story2Map[currentLanguage]?.prompt || story2Map['English'].prompt}{": "} {prompt}</span> : null}
            </h4>

            <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>
              {language
                ? <span> {story2Map[currentLanguage]?.language || story2Map['English'].language}{": "} {language}{" "}{languageEmoji}</span> : null}
            </h4>

            {illustrationObject
              ? <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>{story2Map[currentLanguage]?.illustrationStyle || story2Map['English'].illustrationStyle}: {illustrationObject.styleSelectedPresentationText}</h4> : null}


            <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>
              <span> {story2Map[currentLanguage]?.created || story2Map['English'].created}{": "}{dateCreatedDisplay}</span>
            </h4>


            <h4 style={{ color: 'black', fontSize: '1em', margin: '5px' }}>
              <span> {story2Map[currentLanguage]?.modified || story2Map['English'].modified}{": "}{dateModifiedDisplay}</span>
            </h4>

            <div className="center" >
              <Button color="black" size="large" onClick={editStory}>
                {story2Map[currentLanguage]?.editText.toUpperCase() || story2Map['English'].editText.toUpperCase()}
              </Button>
            </div>
            <br />


            {/* ----- button: hide teacher materials ------ */}
            <div
              className="center"
              onClick={() => {
                setShowStoryDetails_B(!showStoryDetails_B)
                setShowStoryDetails_A(false)
              }
              }>
              <Icon color="black" size="large" name='eye slash' />
            </div>
          </div>}

        <br />
        <br />

      </Container ></>
  );
};

export default StoryDisplayMain;

