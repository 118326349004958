// saveUnfinishedStory.js

import { setDoc, doc, serverTimestamp } from "firebase/firestore";

const saveUnfinishedStory = async ({
  currentUser,
  db,
  updatedStoryObject,
  setShowSavingGraphic,
  setShowNavbar,
  setServerError,
  setSavingStory,
  setSuccessfulSave,
  language,
  languageEmoji
}) => {


  if (!currentUser) {
    console.log("No currentUser found, exiting function");
    return null;
  }

  setSavingStory(true);
  setShowSavingGraphic(() => true);
  setShowNavbar(false);

  const storyObjectWithMeta = {
    ...updatedStoryObject,
    imagesObject: updatedStoryObject.imagesObject || null,
    imageDownloadURL: updatedStoryObject.imagesObject?.imageDownloadURL || null,
    imageThumbMediumURL: updatedStoryObject.imagesObject?.imageThumbMediumURL || null,
    imageThumbDownloadURL: updatedStoryObject.imagesObject?.imageThumbDownloadURL || null,
    userId: currentUser.uid,
    userDisplayName: currentUser?.displayName,
    userEmail: currentUser?.email,
    dateCreated: serverTimestamp(),
    dateModified: serverTimestamp(),
    tokensUsed: { text: null, image: null },
    themeObject: {},
    languageEmoji: languageEmoji || "🌎", 
    language: language || "EarthSpeak", // Provide a default value

  };



  const { storyId } = storyObjectWithMeta;

  try {

    await setDoc(doc(db, "users", currentUser.uid, "stories", storyId), storyObjectWithMeta);


    setSavingStory(false);
    setSuccessfulSave(true);
    setShowNavbar(true);

  } catch (err) {
    setServerError(true);
    setShowNavbar(true);
    console.error("Error saving story: ", err.message);
    console.error("Full error: ", err);
  }
};

export default saveUnfinishedStory;
