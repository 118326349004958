import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import DisplayFirestoreArchivedDocs from '../general/DisplayFirestoreArchivedDocs';
import updateStory from '../api/updateStory';



function StoriesArchived(props) {
  const { currentUser, db, imageUrls, setImageUrls } = props
  let navigate = useNavigate()

  const deleteImgFromDisplay = (item) => {
    const newUrlArray = imageUrls.filter(url => url !== item.imageDownloadURL)
    setImageUrls(newUrlArray)
  }

  const restoreStory = (item) => {
    updateStory({ userId: currentUser?.uid, storyId: item.storyId, db, updatedFields: { archived: false } });
  };



  if (!currentUser) return navigate("/")

  return (
    <>
      <Container>

        <h2 className="center" style={{ fontSize: "3em", color: "red" }}>ARCHIVED STORIES</h2>

        <DisplayFirestoreArchivedDocs
          deleteImgFromDisplay={deleteImgFromDisplay}
          db={db}
          userId={currentUser?.uid}
          storyHandler={restoreStory}
        />

      </Container>
    </>
  )
}

export default StoriesArchived
