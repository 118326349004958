import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Icon, Button } from "semantic-ui-react";

function Payment({ currentUser, setShowNavbar }) {
  const navigate = useNavigate()

  useEffect(() => {
    setShowNavbar(true)
  }, [])

  if (!currentUser) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Container>
        <h2 className="center" style={{ fontSize: "3.5em" }}>Purchase Credits</h2>

        <br />
        <div className="center">
          <Button
            basic
            size="huge"
            color="black"
            onClick={() => navigate("/stripe")}>
            <span className="center"><Icon name='stripe' size='huge' /></span>

          </Button>

        </div>
        <br />

        <div className="center">
          <Button
            basic
            size="huge"
            color="black"
            onClick={() => navigate("/paypal")}>
            <span className="center"><Icon name='paypal' size='huge' /></span>

          </Button>
        </div>

        <br />
        <div className="center">
          <Button
            basic
            size="huge"
            color="black"
            onClick={() => navigate("/applepay")}>
            <span className="center"><Icon name='apple' size='huge' /></span>

          </Button>
        </div>

        {/* <div className="center">
          <Button
            basic
            size="huge"
            color="black"
            onClick={() => navigate("/venmo")}>
            <span className="center">Venmo
          </Button> 
        </div>*/}


      </Container >
    </>
  );
}

export default Payment;


